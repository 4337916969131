import React from 'react'
import injectSheet from 'react-jss'
import globalStyles from 'styles/global'
import { object } from 'prop-types'
import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router'

import flagStore from '@stores/flagStore'

const styles = {
  container: {
    height: '100%',
    overflow: 'hidden',
  },
  ...globalStyles,
}

export const GlobalStyles = ({ classes, children }) => (
  <div className={classes.container}>
    <FlagGroup onDismissed={flagStore.removeFlag}>
      {flagStore.flags.map(flag => (
        <AutoDismissFlag
          appearance={flag.type}
          key={flag.id}
          id={flag.id}
          title={flag.title}
          description={flag.text}
        />
      ))}
    </FlagGroup>
    {children}
  </div>
)

GlobalStyles.propTypes = {
  classes: object,
  children: object,
}

export default injectSheet(styles)(withRouter(observer(GlobalStyles)))
