import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import Select from '@atlaskit/select'
import { FieldTextStateless } from '@atlaskit/field-text'
import { MODAL_ZINDEX } from '@utils/globalVar'
import permission from '@components/Permissions/Permissions.store'
import {
  IsLongEnough,
  ContainsOneNumber,
  NotSameAsFirst,
} from '@utils/Validators'
import store from './Users.store.js'
import OrganisationStore from '@components/Organisation/Organisation.store'

const UsersModal = ({ isNew }) => {
  const {
    closeModal,
    updateFormRef,
    updateUser,
    getActive,
    newUser,
    updateNew,
    emailNew,
    firstNameNew,
    lastNameNew,
    phoneNumberNew,
    updateOrganisationNew,
    updateNewSelect,
    roleNew,
    roleIdNew,
    organisationIdNew,
    password,
    passwordConfirm,
  } = store

  const {
    firstName,
    email,
    lastName,
    phoneNumber,
    id,
    'permission.role': pRole,
    'permission.id': pId,
    updateSelect,
    updateOrganisation,
    update,
    organisationId,
  } = getActive()

  const actions = [
    {
      text: 'Save',
      onClick: () => (isNew ? newUser() : updateUser(id)),
    },
    { text: 'Close', onClick: () => closeModal() },
  ]

  const permissionRows = permission.data.map((item) => ({
    label: item.role,
    value: item.id,
  }))

  return (
    <Modal
      actions={actions}
      heading={isNew ? 'Add new User' : 'Edit User'}
      onClose={closeModal}
    >
      <Form
        shouldFitContainer
        ref={(ref) => {
          updateFormRef(ref)
        }}
      >
        <Field label="Email" required isRequired>
          <FieldTextStateless
            name={isNew ? 'emailNew' : 'email'}
            type="email"
            shouldFitContainer
            value={isNew ? emailNew : email}
            onChange={isNew ? updateNew : update}
          />
        </Field>

        <Field label="First Name" required isRequired>
          <FieldTextStateless
            name={isNew ? 'firstNameNew' : 'firstName'}
            type="text"
            shouldFitContainer
            value={isNew ? firstNameNew : firstName}
            onChange={isNew ? updateNew : update}
          />
        </Field>

        <Field label="Last Name" required isRequired>
          <FieldTextStateless
            name={isNew ? 'lastNameNew' : 'lastName'}
            type="text"
            isRequired
            shouldFitContainer
            value={isNew ? lastNameNew : lastName}
            onChange={isNew ? updateNew : update}
          />
        </Field>

        <Field label="Organisation" required isRequired>
          <Select
            name={'organisationId'}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: MODAL_ZINDEX }),
            }}
            onChange={isNew ? updateOrganisationNew : updateOrganisation}
            options={OrganisationStore.getLabels()}
            placeholder="Choose a Organisation"
            value={OrganisationStore.getSingleLabel(
              organisationId || organisationIdNew,
            )}
          />
        </Field>

        <div>
          <Field label="Phone Number">
            <FieldTextStateless
              name={isNew ? 'phoneNumberNew' : 'phoneNumber'}
              type="string"
              shouldFitContainer
              value={isNew ? phoneNumberNew : phoneNumber}
              onChange={isNew ? updateNew : update}
            />
          </Field>
        </div>

        {isNew ? ( // hack as <Form> wont accept blank children
          <div>
            <Field
              label="Password"
              required
              isRequired
              validateOnBlur
              validators={[IsLongEnough, ContainsOneNumber]}
            >
              <FieldTextStateless
                name="password"
                type="password"
                shouldFitContainer
                value={password}
                onChange={updateNew}
              />
            </Field>
            <Field
              label="Confirm Password"
              required
              isRequired
              validateOnBlur
              validators={[
                IsLongEnough,
                ContainsOneNumber,
                NotSameAsFirst(password),
              ]}
            >
              <FieldTextStateless
                name="passwordConfirm"
                type="password"
                shouldFitContainer
                value={passwordConfirm}
                onChange={updateNew}
              />
            </Field>
          </div>
        ) : (
          <div />
        )}

        <Field label="Role" isRequired required>
          <Select
            name={isNew ? 'newRole' : 'role'}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: MODAL_ZINDEX }),
            }}
            onChange={isNew ? updateNewSelect : updateSelect}
            options={permissionRows}
            placeholder="Choose a Role"
            value={{
              label: isNew ? roleNew : pRole,
              value: isNew ? roleIdNew : pId,
            }}
          />
        </Field>
      </Form>
    </Modal>
  )
}

UsersModal.propTypes = {
  classes: PropTypes.object,
  isNew: PropTypes.bool,
}

export default observer(UsersModal)
