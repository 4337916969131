import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import sortBy from 'lodash/sortBy'

import rest from '@utils/rest'

const { enumeration, array, string, boolean } = types

const ChildModel = types.model('childModel', {
  id: string,
  name: string,
})

const Dialogue = types.model('Dialogue', {
  id: string,
  name: string,
  sport: ChildModel,
  language: ChildModel,
  isDeletable: boolean,
})

const SelectedModel = types.model('SelectedModel', {
  label: string,
  value: string,
})

const DialoguesStore = types
  .model('DialoguesStore', {
    state: enumeration('State', ['pending', 'done', 'error']),
    data: array(Dialogue),
    selectedDefault: SelectedModel,
    selectedLanguage: SelectedModel,
    sports: array(ChildModel),
    selectedSport: SelectedModel,
    modalOpen: false,
  })
  .views(self => ({
    renderLabels(target) {
      return self[target].map(item => ({
        label: item.name,
        value: item.id,
      }))
    },
  }))
  .actions(self => ({
    updateSelect(val, target) {
      self[target] = val
    },
    openModal() {
      self.modalOpen = true
    },
    closeModal() {
      self.modalOpen = false
    },
    getExtraInfo: flow(function* getExtraInfo() {
      self.loading = true
      try {
        const sports = yield rest({
          url: 'sport',
        })
        self.sports = sports.data
      } catch (e) {
        console.log('error', e)
      }
      self.loading = false
    }),
    getDialogues: flow(function* getDialogues() {
      self.state = 'pending'
      try {
        const res = yield rest({
          url: 'dialogue',
        })
        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.data = sortBy(res, ['isDeletable', 'name'])
        }
      } catch (error) {
        console.error('Failed to fetch group', error)
        self.state = 'error'
      }
    }),
    deleteDialogues: flow(function* deleteDialogues(id) {
      self.state = 'pending'
      try {
        const res = yield rest({
          url: `dialogue/${id}`,
          method: 'DELETE',
        })
        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.getDialogues()
        }
      } catch (error) {
        console.error('Failed to fetch group', error)
        self.state = 'error'
      }
    }),
  }))
  .create({
    state: 'done',
    data: [],
    selectedLanguage: {
      label: '',
      value: '',
    },
    selectedSport: {
      label: '',
      value: '',
    },
    selectedDefault: {
      label: '',
      value: '',
    },
  })

makeInspectable(DialoguesStore)

export default DialoguesStore
