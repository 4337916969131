import 'setimmediate' // polyfill for generators
import 'babel-polyfill' // https://github.com/babel/babel/issues/5085#issuecomment-343547443
import ReactDOM from 'react-dom'
import React from 'react'
import { Router, Switch, Route } from 'react-router'
import { Redirect } from 'react-router-dom'
import { ThemeProvider } from 'react-jss'
import '@atlaskit/css-reset'
import theme from '@styles/theme'

import GlobalStyles from '@components/GlobalStyles/GlobalStyles'
import Container from '@components/Container/Container'
import Users from '@components/Users/Users'
import Permissions from '@components/Permissions/Permissions'
import BlankPage from '@components/BlankPage/BlankPage'
import Login from '@components/Login/Login'
import Translations from '@components/Translations/Translations'
import Video from '@components/Video/Video'
import Tokens from '@components/Tokens/Tokens'
import Languages from '@components/Languages/Languages'
import Groups from '@components/Groups/Groups'
import Dialogues from '@components/Dialogues/Dialogues'
import DialogueEdit from '@components/DialogueEdit/DialogueEdit'
import ModalContainer from '@ui/ModalContainer/ModalContainer'
import Organisation from '@components/Organisation/Organisation'
import Location from '@components/Location/Location'
import Adverts from '@components/Adverts/Adverts'
import FeatureRace from '@components/FeatureRace/FeatureRace'
import Authentication from '@components/Authentication/Authentication'
import RptConfig from '@components/RptConfig/RptConfig'
import RpdConfig from '@components/RpdConfig/RpdConfig'
import PiConfig from '@components/PiConfig/PiConfig'
import Config from '@components/Config/Config'
import Region from '@components/Region/Region'
import Screen from '@components/Screen/Screen'

import { history } from '@stores/routerStore'
import userStore from '@stores/userStore'

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

// gets JWT from localstoreage
userStore.jwtLogin()
const getDomain = () => {
  const ENVIRONMENT_DOMAIN = process.env.NODE_ENV
  if (ENVIRONMENT_DOMAIN) {
    if (ENVIRONMENT_DOMAIN === 'production') {
      return 'racingpost.com'
    }
    if (ENVIRONMENT_DOMAIN === 'staging') {
      return 'staging-racingpost.com'
    }
    if (ENVIRONMENT_DOMAIN === 'development') {
      return 'dev-racingpost.com'
    }
  }
  return 'dev-racingpost.com'
}
const DOMAIN = getDomain()

export const GQL_URL = `https://bsd.${DOMAIN}/graphql`

const client = new ApolloClient({
  uri: GQL_URL,
  cache: new InMemoryCache(),
})

const App = () => (
  <ApolloProvider client={client}>
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <GlobalStyles>
          <Switch>
            <Route path="/login" component={Login} />
            <Container>
              <Switch>
                {/* Ensure NavSlideBar has side navigation */}
                <Route path="/admin/roles" component={Permissions} />
                <Route path="/admin/users" component={Users} />
                <Route path="/admin/organisation" component={Organisation} />
                <Route path="/translations" exact component={Translations} />
                <Route path="/video" exact component={Video} />
                <Route path="/translations/tokens" exact component={Tokens} />
                <Route path="/adverts" component={Adverts} />
                <Route path="/feature-races" component={FeatureRace} />
                <Route
                  path="/translations/dialogues"
                  exact
                  component={Dialogues}
                />
                <Route path="/translations/groups" exact component={Groups} />
                <Route
                  path="/translations/languages"
                  exact
                  component={Languages}
                />
                <Route path="/translations/:id" component={DialogueEdit} />
                <Route path="/authentication/:id" component={Authentication} />
                <Route path="/config/rpt" component={RptConfig} />
                <Route path="/config/rpd" component={RpdConfig} />
                <Route path="/devices/list" component={PiConfig} />
                <Route path="/regions" component={Region} />
                <Route path="/locations" component={Location} />
                <Route path="/screens" component={Screen} />
                <Route path="/config" component={Config} />
                <Route
                  path="/"
                  exact
                  render={() => <Redirect to="/devices/list" />}
                />

                <Route component={BlankPage} />
              </Switch>
            </Container>
          </Switch>
        </GlobalStyles>
      </ThemeProvider>
    </Router>
    <ModalContainer />
  </ApolloProvider>
)

ReactDOM.render(<App />, document.getElementById('root'))

// Hot Module Replacement
if (module.hot) {
  module.hot.accept()
}
