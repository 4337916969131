import color from 'color'
import theme from './theme'
import reset from './reset'

const styles = {
  '@global': {
    ...reset,
    body: {
      fontFamily: '"Lato", sans-serif',
      fontSize: 14,
    },
    '*': {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      boxSizing: 'border-box',
    },
    '#root': {
      height: '100%',
    },
    '.tableRight': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    table: {
      'border-bottom': '2px solid #DFE1E6',
    },
    td: {
      overflow: 'visible !important',
      'vertical-align': 'middle',
    },
    video: {
      width: '100%',
    },
    canvas: {
      width: '100%',
    },
    a: {
      color: theme.colors.globalLinkColor,
      'text-decoration': 'none',
      transition: theme.transition,
      '&:hover': {
        'text-decoration': 'none',
        color: color(theme.colors.globalLinkColor)
          .darken(0.5)
          .toString(),
      },
      '&:focus': {
        outline: 'none',
      },
    },
    h2: {
      'font-size': '18px',
      'font-weight': 'bold',
      'margin-bottom': '5px',
    },
  },
}

export default styles
