import React from 'react'
import dayjs from 'dayjs'
import get from 'lodash/get'
import Button, { ButtonGroup } from '@atlaskit/button'
import { ToggleStateless } from '@atlaskit/toggle'

import DeleteButton from '@components/DeleteButton/DeleteButton'
import FakeLink from '@components/UI/FakeLink/FakeLink'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'
import store from './Authentication.store'

const rows = (data, classes) =>
  data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.name,
        content: (
          <FakeLink text={item.name} onClick={() => store.openModal(item)} />
        ),
      },
      {
        key: 'auth',
        content: item.id,
      },
      {
        key: 'hardware',
        content: item.hardwareId,
      },
      {
        key: 'location',
        content: get(item, 'hardware.location.name'),
      },
      {
        key: 'organisation',
        content: get(item, 'hardware.organisation.name'),
      },
      {
        key: 'ip',
        content: item.hardware.ip,
      },

      {
        key: 'lastLoginDate',
        content: dayjs(item.lastLoginDate).format('HH:mm DD-MM'),
      },

      {
        key: 'authenticated',
        content: (
          <div className={classes.toggle}>
            <ToggleStateless
              size="large"
              isChecked={item.authenticated}
              onChange={item.toggleAuth}
            />
          </div>
        ),
      },

      {
        content: (
          <div className="tableRight">
            <ButtonGroup>
              <Button onClick={() => store.openModal(item)}>Edit</Button>
              <DeleteButton
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Delete User',
                    content: `Are you sure you want to delete ${item.name}?`,
                    appearance: 'warning',
                    action: () => store.deleteAuthentication(item.id),
                  })}
              />
            </ButtonGroup>
          </div>
        ),
      },
    ],
  }))

export default rows
