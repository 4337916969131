import React from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'
import { CreatableSelect } from '@atlaskit/select'

import store from './Organisation.store.js'

const OrganisationModal = () => {
  const {
    closeModal,
    updateFormRef,
    modalData,
    update,
    saveOrganisation,
    addIdLabel,
    updateLabels,
  } = store

  const actions = [
    {
      text: 'Save',
      onClick: () => saveOrganisation(),
    },
    { text: 'Close', onClick: () => closeModal() },
  ]
  const labels = (key) =>
    modalData[key]
      ? modalData[key]
          .split(' ')
          .filter((id) => id.length)
          .map((id) => ({ label: id, value: id }))
      : []

  return (
    <Modal
      actions={actions}
      heading={modalData.isNew ? 'Add new Organisation' : 'Edit Organisation'}
      onClose={closeModal}
    >
      <Form
        shouldFitContainer
        ref={(ref) => {
          updateFormRef(ref)
        }}
      >
        <Field label="Name" required isRequired>
          <FieldTextStateless
            name="name"
            type="text"
            shouldFitContainer
            value={modalData.name}
            onChange={update}
          />
        </Field>

        <Field label="Logo">
          <FieldTextStateless
            name="logo"
            type="text"
            shouldFitContainer
            value={modalData.logo}
            onChange={update}
          />
        </Field>
        <Field label="Bookmaker Ids">
          <CreatableSelect
            placeholder="Choose Bookmaker Ids"
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            options={labels('oddIds')}
            value={labels('oddIds')}
            onCreateOption={(item) => addIdLabel(item, 'oddIds')}
            isMulti
            onChange={(item) => updateLabels(item, 'oddIds')}
          />
        </Field>
        <Field label="Content Ids">
          <CreatableSelect
            placeholder="Choose Content Ids"
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            options={labels('contentIds')}
            value={labels('contentIds')}
            onCreateOption={(item) => addIdLabel(item, 'contentIds')}
            isMulti
            onChange={(item) => updateLabels(item, 'contentIds')}
          />
        </Field>
      </Form>
    </Modal>
  )
}

export default observer(OrganisationModal)
