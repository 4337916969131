import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import ManagedDynamicTable from '@components/ManagedAtlaskit/ManagedDynamicTable'
import Button from '@atlaskit/button'
import PageHeader from '@components/PageHeader/PageHeader'
import store from './Organisation.store'
import Modal from './Organisation.modal'
import rows from './rows'
import head from './tableHead'
import Spacer from '@components/Spacer/Spacer'
import ConfigTableEditorModal from '@components/Config/ConfigTableEditorModal'

class Organisation extends Component {
  componentDidMount() {
    store.getOrganisations()
  }

  render() {
    const {
      data,
      state,
      modalOpen,
      openModal,
      configModalData,
      configEditorOpen,
      closeConfigModal,
      update,
    } = store

    return (
      <Fragment>
        <PageHeader text="Organisation">
          <Button onClick={() => openModal()} appearance="primary">
            Create New
          </Button>
        </PageHeader>

        <ManagedDynamicTable
          head={head}
          rows={rows(data)}
          isLoading={state === 'pending'}
        />

        {modalOpen && <Modal />}

        {configEditorOpen && (
          <ConfigTableEditorModal
            nodeId={configModalData.nodeId}
            cumulativeConfig={configModalData.cumulativeConfig}
            closeModal={closeConfigModal}
            configLevel="organisation"
            update={event => update(event, 'configModalData')}
            configPath="cumulativeConfig"
            saveCallBack={store.getOrganisations}
          />
        )}
        <Spacer />
      </Fragment>
    )
  }
}

export default observer(Organisation)
