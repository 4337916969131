import React from 'react'
import injectSheet from 'react-jss'
import { object, string } from 'prop-types'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import color from 'color'
import cx from 'classnames'
import { routerStore } from '@stores/routerStore'

const styles = theme => ({
  link: {
    display: 'block',
    color: theme.colors.navLinkColor,
    'font-size': theme.fontSize.medium,
    padding: theme.padding.small,
    borderRadius: '5px',
    '&:hover': {
      color: color(theme.colors.navLinkColor)
        .darken(0.5)
        .toString(),
      backgroundColor: theme.colors.lightHover
    }
  },
  active: {
    color: color(theme.colors.navLinkColor)
      .darken(0.5)
      .toString(),
    backgroundColor: theme.colors.lightHover
  }
})

export const NavLink = ({ classes, url, text }) => (
  <Link
    to={url}
    className={cx(classes.link, {
      [classes.active]: url === routerStore.currentPath
    })}
  >
    {text}
  </Link>
)

NavLink.propTypes = {
  classes: object,
  text: string,
  url: string
}

export default injectSheet(styles)(observer(NavLink))
