import React, { useEffect, Fragment } from 'react'
import { observer } from 'mobx-react'
import PageHeader from '@components/PageHeader/PageHeader'
import store from './Region.store'
import Modal from './Region.modal'
import Spacer from '@components/Spacer/Spacer'
import Button, { ButtonGroup } from '@atlaskit/button'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import FakeLink from '@components/UI/FakeLink/FakeLink'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'
import Badge from '@atlaskit/badge'
import ConfigTableEditorModal from '@components/Config/ConfigTableEditorModal'
import ManagedDynamicTable from '@components/ManagedAtlaskit/ManagedDynamicTable'
import ConfigButton from '@components/UI/ConfigButton'

const head = {
  cells: [
    { key: 'name', content: 'Name', isSortable: true },
    { key: 'organisationId', content: 'Organisation', isSortable: true },
    {
      key: 'locationCount',
      content: 'Locations',
    },
    {
      key: 'more',
    },
  ],
}

const rows = (data) =>
  data.map((item) => ({
    key: item.id,
    cells: [
      {
        key: item.name,
        content: (
          <FakeLink text={item.name} onClick={() => store.openModal(item)} />
        ),
      },
      {
        key: item.organisation.name,
        content: item.organisation.name,
      },
      {
        key: 'locationCount',
        content: (
          <Badge appearance="important">
            {item.locations ? item.locations.length : 0}
          </Badge>
        ),
      },
      {
        content: (
          <div className="tableRight">
            <ButtonGroup>
              <ConfigButton onClick={() => store.openConfigModal(item)} />
              <Button onClick={() => store.openModal(item)}>Edit</Button>
              <DeleteButton
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Delete Region',
                    content: `Are you sure you want to delete ${item.name}?`,
                    appearance: 'warning',
                    action: () => store.deleteRegion(item.id),
                  })
                }
              />
            </ButtonGroup>
          </div>
        ),
      },
    ],
  }))

const Region = () => {
  useEffect(() => {
    store.getRegions()
  }, [])

  const {
    data,
    state,
    modalOpen,
    openModal,
    configEditorOpen,
    configModalData,
    update,
    closeConfigModal,
  } = store

  return (
    <Fragment>
      <PageHeader text="Regions">
        <Button onClick={() => openModal()} appearance="primary">
          Create New
        </Button>
      </PageHeader>
      <ManagedDynamicTable
        head={head}
        rows={rows(data)}
        isLoading={state === 'pending'}
        sortKey={'name'}
      />

      {modalOpen && <Modal />}

      {configEditorOpen && (
        <ConfigTableEditorModal
          nodeId={configModalData.nodeId}
          cumulativeConfig={configModalData.cumulativeConfig}
          closeModal={closeConfigModal}
          configLevel="region"
          update={(event) => update(event, 'configModalData')}
          configPath="cumulativeConfig"
          saveCallBack={store.getRegions}
        />
      )}
      <Spacer />
    </Fragment>
  )
}

export default observer(Region)
