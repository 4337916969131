import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'

import flagStore from '@stores/flagStore'
import { flatten } from '@utils/helpers'
import rest from '@utils/rest'

const { enumeration, string, array, frozen } = types

const NewTemplate = types.model('NewTemplate', {
  label: string,
  value: string,
})

const Permission = types
  .model('Permission', {
    id: string,
    role: string,
    schema: string,
    activeServices: array(NewTemplate),
    isEditing: false,
  })
  .views(self => ({
    getData() {
      const data = self.schema
        .split(',')
        .filter(item => item && item !== ' ')
        .map(item => item.replace(/ /g, ''))

      // this turns the key string into matching object
      const generateKeys = data.reduce((acc, item) => {
        // splits key and servivce on last : to prevent issue of multipul :'s
        const [key, service] = [
          item.slice(0, item.lastIndexOf(':')),
          item.slice(item.lastIndexOf(':') + 1),
        ]

        switch (service) {
          case '*':
            acc.push(
              { label: `${key} - ALL`, value: item },
              {
                label: `${key} - VIEW`,
                value: `${key}:find,${key}:get`,
              },
              { label: `${key} - CREATE`, value: `${key}:create` },
              {
                label: `${key} - UPDATE`,
                value: `${key}:patch,${key}:update`,
              },
              { label: `${key} - DELETE`, value: `${key}:remove` },
            )
            break
          case 'find':
            acc.push({
              label: `${key} - VIEW`,
              value: `${key}:find,${key}:get`,
            })
            break
          case 'create':
            acc.push({ label: `${key} - CREATE`, value: item })
            break
          case 'update':
            acc.push({
              label: `${key} - UPDATE`,
              value: `${key}:patch,${key}:update`,
            })
            break
          case 'remove':
            acc.push({ label: `${key} - DELETE`, value: item })
            break
          default:
            return acc
        }

        return acc
      }, [])

      const filterUniqKeys = uniqWith(generateKeys, isEqual)

      return filterUniqKeys
    },
  }))
  .actions(self => ({
    updateSchema(data) {
      self.schema = data.map(item => item.value).join(',')
      self.activeServices = data
    },
    editing() {
      self.isEditing = !self.isEditing
    },
    updateRole(event) {
      self.role = event.target.value
    },
    submitRole: flow(function* submitRole() {
      self.isEditing = false

      try {
        const { id, role, schema } = self

        const data = {
          url: `permission/${id}`,
          method: 'PATCH',
          body: {
            role,
            schema,
          },
        }

        yield rest(data)

        flagStore.addFlag({
          title: 'Your changes have been saved',
          type: 'success',
        })
      } catch (error) {
        console.error('Failed to update permission', error)
        self.state = 'error'
      }
    }),
  }))

const TypeData = types.model('TypeData', {
  name: '',
  value: frozen(),
})

const Services = types.model('Services', {
  service: '',
  values: array(TypeData),
})

const PermissionStore = types
  .model('PermissionStore', {
    state: enumeration('State', ['pending', 'done', 'error']),
    data: array(Permission),
    services: array(Services),
    modalOpen: false,
    newName: string,
    newScehma: NewTemplate,
  })
  .views(self => ({
    getLabels() {
      return flatten(
        self.services.map(({ service, values }) =>
          values.map(({ name, value }) => ({
            label: `${service} - ${name}`,
            value: value,
          })),
        ),
      )
    },
    getRoleLabels() {
      return self.data
        .filter(item => item.role !== 'admin')
        .map(({ role, id }) => ({ label: role, value: id }))
    },
  }))
  .actions(self => ({
    storeNewSchema(data) {
      self.newScehma = data
    },
    changeInput(event) {
      self.newName = event.target.value
    },
    openModal() {
      self.modalOpen = true
    },
    closeModal() {
      self.modalOpen = false
    },
    getPermissions: flow(function* getPermissions() {
      self.state = 'pending'
      try {
        const res = yield rest({
          url: 'permission',
        })
        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.data = res.data
        }
      } catch (error) {
        console.error('Failed to fetch permissions', error)
        self.state = 'error'
      }
    }),
    getServices: flow(function* getServices() {
      self.state = 'pending'
      try {
        const res = yield rest({
          url: 'service-list',
        })
        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.services = res
        }
      } catch (error) {
        console.error('Failed to fetch service-list', error)
        self.state = 'error'
      }
    }),
    postNewPermission: flow(function* postNewPermission() {
      self.state = 'pending'
      try {
        const data = {
          url: 'permission',
          method: 'POST',
          successMessage: 'Succesfully added new schema',
          body: {
            role: self.newName,
            schema: self.data.filter(
              item => item.id === self.newScehma.value,
            )[0].schema,
          },
        }

        const res = yield rest(data)
        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.getPermissions()
        }
      } catch (error) {
        console.error('Failed to post new permission', error)
        self.state = 'error'
      }
    }),
    deletePermission: flow(function* deletePermission(id) {
      self.state = 'pending'
      try {
        const res = yield rest({
          url: `permission/${id}`,
          method: 'DELETE',
          successMessage: 'Succesfully deleted',
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.getPermissions()
        }
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.modalState = 'error'
      }
    }),
  }))
  .create({
    state: 'done',
    data: [],
    services: [],
    newName: '',
    newScehma: { label: '', value: '' },
  })

makeInspectable(PermissionStore)

export default PermissionStore
