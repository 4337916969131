import React, { useState, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'
import Select from '@atlaskit/select'
import Store from './Config.store.js'
import OrganisationStore from '@components/Organisation/Organisation.store'
import RegionStore from '@components/Region/Region.store'
import LocationStore from '@components/Location/Location.store'
import ScreenStore from '@components/Screen/Screen.store'

const AddScreenModal = ({ organisationId, regionId, locationId }) => {
  useEffect(() => {
    OrganisationStore.getOrganisations()
    RegionStore.getRegions()
    LocationStore.getLocations()
  }, [])

  const [values, setValues] = useState({
    name: '',
  })

  const updateName = name => {
    setValues(curentValue => ({ ...curentValue, name }))
  }

  const actions = [
    {
      text: 'Save',
      onClick: useCallback(() => {
        ScreenStore.saveScreen({ id: '', name: values.name, locationId }).then(
          () => {
            Store.closeAddScreenModal()
            Store.getOrganisations()
          },
        )
      }, [values.name, locationId]),
    },
    { text: 'Close', onClick: Store.closeAddScreenModal },
  ]

  return (
    <Modal
      actions={actions}
      heading="Add new Location"
      onClose={Store.closeAddScreenModal}
    >
      <Form shouldFitContainer>
        <Field label="Name" required isRequired>
          <FieldTextStateless
            name="name"
            type="text"
            shouldFitContainer
            value={values.name}
            onChange={e => updateName(e.target.value)}
          />
        </Field>

        <Field label="Organisation" isRequired required>
          <Select
            isReadOnly
            isDisabled
            name={'organisationId'}
            options={OrganisationStore.getLabels()}
            value={OrganisationStore.getSingleLabel(organisationId)}
          />
        </Field>

        <Field label="Region" isRequired required>
          <Select
            name={'regionId'}
            isReadOnly
            isDisabled
            options={RegionStore.getLabelsForOrganisationid(organisationId)}
            value={RegionStore.getSingleLabel(regionId)}
          />
        </Field>

        <Field label="Location" isRequired required>
          <Select
            name={'locationId'}
            isReadOnly
            isDisabled
            options={LocationStore.getLabelsForRegionId(regionId)}
            value={LocationStore.getSingleLabel(locationId)}
          />
        </Field>
      </Form>
    </Modal>
  )
}

export default observer(AddScreenModal)
