export default {
  cells: [
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'softName',
      content: 'Soft Name',
      isSortable: true,
    },
    {
      key: 'group',
      content: 'Groups',
      isSortable: true,
    },
    {
      key: 'languages',
      content: 'Languages',
      isSortable: true,
    },
    {
      key: 'actions',
      content: '',
      isSortable: false,
    },
  ],
}
