import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'
import EditScopeStore from './EditScope.store'
import LocationStore from '@components/Location/Location.store'
import { scopeIncludeEnum } from '../AdvertUtils'
import {
  InstructionText,
  OptionsContainer,
  SaveOrCancelContainer,
  StyledDivHover,
  ZoomedOptions,
  ZoomedSelectedOptions,
} from './EditScope.styles'

const AddExclusions = (props) => {
  const [isOpen, setIsOpen] = useState(true)
  const closeModal = useCallback(() => setIsOpen(false), [])
  const { scopeData, updateScope } = EditScopeStore

  const shopsForSelectedRegions = scopeData.regions
    .reduce((acc, curr) => {
      if (curr.include === scopeIncludeEnum.include) {
        const shops = LocationStore.getLabelsForRegionId(curr.value)
        acc = [...acc, ...shops]
      }
      return acc
    }, [])
    .map((shop) => ({ ...shop, ...(shop.include = scopeIncludeEnum.exclude) }))

  const onShopClick = (shop) => {
    const shopNames = props.liveShopExclusionsArray.map((shop) => shop.label)

    if (!shopNames.includes(shop.label)) {
      const newShopArray = [...props.liveShopExclusionsArray, shop]
      props.setLiveShopExclusionsArray(newShopArray)
    }
  }

  const removeShopClick = (shopToRemove) => {
    const remainingShops = props.liveShopExclusionsArray.filter(
      (shop) => shop.label !== shopToRemove.label,
    )
    props.setLiveShopExclusionsArray(remainingShops)
  }

  return (
    <div>
      {isOpen && (
        <Modal
          onClose={() => {
            closeModal()
            props.handleClose()
          }}
        >
          <OptionsContainer>
            <ZoomedOptions>
              <InstructionText>Click on a shop to Exclude</InstructionText>
              {shopsForSelectedRegions.map((shop) => {
                return (
                  <StyledDivHover
                    key={shop.label}
                    onClick={() => onShopClick(shop)}
                  >
                    {shop.label}
                  </StyledDivHover>
                )
              })}
            </ZoomedOptions>
            <ZoomedSelectedOptions>
              <InstructionText>
                Exclude advert at these shops (Click to remove shop from list)
              </InstructionText>
              {props.liveShopExclusionsArray &&
                props.liveShopExclusionsArray.map((shop) => {
                  return (
                    <StyledDivHover
                      key={shop.label}
                      onClick={() => removeShopClick(shop)}
                    >
                      {shop.label}
                    </StyledDivHover>
                  )
                })}
            </ZoomedSelectedOptions>
          </OptionsContainer>
          <SaveOrCancelContainer>
            <Button
              onClick={() => {
                updateScope({
                  target: {
                    value: props.liveShopExclusionsArray,
                    name: 'regionExclusions',
                  },
                }),
                  updateScope({
                    target: {
                      value: [],
                      name: 'selectedShops',
                    },
                  }),
                  closeModal()
                props.handleClose()
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                closeModal()
                props.handleClose()
              }}
            >
              Cancel
            </Button>
          </SaveOrCancelContainer>
        </Modal>
      )}
    </div>
  )
}

export default observer(AddExclusions)
