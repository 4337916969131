import React from 'react'
import hoc from '@utils/hoc'
import PageHeader from '@atlaskit/page-header'
import { object } from 'prop-types'
import DynamicTable from '@atlaskit/dynamic-table'
import head from './tableHead.js'
import rows from './rows.js'
import store from './Video.store.js'
import VideoPreview from './VideoPreview.modal'
import VideoRecord from './VideoRecord.modal'
import Spacer from '@components/Spacer/Spacer'

class PM {
  componentDidMount() {
    store.fetchRaces()
    store.fetchVideos()
  }
}

const styles = {
  container: {
    marginBottom: 40,
  },
  buttonContainer: {
    margin: 20,
    display: 'flex',
    'justify-content': 'center',
  },
}

export const Video = ({ classes }) => (
  <div className={classes.container}>
    <PageHeader>Video</PageHeader>
    <DynamicTable
      head={head}
      rows={rows(store.races)}
      loadingSpinnerSize="large"
      rowsPerPage={undefined}
      isLoading={store.state || store.videoState}
      defaultSortKey="location"
      defaultSortOrder="ASC"
      isFixedSize
      onSort={() => console.log('onSort')}
      onSetPage={() => console.log('onSetPage')}
    />
    {store.previewModal && <VideoPreview active={store.getActive()} />}
    {store.recordModal && (
      <VideoRecord active={store.getActive()} classes={classes} />
    )}
    <Spacer />
  </div>
)

Video.propTypes = {
  classes: object,
}

export default hoc(PM, Video, styles)
