export default {
  cells: [
    {
      key: 'time',
      content: 'Time',
      isSortable: true,
      width: 10,
    },

    {
      key: 'location',
      content: 'Location',
      isSortable: true,
    },
    {
      key: 'horse_name',
      content: 'Tipped Horse',
      isSortable: true,
    },
    {
      key: 'preview',
      content: 'Preview',
      isSortable: false,
    },
    {
      key: 'download',
      content: 'Download',
      isSortable: false,
    },

    {
      key: 'actions',
      content: '',
      isSortable: false,
      width: 22,
    },
  ],
}
