import { types, flow, applySnapshot } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import userStore from '@stores/userStore'

const { enumeration, string, maybe } = types

const Details = types
  .model('Details', {
    state: enumeration('State', ['pending', 'done', 'error']),
    id: string,
    email: string,
    permissionId: string,
    firstName: string,
    lastName: string,
    phoneNumber: maybe(string),
    role: string,
    organisationId: string,
  })
  .volatile(() => ({
    formRef: {},
  }))
  .actions((self) => ({
    updateFormRef(ref) {
      self.formRef = ref
    },
    getUser: flow(function* getUser() {
      self.state = 'pending'
      try {
        const res = yield rest({
          url: `user-account/${userStore.userId}`,
        })
        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.id = res.id
          self.email = res.email
          self.firstName = res.firstName
          self.lastName = res.lastName
          self.phoneNumber = res.phoneNumber
          self.role = res['permission.role']
          self.permissionId = res.permissionId
          self.organisationId = res.organisationId
        }
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    update(e) {
      self[e.target.name] = e.target.value
    },
    postUser: flow(function* postUser(e) {
      e.preventDefault()

      if (self.formRef.validate().isInvalid) {
        return false
      }

      self.state = 'pending'

      try {
        const data = {
          url: `user-account/${userStore.userId}`,
          method: 'PATCH',
          successMessage: 'Details successfully updated',
          body: {
            email: self.email,
            firstName: self.firstName,
            lastName: self.lastName,
            phoneNumber: self.phoneNumber,
            permissionId: self.permissionId,
          },
        }

        const res = yield rest(data)
        if (res.message) {
          self.state = 'error'
        } else {
          const data = {
            ...res,
            state: 'done',
            phoneNumber: res.phoneNumber ? res.phoneNumber : '',
            role: self.role,
          }
          applySnapshot(self, data)
        }
      } catch (error) {
        console.error('Failed to update', error)
        self.state = 'error'
      }
    }),
  }))
  .create({
    state: 'done',
    id: '',
    permissionId: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    role: '',
    organisationId: '',
  })

makeInspectable(Details)

export default Details
