export default () => ({
  cells: [
    {
      key: 'eventName',
      content: 'Event Name',
      isSortable: true,
    },
    {
      key: 'race',
      content: 'Race',
      isSortable: true,
    },
    {
      key: 'featureRaceDate',
      content: 'Race Date',
      isSortable: true,
    },
    {
      key: 'displayFromDate',
      content: 'Display From',
      isSortable: true,
    },
    {
      key: 'status',
      content: 'Status',
      isSortable: true,
    },
    { key: 'leftPreview', content: 'Left Content Preview' },
    { key: 'rightPreview', content: 'Right Content Preview' },

    { key: 'delete', content: 'Delete' },
  ],
})
