import { types, flow, getSnapshot } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import { set } from 'lodash'
import EditScopeStore from './EditScope/EditScope.store'
import OrganisationStore from '@components/Organisation/Organisation.store'

const { string, array, enumeration } = types

const getPath = () => {
  const ENVIRONMENT_DOMAIN = process.env.NODE_ENV
  if (ENVIRONMENT_DOMAIN) {
    if (ENVIRONMENT_DOMAIN === 'production') {
      return 'production-racingpost.com'
    }
    if (ENVIRONMENT_DOMAIN === 'staging') {
      return 'staging-racingpost.com'
    }
    if (ENVIRONMENT_DOMAIN === 'development') {
      return 'dev-racingpost.com'
    }
  }
  return 'dev-racingpost.com'
}
const DOMAIN = getPath()

const bucket = `https://s3.eu-west-1.amazonaws.com/bsd-assets.${DOMAIN}`

const Adverts = types.model('Adverts', {
  id: string,
  advertImage: types.maybeNull(string),
  advertName: string,
  advertWidth: types.maybeNull(types.integer),
  advertHeight: types.maybeNull(types.integer),
  contentId: string,
  createdAt: string,
  updatedAt: string,
  startDate: types.maybeNull(string),
  expirationDate: types.maybeNull(string),
  startTime: types.maybeNull(string),
  endTime: types.maybeNull(string),
  priceStatus: types.maybeNull(string),
  isNew: false,
  advertType: enumeration('advertType', [
    'general',
    'meeting',
    'race_specific',
    'feature_race',
    '',
  ]),
  featureRaceId: types.maybeNull(string),
  advertPlacement: enumeration('advertPlacement', [
    'gaps',
    'rpZone',
    'meetingBar',
    'race',
    'race_specific',
    'column_gaps',
    '',
  ]),
  termsAndConditions: types.maybeNull(string),
})

const EMPTY_CLONE = {
  id: '',
  isNew: true,
  advertImage: '',
  advertName: '',
  contentId: '',
  startDate: '',
  expirationDate: null,
  startTime: '',
  endTime: '',
  priceStatus: '',
  advertWidth: 0,
  advertHeight: 0,
  createdAt: '',
  updatedAt: '',
  advertType: '',
  featureRaceId: null,
  advertPlacement: '',
  termsAndConditions: '',
}

const AdvertsStore = types
  .model('AdvertsStore', {
    data: array(Adverts),
    state: enumeration('State', ['pending', 'done', 'error']),
    modalOpen: false,
    preview: types.maybeNull(string),
    modalData: Adverts,
    nameFilter: string,
    bookmakerFilter: string,
    statusFilter: string,
    firstSaveData: types.maybeNull(Adverts),
  })
  .volatile(() => ({
    modalRef: {},
  }))
  .views((self) => ({
    getLabels() {
      return self.data.map((item) => ({ label: item.name, value: item.id }))
    },
    getSingleLabel(id) {
      return self.getLabels().find((item) => item.value === id)
    },
  }))
  .actions((self) => ({
    updateFormRef(ref) {
      self.modalRef = ref
    },
    update(event, path = 'modalData') {
      set(self[path], event.target.name, event.target.value)
      self.modalData[event.target.name] = event.target.value

      if (event.target.name === 'advertImage') {
        self.modalData.advertImage = event.target.file
        self.modalData.advertName = event.target.advertName
      }
    },
    openModal(adverts) {
      self.getAdverts()
      OrganisationStore.getOrganisations()
      const modalData = adverts ? { ...getSnapshot(adverts) } : EMPTY_CLONE
      self.modalData = modalData
      self.modalOpen = true
    },
    closeModal() {
      self.modalData = EMPTY_CLONE
      self.modalOpen = false
    },
    openPreview: flow(function* openPreview(item) {
      const response = yield fetch(
        `${bucket}/${item.contentId}/${item.advertType}/${item.advertPlacement}/${item.advertName}`,
      )
      const base64 = yield response.text()
      self.preview = base64
    }),

    closePreview() {
      self.preview = null
    },
    getAdverts: flow(function* getAdverts() {
      try {
        const res = yield rest({
          url: `adverts`,
        })
        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch adverts', error)
        self.state = 'error'
      }
    }),
    deleteAdvert: flow(function* deleteAdvert(
      advertName,
      contentId,
      advertType,
      advertPlacement,
    ) {
      self.state = 'pending'
      try {
        yield rest({
          url: `adverts/${advertName}?contentId=${contentId}&advertType=${advertType}&advertPlacement=${advertPlacement}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        self.state = 'done'
        self.getAdverts()
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    setFirstSaveData(res) {
      const data = { ...res }
      delete data.organisationId
      self.firstSaveData = data
    },

    saveAdvert: flow(function* saveAdvert() {
      const validate = self.modalRef.validate()
      if (
        !validate.invalidFields.length ||
        ([validate.invalidFields.length === 1] &&
          validate.invalidFields[0].name !== 'advertImage')
      )
        return
      let options
      if (self.modalData.id === '') {
        const cleanBody = { ...self.modalData }
        delete cleanBody.id
        options = {
          method: 'POST',
          url: 'adverts',
          body: cleanBody,
          successMessage: `${self.modalData.advertName} Successfully Added`,
        }
      } else {
        options = {
          method: 'PATCH',
          url: `adverts/${self.modalData.advertName}?id=${self.modalData.id}`,
          body: self.modalData,
          successMessage: `${self.modalData.advertName} Successfully Updated`,
        }
      }
      yield rest(options).then((res) => {
        self.setFirstSaveData(res)
        self.modalData.isNew && EditScopeStore.openScope(self.firstSaveData)
        return res
      })
      self.closeModal()
      self.getAdverts()
    }),
    changeFilter(event) {
      self[event.target.name] = event.target.value
    },
    resetFilters() {
      self.nameFilter = ''
      self.bookmakerFilter = ''
      self.statusFilter = ''
      self.getAdverts()
    },

    search: flow(function* search() {
      self.state = 'pending'

      try {
        const params = {
          advertName: self.nameFilter,
          contentId: self.bookmakerFilter,
          status: self.statusFilter,
        }
        const filters = new URLSearchParams(params).toString()
        const res = yield rest({
          url: `adverts?${filters}`,
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch adverts', error)
        self.state = 'error'
      }
    }),
  }))
  .create({
    data: [],
    modalData: EMPTY_CLONE,
    state: 'done',
    modalOpen: false,
    preview: null,
    nameFilter: '',
    bookmakerFilter: '',
    statusFilter: '',
  })

makeInspectable(AdvertsStore)

export default AdvertsStore
