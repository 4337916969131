import { types, flow } from 'mobx-state-tree'
import sortBy from 'lodash/sortBy'
import makeInspectable from 'mobx-devtools-mst'

import { history } from '@stores/routerStore'
import flagStore from '@stores/flagStore'
import rest from '@utils/rest'

const { string, enumeration, array } = types

const ChildModel = types.model('childModel', {
  id: string,
  name: string,
})

const Dialogue = types
  .model('Dialogue', {
    id: string,
    dialogueTitleId: string,
    title: string,
    dialogue_title: ChildModel,
    isChanged: false,
  })
  .actions(self => ({
    updateField(event) {
      self.title = event.target.value
      self.isChanged = true
    },
  }))

const Content = types
  .model('Content', {
    id: string,
    dialogueTitleId: string,
    dialogueId: string,
    question: string,
    answer: string,
    isChanged: false,
  })
  .actions(self => ({
    update(event) {
      self[event.target.name] = event.target.value
      self.isChanged = true
    },
  }))

const DialogueEditStore = types
  .model('DialogueEditStore', {
    state: enumeration('State', ['pending', 'done', 'error']),
    dialogue_contents: array(Content),
    dialogue_title_names: array(Dialogue),
    name: string,
    activeId: string,
  })
  .views(self => ({
    getActive() {
      return self.dialogue_contents.filter(
        item => item.dialogueTitleId === self.activeId,
      )
    },
    getChangedTitle() {
      return self.dialogue_title_names.filter(item => item.isChanged)
    },
    getChangedContent() {
      return self.dialogue_contents.filter(item => item.isChanged)
    },
  }))
  .actions(self => ({
    setActive(id) {
      self.activeId = id
    },
    getDialogues: flow(function* getDialogues() {
      self.state = 'pending'

      const url = history.location.pathname.split('/')
      try {
        const res = yield rest({
          url: `dialogue/${url[url.length - 1]}`,
        })
        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.name = res.name
          self.dialogue_contents = res.dialogue_contents
          self.dialogue_title_names = sortBy(
            res.dialogue_title_names,
            'dialogue_title.name',
          )
          self.activeId = self.dialogue_title_names[0].dialogueTitleId
        }
      } catch (error) {
        console.error('Failed to fetch group', error)
        self.state = 'error'
      }
    }),
    addNewRow: flow(function* getDialogues() {
      self.state = 'pending'
      const { dialogueTitleId, dialogueId } = self.getActive()[0]

      try {
        const res = yield rest({
          url: `dialogue-content`,
          method: 'POST',
          body: {
            dialogueId,
            dialogueTitleId,
            question: 'New Question',
            answer: 'New Question',
          },
        })
        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.getDialogues()
        }
      } catch (error) {
        console.error('Failed to fetch group', error)
        self.state = 'error'
      }
    }),
    deleteRow: flow(function* deleteRow(id) {
      self.state = 'pending'

      try {
        const res = yield rest({
          url: `dialogue-content/${id}`,
          method: 'DELETE',
        })
        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.getDialogues()
        }
      } catch (error) {
        console.error('Failed to fetch group', error)
        self.state = 'error'
      }
    }),

    saveAll: flow(function* saveAll() {
      self.state = 'pending'

      try {
        for (const item of self.getChangedContent()) {
          const { id, question, answer } = item
          yield rest({
            url: `dialogue-content/${id}`,
            method: 'PATCH',
            body: {
              question,
              answer,
            },
          })
        }

        for (const item of self.getChangedTitle()) {
          yield rest({
            url: `dialogue-title-name/${item.id}`,
            method: 'PATCH',
            body: {
              title: item.title,
            },
          })
        }

        self.state = 'done'
        self.getDialogues()

        flagStore.addFlag({
          title: 'Your changes have been saved',
          type: 'success',
        })
      } catch (error) {
        console.error('Failed to fetch group', error)
        self.state = 'error'
      }
    }),
  }))
  .create({
    state: 'done',
    dialogue_contents: [],
    dialogue_title_names: [],
    name: '',
    activeId: '',
  })

makeInspectable(DialogueEditStore)

export default DialogueEditStore
