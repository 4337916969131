import React, { useState } from 'react'
import { sortBy } from 'lodash'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'
import EditScopeStore from './EditScope.store'
import { scopeIncludeEnum } from '../AdvertUtils'
import { useQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { allHorseRacesQuery } from './AdvertQueries'
import { jurisdictions } from '../AdvertUtils'
import {
  StyledDivHover,
  ZoomedSelectedOptions,
  ZoomedOptions,
  SaveOrCancelContainer,
  OptionsContainer,
  InstructionText,
} from './EditScope.styles'

const today = moment().format('YYYY-MM-DD')
const nextWeek = moment().add(7, 'days').format('YYYY-MM-DD')

const AddRaces = (props) => {
  const [isOpen, setIsOpen] = useState(true)
  const closeModal = () => setIsOpen(false)
  const { updateScope } = EditScopeStore

  const selectedJurisdictions = jurisdictions
    ? jurisdictions.reduce((acc, curr) => {
        if (curr.include === scopeIncludeEnum.include) {
          curr.name === 'UK/IRE' ? acc.push('GB', 'IRE') : acc.push(curr.name)
        }
        return acc
      }, [])
    : []

  const {
    data: RaceData,
    error: RaceError,
    loading: RaceLoading,
  } = useQuery(allHorseRacesQuery, {
    variables: {
      countryCodes: selectedJurisdictions,
      startDateUTC: today,
      endDateUTC: nextWeek,
    },
  })

  if (RaceLoading) {
    return <p>LOADING</p>
  }
  if (RaceError) {
    return <p>Error loading races</p>
  }

  const allRacesForScope = RaceData
    ? RaceData.AllMeeting.map((meeting) => {
        return meeting.raceConnection.edges.map((race) => {
          return {
            id: race.node.id,
            dateTime: race.node.datetime,
            meeting: meeting.courseName,
          }
        })
      }).flat()
    : []

  const onRaceClick = (race) => {
    const raceIds = props.liveRacesArray.map((race) => race.id)
    if (!raceIds.includes(race.id)) {
      const newRaceArray = [...props.liveRacesArray, race]
      props.setLiveRacesArray(newRaceArray)
    }
  }

  const removeRaceClick = (raceToRemove) => {
    const removeRace = props.liveRacesArray.filter(
      (race) => race.id !== raceToRemove.id,
    )
    props.setLiveRacesArray(removeRace)
  }

  const racesByDaySortedAlphabetically = sortBy(
    allRacesForScope,
    [(x) => moment(x.dateTime).format('DD,MM'), (x) => x.meeting.toUpperCase()],
    ['asc', 'asc'],
  )

  return (
    <div>
      {isOpen && (
        <Modal
          onClose={() => {
            closeModal()
            props.handleClose()
          }}
        >
          <OptionsContainer>
            <ZoomedOptions>
              <InstructionText> Click on a race to include </InstructionText>
              {racesByDaySortedAlphabetically &&
                racesByDaySortedAlphabetically.map((race) => {
                  return (
                    <StyledDivHover
                      key={`${race.meeting}${race.dateTime}`}
                      onClick={() => onRaceClick(race)}
                    >
                      {`${moment(race.dateTime).format('Do MMM HH mm')}: ${
                        race.meeting
                      }`}
                    </StyledDivHover>
                  )
                })}
            </ZoomedOptions>
            <ZoomedSelectedOptions>
              <InstructionText>
                Include advert on these races (Click to remove race from list)
              </InstructionText>
              {props.liveRacesArray &&
                props.liveRacesArray.map((race) => {
                  return (
                    <StyledDivHover
                      key={`${race.meeting}${race.dateTime}`}
                      onClick={() => removeRaceClick(race)}
                    >
                      {`${moment(race.dateTime).format('Do MMM HH:mm')}: ${
                        race.meeting
                      }`}
                    </StyledDivHover>
                  )
                })}
            </ZoomedSelectedOptions>
          </OptionsContainer>
          <SaveOrCancelContainer>
            <Button
              onClick={() => {
                updateScope({
                  target: {
                    value: props.liveRacesArray,
                    name: 'selectedRaces',
                  },
                }),
                  closeModal()
                props.handleClose()
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                closeModal()
                props.handleClose()
              }}
            >
              Cancel
            </Button>
          </SaveOrCancelContainer>
        </Modal>
      )}
    </div>
  )
}

export default observer(AddRaces)
