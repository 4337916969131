import { gql } from '@apollo/client'

export const allHorseMeetingsQuery = gql`
  query AllMeeting(
    $countryCodes: [CountryEnum!]!
    $startDateUTC: String!
    $endDateUTC: String!
  ) {
    AllMeeting(
      countryCodes: $countryCodes
      dateRange: { startDateUTC: $startDateUTC, endDateUTC: $endDateUTC }
    ) {
      __typename
      ... on InternationalHorseMeeting {
        courseName
        dateTime
        countryCode
        id
      }
      ... on HorseMeeting {
        courseName
        dateTime
        countryCode
        id
      }
    }
  }
`
export const allHorseRacesQuery = gql`
  query AllMeeting(
    $countryCodes: [CountryEnum!]!
    $startDateUTC: String!
    $endDateUTC: String!
  ) {
    AllMeeting(
      countryCodes: $countryCodes
      dateRange: { startDateUTC: $startDateUTC, endDateUTC: $endDateUTC }
    ) {
      __typename
      ... on InternationalHorseMeeting {
        courseName
        raceConnection {
          edges {
            node {
              datetime
              id
            }
          }
        }
      }
      ... on HorseMeeting {
        courseName
        raceConnection {
          edges {
            node {
              datetime
              id
            }
          }
        }
      }
    }
  }
`
