import React from 'react'
import { object } from 'prop-types'
import Form, { Field, FormFooter } from '@atlaskit/form'
import FieldText from '@atlaskit/field-text'
import Button from '@atlaskit/button'
import Checkbox, { CheckboxGroup } from '@atlaskit/checkbox'
import { parse } from 'query-string'
import ForgotPasswordModal from '@components/ForgotPassword/ForgotPassword.modal'
import ResetPasswordModal from '@components/ResetPassword/ResetPassword.modal'

import basketball from '@assets/backgrounds/basketball.jpg'
import boxing from '@assets/backgrounds/boxing.jpg'
import cycling from '@assets/backgrounds/cycling.jpg'
import f1 from '@assets/backgrounds/f1.jpg'
import football from '@assets/backgrounds/football.jpg'
import rugby from '@assets/backgrounds/rugby.jpg'
import running from '@assets/backgrounds/running.jpg'
import swimming from '@assets/backgrounds/swimming.jpg'
import tennis from '@assets/backgrounds/tennis.jpg'
import ski from '@assets/backgrounds/ski.jpg'

import logoImg from '@assets/rp-dark-logo.png'
import hoc from '@utils/hoc'
import { getRandom } from '@utils/helpers'

import store from './Login.store'

const bgArr = [
  basketball,
  boxing,
  cycling,
  f1,
  football,
  rugby,
  running,
  swimming,
  tennis,
  ski,
]

class PM {
  componentDidMount() {
    const parsedURL = parse(window.location.search)
    if (parsedURL.resetPasswordId) {
      store.addResetPasswordId(parsedURL.resetPasswordId)
    }
  }
}

const styles = () => ({
  loginContainer: {
    display: 'flex',
    height: '100%',
  },
  formContainer: {
    flex: 0.86,
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    padding: '0 20px',
    'max-width': '100vw',
  },
  remeberMeForgotPasswordContainer: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'space-between',
  },
  forgotPassword: {
    paddingTop: '5px',
  },
  form: {
    width: 400,
    '& > div': {
      'justify-content': 'center',
    },
  },
  logo: {
    width: 260,
    marginBottom: 20,
  },
  welcomeBg: {
    flex: 1,
    position: 'relative',
  },
  welcomeBgCrop: {
    position: 'absolute',
    right: 0,
    top: '-25%',
    height: '150vh',
    width: '150vh',
    overflow: 'hidden',
    borderRadius: '50%',
    minWidth: '100vw',
    minHeight: '100vw',
  },
  welcomeBgImage: {
    backgroundImage: `url(${getRandom(bgArr)})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    position: 'absolute',
    right: '-25%',
    top: 0,
    width: '100%',
  },
})

export const Login = ({ classes }) => {
  const {
    loginContainer,
    welcomeBg,
    formContainer,
    form,
    logo,
    remeberMeForgotPasswordContainer,
    forgotPassword,
    welcomeBgCrop,
    welcomeBgImage,
  } = classes

  const {
    updateLoginDetails,
    email,
    password,
    sendLoginForm,
    state,
    remeberMe,
    toggleRemember,
    updateFormRef,
    forgotPasswordModalVisible,
    clearResetPasswordId,
    toggleForgotPasswordModal,
    resetPasswordId,
  } = store

  return (
    <div className={loginContainer}>
      <div className={welcomeBg}>
        <div className={welcomeBgCrop}>
          <div className={welcomeBgImage} />
        </div>
      </div>
      <div className={formContainer}>
        <div className={form}>
          <Form
            shouldFitContainer
            ref={ref => {
              updateFormRef(ref)
            }}
          >
            <img src={logoImg} alt={'logo'} className={logo} />
            <Field label="Email" required isRequired>
              <FieldText
                name="email"
                type="email"
                shouldFitContainer
                onChange={updateLoginDetails}
                value={email}
                autocomplete="email"
              />
            </Field>
            <Field label="Password" required isRequired>
              <FieldText
                name="password"
                type="password"
                shouldFitContainer
                onChange={updateLoginDetails}
                value={password}
                autocomplete="current-password"
              />
            </Field>
            <div className={remeberMeForgotPasswordContainer}>
              <Field>
                <CheckboxGroup>
                  <Checkbox
                    label="Remember me"
                    value={remeberMe}
                    initiallyChecked
                    onChange={toggleRemember}
                  />
                </CheckboxGroup>
              </Field>
              <Button
                className={forgotPassword}
                appearance="subtle-link"
                onClick={toggleForgotPasswordModal}
              >
                Forgot Password
              </Button>
            </div>
            <FormFooter>
              <Button
                onClick={sendLoginForm}
                appearance="primary"
                type="submit"
                isLoading={state === 'pending'}
              >
                Login
              </Button>
            </FormFooter>
          </Form>
        </div>
      </div>
      {forgotPasswordModalVisible && (
        <ForgotPasswordModal closeModal={toggleForgotPasswordModal} />
      )}
      {resetPasswordId && (
        <ResetPasswordModal
          closeModal={clearResetPasswordId}
          id={resetPasswordId}
        />
      )}
    </div>
  )
}

Login.propTypes = {
  classes: object,
  history: object,
}

export default hoc(PM, Login, styles)
