import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import get from 'lodash/get'

import Select from '@atlaskit/select'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field, Validator } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'

import store from './PiConfig.store.js'
import OrganisationStore from '@components/Organisation/Organisation.store'
import LocationStore from '@components/Location/Location.store'
import { MODAL_ZINDEX } from '@utils/globalVar'
import UserStore from '../../stores/userStore.js'
import RegionStore from '@components/Region/Region.store'

const PiConfigModal = () => {
  const {
    closeModal,
    updateFormRef,
    modalData,
    update,
    savePiConfig,
    badActivationCode,
  } = store

  const actions = [
    {
      text: 'Save',
      onClick: () => savePiConfig(),
    },
    { text: 'Close', onClick: () => closeModal() },
  ]

  return (
    <Modal
      actions={actions}
      heading={modalData.isNew ? 'Add New Device' : 'Edit Device'}
      onClose={closeModal}
    >
      <Form
        shouldFitContainer
        ref={ref => {
          updateFormRef(ref)
        }}
        onSubmit={() => savePiConfig()}
      >
        {modalData.isNew ? (
          <div>
            <Field label="Activation code" required isRequired>
              <FieldTextStateless
                name="activationCode"
                type="text"
                shouldFitContainer
                value={modalData.activationCode}
                onChange={update}
              />
            </Field>
            {badActivationCode
              ? 'Could not find device for this activation code'
              : ''}
          </div>
        ) : (
          // TODO upgrade babel to use shorthand
          <Fragment>
            <Field label="Name" required isRequired>
              <FieldTextStateless
                name="name"
                type="text"
                shouldFitContainer
                value={modalData.name}
                onChange={update}
              />
            </Field>
            <Field
              label="URL"
              required
              isRequired
              validators={[
                <Validator
                  key="urlValidator"
                  func={val => /(http:|https:)\/\/[\w]+\.[\w]+/.test(val)}
                  invalid={'Must be a valid url (start with https://)'}
                />,
              ]}
            >
              <FieldTextStateless
                name="url"
                type="text"
                shouldFitContainer
                value={modalData.url}
                onChange={update}
              />
            </Field>

            {UserStore.isAdmin() ? (
              <Fragment>
                <Field label="Organisation" isRequired required>
                  <Select
                    name={'organisationId'}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: MODAL_ZINDEX }),
                    }}
                    onChange={item =>
                      update({
                        target: {
                          name: 'location.region.organisationId',
                          value: item.value,
                        },
                      })
                    }
                    options={OrganisationStore.getLabels()}
                    placeholder="Choose a Organisation"
                    value={OrganisationStore.getSingleLabel(
                      get(modalData, 'location.region.organisationId', ''),
                    )}
                  />
                </Field>

                <Field label="Region" isRequired required>
                  <Select
                    isDisabled={
                      !get(modalData, 'location.region.organisationId', false)
                    }
                    name={'regionId'}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: MODAL_ZINDEX }),
                    }}
                    onChange={item => {
                      update({
                        target: {
                          name: 'location.regionId',
                          value: item.value,
                        },
                      })
                    }}
                    options={RegionStore.getLabelsForOrganisationid(
                      get(modalData, 'location.region.organisationId', ''),
                    )}
                    placeholder="Choose a Region"
                    value={RegionStore.getSingleLabel(
                      get(modalData, 'location.regionId', ''),
                    )}
                  />
                </Field>
              </Fragment>
            ) : (
              <div />
            )}

            <Field label="Location" isRequired required>
              <Select
                isDisabled={!get(modalData, 'location.regionId', false)}
                name={'locationId'}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: MODAL_ZINDEX }),
                }}
                onChange={item =>
                  update({
                    target: { name: 'locationId', value: item.value },
                  })
                }
                options={LocationStore.getLabelsForRegionId(
                  get(modalData, 'location.regionId', ''),
                )}
                placeholder="Choose a Location"
                value={LocationStore.getSingleLabel(modalData.locationId)}
              />
            </Field>
          </Fragment>
        )}
      </Form>
    </Modal>
  )
}

export default observer(PiConfigModal)
