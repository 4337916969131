import React, { Fragment } from 'react'
import PageHeader from '@atlaskit/page-header'
import { observer } from 'mobx-react'
import Form from '@atlaskit/form/dist/cjs/Form'
import Field from '@atlaskit/form/dist/cjs/Field'
import { FieldTextStateless } from '@atlaskit/field-text'
import Button, { ButtonGroup } from '@atlaskit/button'
import FormFooter from '../FormFooter'
import { MODAL_ZINDEX } from '@utils/globalVar'
import Select from '@atlaskit/select'
import PiConfigStore from '../PiConfig/PiConfig.store'
import OrganisationStore from '@components/Organisation/Organisation.store'
import UserStore from '../../stores/userStore'
import { sortBy } from 'lodash'

const DeviceList = () => {
  const label = OrganisationStore.getSingleLabel(
    PiConfigStore.organisationFilterId,
  )
  return (
    <Fragment>
      <PageHeader>Device List</PageHeader>
      <Form>
        <Field label="Name">
          <FieldTextStateless
            name="nameFilter"
            type="text"
            shouldFitContainer
            onChange={PiConfigStore.changeFilter}
            value={PiConfigStore.nameFilter}
          />
        </Field>
        {UserStore.isAdmin() ? (
          <Field label="Organisation">
            <Select
              name="organisationFilterId"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: MODAL_ZINDEX }),
              }}
              onChange={(item) =>
                PiConfigStore.changeFilter({
                  target: { name: 'organisationFilterId', value: item.value },
                })
              }
              options={sortBy(OrganisationStore.getLabels(), 'label')}
              placeholder="Choose Organisation"
              value={label || ''}
            />
          </Field>
        ) : (
          <div />
        )}
        <FormFooter>
          <ButtonGroup>
            <Button appearance="secondary" onClick={PiConfigStore.resetFilters}>
              Reset Filters
            </Button>
            <Button appearance="primary" onClick={PiConfigStore.search}>
              Search
            </Button>
          </ButtonGroup>
        </FormFooter>
      </Form>
    </Fragment>
  )
}
export default observer(DeviceList)
