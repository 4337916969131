import React, { useEffect, Fragment } from 'react'
import { observer } from 'mobx-react'
import ManagedDynamicTable from '@components/ManagedAtlaskit/ManagedDynamicTable'
import PageHeader from '@components/PageHeader/PageHeader'
import store from './Screen.store'
import Modal from './Screen.modal'
import Spacer from '@components/Spacer/Spacer'
import Button, { ButtonGroup } from '@atlaskit/button'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import FakeLink from '@components/UI/FakeLink/FakeLink'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'
import ConfigTableEditorModal from '@components/Config/ConfigTableEditorModal'
import ConfigButton from '@components/UI/ConfigButton'

const head = {
  cells: [
    { key: 'id', content: 'ID', width: '300px' },
    { key: 'name', content: 'Name', isSortable: true },
    {
      key: 'location.region.organisationId',
      content: 'Organisation',
      isSortable: true,
    },
    { key: 'location.regionId', content: 'Region', isSortable: true },
    { key: 'locationId', content: 'Location', isSortable: true },
    {
      key: 'more',
    },
  ],
}

const rows = (data) =>
  data.map((item) => ({
    key: item.id,
    cells: [
      { key: item.id, content: item.id },
      {
        key: item.name,
        content: (
          <FakeLink text={item.name} onClick={() => store.openModal(item)} />
        ),
      },
      {
        key: item.location.region.organisation.name,
        content: item.location.region.organisation.name,
      },
      {
        key: item.location.region.name,
        content: item.location.region.name,
      },
      {
        key: item.location.name,
        content: item.location.name,
      },
      {
        content: (
          <div className="tableRight">
            <ButtonGroup>
              <ConfigButton onClick={() => store.openConfigModal(item)} />
              <Button onClick={() => store.openModal(item)}>Edit</Button>
              <DeleteButton
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Delete Screen',
                    content: `Are you sure you want to delete ${item.name}?`,
                    appearance: 'warning',
                    action: () => store.deleteScreen(item.id),
                  })
                }
              />
            </ButtonGroup>
          </div>
        ),
      },
    ],
  }))

const Screen = () => {
  useEffect(() => {
    store.getScreens()
  }, [])

  const {
    data,
    state,
    modalOpen,
    openModal,
    configEditorOpen,
    configModalData,
    update,
    closeConfigModal,
  } = store

  return (
    <Fragment>
      <PageHeader text="Screens">
        <Button onClick={() => openModal()} appearance="primary">
          Create New
        </Button>
      </PageHeader>
      <ManagedDynamicTable
        head={head}
        rows={rows(data)}
        isLoading={state === 'pending'}
        sortKey={'name'}
      />

      {modalOpen && <Modal />}

      {configEditorOpen && (
        <ConfigTableEditorModal
          nodeId={configModalData.nodeId}
          cumulativeConfig={configModalData.cumulativeConfig}
          closeModal={closeConfigModal}
          configLevel="screen"
          update={(event) => update(event, 'configModalData')}
          configPath="cumulativeConfig"
          saveCallBack={store.getScreens}
          modalWidth={() => {
            if (window.innerWidth > 2750) {
              return '60%'
            } else if (window.innerWidth < 2075) {
              return 'xlg'
            }
            return '90%'
          }}
        />
      )}
      <Spacer />
    </Fragment>
  )
}

export default observer(Screen)
