export default {
  cells: [
    {
      key: 'Group Name',
      content: 'Group Name',
      isSortable: true,
      width: 12,
    },
    {
      key: 'Tokens',
      content: 'Tokens',
      isSortable: true,
    },
    {
      key: 'more',
      width: 6,
    },
  ],
}
