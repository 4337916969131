import React from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'

const styles = {
  hr: {
    borderBottom: `2px solid #dadada`, // this should come from theme but having a problem with theme provider
    'border-radius': '2px',
    margin: '20px 0 15px 0'
  }
}

export const Hr = ({ classes }) => <div className={classes.hr} />

Hr.propTypes = {
  classes: PropTypes.object
}

export default injectSheet(styles)(Hr)
