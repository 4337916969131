import React from 'react'
import FakeLink from '@components/UI/FakeLink/FakeLink'
import RefreshIcon from '@atlaskit/icon/glyph/refresh'
import Button, { ButtonGroup } from '@atlaskit/button'
import Tooltip from '@atlaskit/tooltip'
import { CheckboxStateless } from '@atlaskit/checkbox'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'
import store from './PiConfig.store'
import get from 'lodash/get'

const rows = data =>
  data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.id,
        content: (
          <CheckboxStateless
            isChecked={store.isChecked(item.id)}
            onChange={() => store.addToBulkUpdate(item.id)}
          />
        ),
      },
      {
        key: item.name,
        content: (
          <Tooltip content={item.url} position={'bottom'}>
            <FakeLink text={item.name} onClick={() => store.openModal(item)} />
          </Tooltip>
        ),
      },
      {
        key: get(item, 'location.region.organisation.id', index),
        content: get(item, 'location.region.organisation.name', ''),
      },
      {
        key: get(item, 'location.region.id', index),
        content: get(item, 'location.region.name', ''),
      },
      {
        key: get(item, 'location.id', index),
        content: get(item, 'location.name', ''),
      },
      {
        content: (
          <div className="tableRight">
            <ButtonGroup>
              <Tooltip content="Edit Device" position="bottom">
                <Button onClick={() => store.openModal(item)}>Edit</Button>
              </Tooltip>
              <Tooltip content="Reset Device" position="bottom">
                <Button
                  onClick={() =>
                    WarningModalStore.open({
                      heading: 'Reset Device',
                      content: `Are you sure you want to reset ${item.name}?`,
                      appearance: 'warning',
                      actionText: 'Reset',
                      action: () => store.resetPi(item.id),
                    })
                  }
                >
                  <RefreshIcon />
                </Button>
              </Tooltip>
              <Tooltip content="Delete Device" position="bottom">
                <DeleteButton
                  onClick={() =>
                    WarningModalStore.open({
                      heading: 'Delete Device',
                      content: `Are you sure you want to delete ${item.name}?`,
                      appearance: 'warning',
                      action: () => store.deletePiConfig(item.id),
                    })
                  }
                />
              </Tooltip>
            </ButtonGroup>
          </div>
        ),
      },
    ],
  }))

export default rows
