import React, { useState, useEffect } from 'react'
import Switch from 'react-switch'
import { observer } from 'mobx-react'
import DynamicTable from '@atlaskit/dynamic-table'
import injectSheet from 'react-jss'
import Select from '@atlaskit/select'
import { Field } from '@atlaskit/form'
import TextArea from '@atlaskit/textarea'
import TextField from '@atlaskit/textfield'
import Tooltip from '@atlaskit/tooltip'
import { TimePicker } from '@atlaskit/datetime-picker'
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel'
import OrganisationStore from '@components/Organisation/Organisation.store'
import Store from './Config.store'
import { halfHourTimes } from '../constants'
import ConfigStore from './Config.store'
import UserStore from '../../stores/userStore'
import { entainOrgIds } from '../../utils/globalVar'

const overwritingColor = 'red'
const overwritingWithSameValueColor = 'green'
const disabledColor = 'grey'
const inheritedColor = 'rgba(0,0,0,0.5)'
const styles = {
  head: {
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  cell: {
    textAlign: 'left',
    overflowWrap: 'anywhere',
  },
  overwriting: {
    color: overwritingColor,
    '& input': {
      color: overwritingColor,
    },
  },
  overwritingWithSameValue: {
    color: overwritingWithSameValueColor,
    '& input': {
      color: overwritingWithSameValueColor,
    },
  },
  inherited: {
    color: inheritedColor,
  },
  tableKey: {
    padding: '10px 0',
    '& p': {
      marginBottom: '5px',
    },
  },
}
const bookmakerAndContentIdKeys = {
  bookmakerId: { displayName: 'Bookmaker ID' },
  contentId: { displayName: 'Content ID' },
}

const versionKey = {}

let meetingPriorityKeys = {}

let keys = {
  // this is the order that the config keys will appear in the config modal on management. This can be slightly adjusted further down in the code where keys are spread and certian values are placed just before
  showPrices: {
    displayName: 'Show Prices',
  },
  inlineSpotlights: {
    displayName: 'Inline Spotlights',
    tooltip:
      'Spotlight show inline with their runner opposed to at the end of the race',
  },
  diomedSpotlights: {
    displayName: 'Diomed Spotlights',
    tooltip: 'Shortened version of spotlight comments',
  },
  countries: {
    displayName: 'Countries Shown',
    tooltip: 'Controls which countries events will be shown',
  },
  showRPZone: {
    displayName: 'Show RP Zone',
    tooltip: 'Shows tips for todays horses',
  },
  highlightToteRaces: {
    displayName: 'Highlight Tote Races',
    tooltip: 'Shows Tote races/scoop6',
  },
  verdictAtBottom: {
    displayName: 'Verdict at the Bottom',
    tooltip: 'Shows verdict at the bottom of race',
  },
  showAdverts: { displayName: 'Show Adverts' },
  showScreensaver: { displayName: 'Show Screensaver' },
  showNewspaper: { displayName: 'Show Newspaper' },
  defaultNewspaperPage: { displayName: 'Default Newspaper Page' },
  showCardInsights: {
    displayName: 'Show Race Card Insights',
    tooltip: 'How to read the race card images',
  },
  showNewspaperTips: { displayName: 'Show Newspaper Tips' },
  derivedMarkets: {
    displayName: 'Show Derived Markets',
    tooltip: 'Additional betting markets',
  },
  refreshBetweenTimes: {
    displayName: 'Refresh Between Times',
    tooltip: 'Force screen to refresh between times',
  },
  devTools: {
    displayName: 'Use Dev Tools',
    tooltip: 'Allow cursor to be used',
  },
  showGreyhounds: { displayName: 'Show Greyhound Meetings' },
  ghBookmakerId: { displayName: 'Greyhound Bookmaker ID' },
  showGhForecast: {
    displayName: 'Greyhounds Forecast Prices',
    tooltip:
      'Show betting forecast prices, will be overwritten by bookmaker prices',
  },
  showVirtuals: { displayName: 'Show Virtual Racing' },
  display24Hr: { displayName: 'Display In 24hr Time' },
  showFeatureRacesByDay: { displayName: 'Show Feature Races By Day' },
  showEPS: { displayName: 'Show Early Price Screen' },
  showHorsesNextOff: { displayName: 'Show Horses Next Off' },
  showSplashPage: { displayName: 'Show Splash Page' },
  showEasyView: { displayName: 'Show Easy View' },
  defaultToEasyView: { displayName: 'Default To Easy View' },
  showSports: { displayName: 'Show Sports' },
  sportsNavigationState: {
    displayName: 'Navigation State',
    tooltip:
      'Select how to display the navigation - default is navigation open',
  },
  specificSport: {
    displayName: 'Specific Sport',
    tooltip: 'Select one sport to show',
  },
  sportsDaysToAdd: {
    displayName: 'Days To Show',
    tooltip: 'Unless set, default is 3 days',
  },
  sportsOrder: {
    displayName: 'Sports Ordering',
    tooltip:
      'When Navigation Open is selected, choose the order to display the sports on the menu up to 3',
  },
  isIREShop: { displayName: 'Is Irish Shop' },
}

const setPriorityKeys = {
  morningMeetingPriority: {
    displayName: 'Morning Meeting Priority',
    tooltip: 'BSD morning meeting priorities to display in this order',
  },
  morningMeetingPriorityStartTime: {
    displayName: 'Morning Priority Start Time',
    tooltip: 'BSD morning priority start time',
  },
  afternoonMeetingPriority: {
    displayName: 'Afternoon Meeting Priority',
    tooltip: 'BSD afternoon meeting priorities to display in this order',
  },
  afternoonMeetingPriorityStartTime: {
    displayName: 'Afternoon Priority Start Time',
    tooltip: 'BSD afternoon priority start time',
  },
  eveningMeetingPriority: {
    displayName: 'Evening Meeting Priority',
    tooltip: 'BSD evening meeting priorities to display in this order',
  },
  eveningMeetingPriorityStartTime: {
    displayName: 'Evening Priority Start Time',
    tooltip: 'BSD evening priority start time',
  },
}

const allSportsLabels = [
  { label: 'American Football', value: 'americanfootball' },
  { label: 'Baseball', value: 'baseball' },
  { label: 'Basketball', value: 'basketball' },
  { label: 'Boxing', value: 'boxing' },
  { label: 'Cricket', value: 'cricket' },
  { label: 'Cycling', value: 'cycling' },
  { label: 'Darts', value: 'darts' },
  { label: 'Football', value: 'football' },
  { label: 'GAA Football', value: 'gaafootball' },
  { label: 'GAA Hurling', value: 'gaahurling' },
  { label: 'Golf', value: 'golf' },
  { label: 'Handball', value: 'handball' },
  { label: 'Ice Hockey', value: 'icehockey' },
  { label: 'MMA', value: 'mma' },
  { label: 'Motor Sports', value: 'motorsports' },
  { label: 'Rugby League', value: 'rugbyleague' },
  { label: 'Rugby Union', value: 'rugbyunion' },
  { label: 'Snooker', value: 'snooker' },
  { label: 'Tennis', value: 'tennis' },
  { label: 'Volleyball', value: 'volleyball' },
]

const booleanLabels = [
  { label: 'true', value: true },
  { label: 'false', value: false },
  { label: '-', value: null },
]

const newspaperLabels = [
  { label: 'false', value: 'false' },
  { label: 'UK', value: 'UK' },
  { label: 'IRE', value: 'IRE' },
]

const featureRaceDays = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
]

const easyViewLabels = [
  { label: 'Smart View', value: 'smartView' },
  { label: 'Off', value: 'off' },
  { label: '-', value: null },
]

const entainEasyViewLabels = [
  { label: 'Easy View', value: 'easyView' },
  { label: 'Off', value: 'off' },
  { label: '-', value: null },
]

const navigationStateLabels = [
  { label: 'Navigation Open', value: 'NAV_OPEN' },
  { label: 'Next off Football', value: 'NEXT_OFF_FOOTBALL' },
  { label: 'Next off Sports', value: 'NEXT_OFF_SPORTS' },
  { label: 'Specific Sport', value: 'SPECIFIC_SPORT' },
]

const idOptions = ['contentId', 'bookmakerId']

const sportsDaysToAddLabels = Array.from({ length: 7 }, (_, index) => ({
  label: `${index + 1}`,
  value: `${index + 1}`,
}))

const sportsOrderLabels = allSportsLabels.filter(
  (sport) => sport.value !== 'football',
)
const values = {
  showPrices: booleanLabels,
  inlineSpotlights: booleanLabels,
  diomedSpotlights: booleanLabels,
  showRPZone: booleanLabels,
  highlightToteRaces: booleanLabels,
  verdictAtBottom: booleanLabels,
  showVirtuals: booleanLabels,
  display24Hr: booleanLabels,
  showGreyhounds: booleanLabels,
  showFeatureRacesByDay: featureRaceDays,
  showGhForecast: booleanLabels,
  showAdverts: booleanLabels,
  showScreensaver: booleanLabels,
  showNewspaper: newspaperLabels,
  showCardInsights: booleanLabels,
  showNewspaperTips: booleanLabels,
  derivedMarkets: booleanLabels,
  devTools: booleanLabels,
  showEPS: booleanLabels,
  showHorsesNextOff: booleanLabels,
  showSplashPage: booleanLabels,
  showEasyView: easyViewLabels,
  defaultToEasyView: booleanLabels,
  showSports: booleanLabels,
  sportsNavigationState: navigationStateLabels,
  specificSport: allSportsLabels,
  sportsDaysToAdd: sportsDaysToAddLabels,
  sportsOrder: sportsOrderLabels,
  isIREShop: booleanLabels,
}

const configOrder = (configLevel) => {
  const order = ['organisation', 'region', 'location', 'screen']
  const index = order.indexOf(configLevel.toLowerCase())
  order.splice(index + 1)
  return order
}

const getAvailableVersionOptions = () => {
  const { availableConfigVersions } = ConfigStore

  let configVersionOptions = []

  availableConfigVersions.forEach((version) => {
    configVersionOptions.push({ label: version, value: version })
  })
  return configVersionOptions
}

const head = (config, classes, configLevel) => ({
  cells: [
    { key: 'name', content: 'Config Option' },
    ...configOrder(configLevel).reduce((acc, configKey) => {
      if (config[configKey]) {
        acc = [
          ...acc,
          {
            key: configKey,
            content: <div className={classes.head}>{configKey}</div>,
          },
        ]
      }
      return acc
    }, []),
  ],
})

const rows = ({ config, configLevel, update, configPath, editable }) => {
  const updatedKeys = {
    ...versionKey,
    ...bookmakerAndContentIdKeys,
    ...meetingPriorityKeys,
    ...keys,
  }
  return Object.keys(updatedKeys).map((key) => ({
    key,
    cells: [
      {
        key,
        content: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>{updatedKeys[key].displayName || key}</p>
            {updatedKeys[key].tooltip && (
              <Tooltip content={updatedKeys[key].tooltip}>
                <EditorPanelIcon label="Info" />
              </Tooltip>
            )}
          </div>
        ),
      },

      ...configOrder(configLevel).reduce((acc, configKey) => {
        if (config[configKey]) {
          const value = config[configKey].config[key]
          const parentToInheritFrom = [...acc]
            .reverse()
            .find((config) => config.value !== null && config.value !== '')
          const inheritedValue =
            parentToInheritFrom && parentToInheritFrom.value !== null
              ? parentToInheritFrom.value
              : null
          const overwriting = value !== null && inheritedValue !== null

          const overwritingWithSameValue =
            overwriting && value === inheritedValue

          let content = '-'

          const getLabel = (value, labels) => {
            const check = labels.find((label) => label.value === value)
            return check ? check.label : ''
          }

          const valueToLabelMap = allSportsLabels.reduce((map, sport) => {
            map[sport.value] = sport.label
            return map
          }, {})

          function replaceValuesWithLabels(valueString) {
            // Split the string by commas to get the individual values
            const values = valueString.split(',')

            // Map each value to its corresponding label
            const labels = values.map((value) => valueToLabelMap[value.trim()])

            // Join the labels back into a single string
            return labels.join(', ')
          }

          const determineContent = (value, inheritedValue, key) => {
            if (key === 'sportsOrder') {
              const targetValue = value !== null ? value : inheritedValue
              if (targetValue !== null) {
                return replaceValuesWithLabels(targetValue)
              }
              return '-'
            }

            const targetValue = value !== null ? value : inheritedValue
            if (targetValue !== null) {
              return key === 'sportsNavigationState' || key === 'specificSport'
                ? getLabel(
                    targetValue,
                    key === 'sportsNavigationState'
                      ? navigationStateLabels
                      : allSportsLabels,
                  )
                : `${targetValue}`
            }

            return '-'
          }

          content = determineContent(value, inheritedValue, key).replace(
            /,/g,
            ', ',
          )

          if (
            editable &&
            configLevel &&
            configLevel.toLowerCase() === configKey
          ) {
            if (key === 'sportsOrder') {
              content = (
                <Field label={null}>
                  <Select
                    name={key}
                    isMulti={values[key] === sportsOrderLabels}
                    options={
                      values[key] === sportsOrderLabels
                        ? sportsOrderLabels
                        : values[key] || OrganisationStore.getIdLabels(key)
                    }
                    value={
                      values[key] === sportsOrderLabels && value
                        ? value
                            .split(',')
                            .map((val) => ({ label: val, value: val }))
                        : (
                            values[key] || OrganisationStore.getIdLabels(key)
                          ).find(({ value: val }) => {
                            if (value !== null) {
                              return val === value
                            }
                            return val === inheritedValue || val === null
                          })
                    }
                    placeholder={
                      inheritedValue !== null
                        ? replaceValuesWithLabels(inheritedValue)
                        : '-'
                    }
                    styles={{
                      singleValue: (base) => ({
                        ...base,
                        color:
                          overwriting && !overwritingWithSameValue
                            ? overwritingColor
                            : overwritingWithSameValue
                            ? overwritingWithSameValueColor
                            : inheritedValue
                            ? inheritedColor
                            : '#000',
                      }),
                    }}
                    onChange={(e) => {
                      let value
                      if (
                        values[key] === sportsOrderLabels &&
                        Array.isArray(e)
                      ) {
                        value = e.map((item) => item.value).join(',')
                      } else {
                        value = e.value
                      }
                      update({
                        target: {
                          name: `${configPath}.${configKey}.config.${key}`,
                          value: value,
                        },
                      })
                    }}
                  />
                </Field>
              )
            } else if (values[key] || idOptions.includes(key)) {
              const getSportsNavigationState = (config, levels) => {
                for (const level of levels) {
                  const state = config[level].config.sportsNavigationState
                  if (state != null) {
                    return state
                  }
                }
                return undefined
              }

              const levels = ['screen', 'location', 'region', 'organisation']
              let navigationState

              switch (configLevel) {
                case 'screen':
                  navigationState = getSportsNavigationState(config, levels)
                  break
                case 'location':
                  navigationState = getSportsNavigationState(
                    config,
                    levels.slice(1),
                  )
                  break
                case 'region':
                  navigationState = getSportsNavigationState(
                    config,
                    levels.slice(2),
                  )
                  break
                case 'organisation':
                  navigationState = getSportsNavigationState(
                    config,
                    levels.slice(3),
                  )
                  break
                default:
                  navigationState = undefined
                  break
              }

              const isDisabled =
                key === 'specificSport' && navigationState !== 'SPECIFIC_SPORT'

              content = (
                <Field label={null}>
                  <Select
                    isDisabled={isDisabled}
                    name={key}
                    isMulti={values[key] === featureRaceDays}
                    options={
                      values[key] === featureRaceDays
                        ? featureRaceDays
                        : values[key] || OrganisationStore.getIdLabels(key)
                    }
                    value={
                      values[key] === featureRaceDays && !!value && value.length
                        ? value.split(',').map((value) => {
                            return { label: value, value: value }
                          })
                        : (
                            values[key] || OrganisationStore.getIdLabels(key)
                          ).find(({ value: val }) => {
                            if (value !== null) {
                              return val == value
                              // double equals is intentional as values can be number or string
                            }
                            return val === inheritedValue || val === null
                          })
                    }
                    placeholder={inheritedValue !== null ? inheritedValue : '-'}
                    styles={{
                      singleValue: (base) => ({
                        ...base,
                        get color() {
                          if (isDisabled) return disabledColor
                          if (overwriting && !overwritingWithSameValue) {
                            return overwritingColor
                          }
                          if (overwritingWithSameValue) {
                            return overwritingWithSameValueColor
                          }
                          if (inheritedValue) {
                            return inheritedColor
                          }
                          return '#000'
                        },
                      }),
                    }}
                    onChange={(e) => {
                      let value
                      if (values[key] === featureRaceDays && e.length > 0) {
                        value = e.map((item) => item.value).toString()
                      } else if (
                        key === 'sportsDaysToAdd' &&
                        e.value.length > 0
                      ) {
                        value = Number(e.value)
                      } else {
                        value = e.value
                      }
                      update({
                        target: {
                          name: `${configPath}.${configKey}.config.${key}`,
                          value: value,
                        },
                      })
                    }}
                  />
                </Field>
              )
            } else if (
              [
                'morningMeetingPriorityStartTime',
                'afternoonMeetingPriorityStartTime',
                'eveningMeetingPriorityStartTime',
              ].includes(key)
            ) {
              content = (
                <Field label={null}>
                  <TimePicker
                    resize="smart"
                    times={halfHourTimes}
                    css={{
                      maxHeight: '30px',
                      width: '100%',
                    }}
                    placeholder={inheritedValue !== null ? inheritedValue : '-'}
                    value={value !== null ? `${value}` : undefined}
                    shouldFitContainer
                    onChange={(e) => {
                      const value = e
                      update({
                        target: {
                          name: `${configPath}.${configKey}.config.${key}`,
                          value: value,
                        },
                      })
                    }}
                  />
                </Field>
              )
            } else if (key === 'defaultNewspaperPage') {
              content = (
                <Field label={null}>
                  <TextField
                    type="number"
                    min={0}
                    resize="smart"
                    css={{
                      height: '36px',
                      width: '100%',
                    }}
                    value={value !== null ? `${value}` : undefined}
                    placeholder={inheritedValue !== null ? inheritedValue : '-'}
                    shouldFitContainer
                    onChange={(e) => {
                      const value = e.target.value
                      update({
                        target: {
                          name: `${configPath}.${configKey}.config.${key}`,
                          value: value < 1 ? null : value,
                        },
                      })
                    }}
                  />
                </Field>
              )
            } else {
              content = (
                <Field label={null}>
                  <TextArea
                    resize="smart"
                    css={{
                      maxHeight: '30px',
                      width: '100%',
                    }}
                    value={value !== null ? `${value}` : undefined}
                    placeholder={
                      inheritedValue !== null
                        ? `${inheritedValue.replace(/,/g, ', ')}`
                        : '-'
                    }
                    shouldFitContainer
                    onChange={(e) => {
                      const value =
                        key === 'bookmakerId' || key === 'contentId'
                          ? e.target.value.toUpperCase()
                          : e.target.value
                      update({
                        target: {
                          name: `${configPath}.${configKey}.config.${key}`,
                          value: value,
                        },
                      })
                    }}
                  />
                </Field>
              )
            }
          }

          acc = [
            ...acc,
            {
              key: `${configKey}.config.${key}`,
              value: value !== null ? value : inheritedValue,
              content: <div>{content}</div>,
            },
          ]
        }
        return acc
      }, []),
    ],
  }))
}

const ConfigTableEditor = ({
  classes,
  config,
  configLevel,
  update,
  configPath,
  editable,
}) => {
  const { modalData } = Store
  const [isChecked, setIsChecked] = useState(false)

  const handleChange = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    if (
      modalData &&
      modalData.cumulativeConfig[configLevel].config
        .showMeetingPriorityByTime !== null
    ) {
      setIsChecked(
        modalData.cumulativeConfig[configLevel].config
          .showMeetingPriorityByTime,
      )
    } else {
      const configOrderArr = configOrder(configLevel).reverse()
      let checked = null
      for (let i = 1; i < configOrderArr.length; i++) {
        const configLevel = config[configOrderArr[i]].config
        if (
          configLevel.showMeetingPriorityByTime !== null &&
          checked === null
        ) {
          checked = configLevel.showMeetingPriorityByTime
          break
        }
      }
      setIsChecked(!!checked)
    }
  }, [])

  useEffect(() => {
    update({
      target: {
        name: `${configPath}.${configLevel}.config.showMeetingPriorityByTime`,
        value: isChecked,
      },
    })
  }, [isChecked])

  meetingPriorityKeys = isChecked
    ? { ...setPriorityKeys }
    : {
        meetingPriority: {
          displayName: 'Meeting Priority',
          tooltip: 'BSD priorities meeting to display in this order',
        },
      }

  const versions = getAvailableVersionOptions()
  const orgId = UserStore.organisationId

  const isEntain = entainOrgIds.includes(orgId)

  const isAdmin = UserStore.isAdmin()

  if (isAdmin) {
    keys = {
      showEasyView: {
        displayName: 'Show Easy View',
        tooltip: 'Display easy view toggle',
      },
      defaultToEasyView: {
        displayName: 'Default to Easy View',
      },
      showGreyhoundsEasyView: {
        displayName: 'Show Greyhounds Easy View',
      },
      ...keys,
    }

    values.showEasyView = [
      ...easyViewLabels,
      { label: 'Easy View', value: 'easyView' },
    ]
    values.showGreyhoundsEasyView = booleanLabels
  }

  if (isEntain) {
    keys = {
      showEasyView: {
        displayName: 'Show Easy View',
        tooltip: 'Display easy view toggle',
      },
      defaultToEasyView: {
        displayName: 'Default to Easy View',
      },
      showGreyhoundsEasyView: {
        displayName: 'Show Greyhounds Easy View',
      },
      ...keys,
    }
    values.showEasyView = entainEasyViewLabels
    values.showGreyhoundsEasyView = booleanLabels
  }

  if (configLevel !== 'screen') {
    versionKey.version = {
      displayName: 'Version',
      tooltip: 'Select BSD version',
    }
    values.version = versions
  } else {
    delete versionKey.version
    delete values.version
  }
  return (
    <div>
      <label
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '60px',
        }}
      >
        <span style={{ marginRight: '10px' }}>
          Set meeting priority by time of day
        </span>
        <Switch
          onChange={handleChange}
          checked={isChecked}
          height={16}
          width={36}
        />
      </label>

      <DynamicTable
        head={head(config, classes, configLevel)}
        rows={rows({
          config,
          configLevel,
          update,
          configPath,
          editable,
        })}
      />

      <div className={classes.tableKey}>
        <p>
          <span className={classes.inherited}>FADED</span> Shows the config
          value is inherited from a parent
        </p>
        <p>
          <span className={classes.overwritingWithSameValue}>GREEN</span> Shows
          the config has a value defined that is the same as the inherited
          value.
        </p>
        <p>
          <span className={classes.overwriting}>RED</span> Shows the config has
          a value defined that is overwriting the inherited value.
        </p>
      </div>
    </div>
  )
}

export default injectSheet(styles)(observer(ConfigTableEditor))
