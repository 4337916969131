import React from 'react'
import DynamicTable from '@atlaskit/dynamic-table'
import Modal from '@atlaskit/modal-dialog'
import PageHeader from '@components/PageHeader/PageHeader'
import { FieldTextStateless } from '@atlaskit/field-text'
import { Field } from '@atlaskit/form'
import hoc from '@utils/hoc'
import { object } from 'prop-types'
import Spacer from '@components/Spacer/Spacer'

import Button from '@atlaskit/button'
import head from './tableHead.js'
import rows from './rows.js'
import mainStore from '../Tokens.store'

import store from './Tokens.modal.store'

class PM {
  componentDidMount() {}
}

const styles = () => ({
  container: {
    display: 'flex',
  },
  leftCol: {
    flex: '0.6',
    marginRight: 30,
  },
  rightCol: {
    flex: 1,
  },
})

const UserModal = ({ classes }) => {
  const actions = [
    {
      text: 'Save',
      onClick: () => store.sendData(),
    },
    {
      text: 'Close',
      onClick: () => {
        store.cleanModal()
        mainStore.closeModal()
      },
    },
  ]

  return (
    <Modal
      actions={actions}
      onClose={() => mainStore.closeModal()}
      width={'850px'}
    >
      <div className={classes.container}>
        <div className={classes.leftCol}>
          <PageHeader text="Token" />

          <Field label="Name" required isRequired>
            <FieldTextStateless
              onChange={store.updateItem}
              name="name"
              value={store.name}
              shouldFitContainer
            />
          </Field>

          <Field label="Soft Name" required isRequired>
            <FieldTextStateless
              onChange={store.updateItem}
              name="softName"
              value={store.softName}
              shouldFitContainer
            />
          </Field>
        </div>
        <div className={classes.rightCol}>
          <PageHeader text="Translations">
            <Button onClick={() => store.addNewRow()} appearance="primary">
              Add New Translation
            </Button>
          </PageHeader>

          <DynamicTable
            head={head}
            rows={rows(store.token_translations)}
            loadingSpinnerSize="large"
            rowsPerPage={undefined}
            isLoading={false}
            defaultSortKey="name"
            defaultSortOrder="ASC"
          />
          <Spacer />
        </div>
      </div>
    </Modal>
  )
}

UserModal.propTypes = {
  classes: object,
}

export default hoc(PM, UserModal, styles)
