import { types, flow, destroy, getSnapshot } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import store from '../Tokens.store'

const { string, array, maybeNull } = types

const Language = types.model('Language', {
  id: string,
  name: string,
})

export const Translation = types
  .model('Translation', {
    id: string,
    text: string,
    tokenId: string,
    languageId: string,
    language: maybeNull(Language),
  })
  .actions(self => ({
    updateSelect({ value, label }) {
      self.language = { id: value, name: label }
      self.languageId = value
    },
    updateText(event) {
      self.text = event.target.value
    },
  }))

const TokenStores = types
  .model('TokenStores', {
    loading: false,
    id: string,
    name: string,
    softName: string,
    token_translations: array(Translation),
  })
  .actions(self => ({
    addNewRow() {
      self.token_translations.push({
        id: '',
        text: '',
        tokenId: '',
        languageId: '',
        language: { id: '', name: '' },
      })
    },
    deleteRow: flow(function* deleteRow(item, { id }) {
      if (id) {
        self.loading = true

        try {
          yield rest({
            url: `token-translation/${id}`,
            method: 'DELETE',
          })
        } catch (e) {
          console.log('error', e)
        }
        self.loading = false
      }

      destroy(item)
    }),
    updateItem(event) {
      self[event.target.name] = event.target.value.replace(/ /g, '')
    },
    cleanModal() {
      self.id = ''
      self.name = ''
      self.softName = ''
      self.token_translations = []
    },
    sendData: flow(function* sendData() {
      self.loading = true
      const { name, softName, id } = self
      const url = id ? `token/${id}` : 'token'
      const method = id ? 'PATCH' : 'POST'
      try {
        const res = yield rest({
          url,
          method,
          body: {
            name,
            softName,
          },
        })
        self.sendChildTokens(res.id)
      } catch (e) {
        console.log('error', e)
      }
      self.loading = false
    }),
    sendChildTokens: flow(function* sendChildTokens(parentId) {
      self.loading = true
      for (const item of self.token_translations) {
        const { text, languageId, id } = item
        const body = {
          tokenId: parentId,
          text,
          languageId,
        }
        try {
          const method = id ? 'PATCH' : 'POST'
          const url = id ? `token-translation/${id}` : 'token-translation'
          yield rest({
            url,
            method,
            body,
          })
        } catch (e) {
          console.log('error', e)
        }
      }
      self.loading = false
      self.cleanModal()
      store.fetchTokens()
      store.openModal()
    }),
    cloneChild({ id, name, softName, token_translations }) {
      self.id = id
      self.name = name
      self.softName = softName
      self.token_translations = getSnapshot(token_translations)
    },
  }))
  .create({
    id: '',
    name: '',
    softName: '',
    token_translations: [
      {
        id: '',
        text: '',
        tokenId: '',
        languageId: '',
        language: { id: '', name: '' },
      },
    ],
  })

makeInspectable(TokenStores)

export default TokenStores
