import React from 'react'
import PropTypes from 'prop-types'
import Button from '@atlaskit/button'
import DynamicTable from '@atlaskit/dynamic-table'
import hoc from '@utils/hoc'
import PageHeader from '@components/PageHeader/PageHeader'
import Modal from '@atlaskit/modal-dialog'
import { FieldTextStateless } from '@atlaskit/field-text'
import { Field } from '@atlaskit/form'
import Select from '@atlaskit/select'
import Spacer from '@components/Spacer/Spacer'

import store from './Permissions.store'
import rows from './rows'
import head from './tableHead'

class PM {
  componentDidMount() {
    store.getPermissions()
    store.getServices()
  }
}

export const Permissions = () => {
  const {
    state,
    postNewPermission,
    openModal,
    closeModal,
    data,
    modalOpen,
    changeInput,
    newName,
    storeNewSchema,
    getRoleLabels,
    newSchema,
  } = store

  const actions = [
    {
      text: 'Add new Role',
      onClick: () => postNewPermission(),
    },
    {
      text: 'Close',
      onClick: () => closeModal(),
    },
  ]

  return (
    <div>
      <PageHeader text="Roles">
        <Button onClick={openModal} appearance="primary">
          Create Role
        </Button>
      </PageHeader>

      <DynamicTable
        head={head}
        rows={rows()}
        rowsPerPage={data.length < 10 ? undefined : 10}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={state === 'pending'}
        isFixedSize
        emptyView={<h1>No Roles to Display</h1>}
      />

      {modalOpen && (
        <Modal actions={actions} heading={'Create Role'} onClose={closeModal}>
          <Field label="Name" required isRequired>
            <FieldTextStateless
              shouldFitContainer
              onChange={changeInput}
              value={newName}
            />
          </Field>

          <Field label="Copy Role" required isRequired>
            <Select
              placeholder="Select a Role"
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              onChange={storeNewSchema}
              options={getRoleLabels()}
              value={newSchema}
            />
          </Field>
        </Modal>
      )}

      <Spacer />
    </div>
  )
}

Permissions.propTypes = {
  classes: PropTypes.object,
}

export default hoc(PM, Permissions)
