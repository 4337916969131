import React from 'react'
import Select from '@atlaskit/select'
import { FieldTextStateless } from '@atlaskit/field-text'
import { MODAL_ZINDEX } from '@utils/globalVar'
import DeleteButton from '@components/DeleteButton/DeleteButton'

import langStore from '@components/Languages/Languages.store'
import store from './Tokens.modal.store'

const rows = data =>
  data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.language,
        content: (
          <Select
            options={langStore.getDataForSelect()}
            menuPortalTarget={document.body}
            value={{ label: item.language.name, value: item.language.id }}
            styles={{ menuPortal: base => ({ ...base, zIndex: MODAL_ZINDEX }) }}
            placeholder="Language"
            onChange={item.updateSelect}
          />
        ),
      },
      {
        key: item.text,
        content: (
          <FieldTextStateless
            isLabelHidden
            shouldFitContainer
            value={item.text}
            name="text"
            onChange={item.updateText}
          />
        ),
      },
      {
        key: 'delete',
        content: (
          <div className="tableRight">
            <DeleteButton onClick={() => store.deleteRow(item)} />
          </div>
        ),
      },
    ],
  }))

export default rows
