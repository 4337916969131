import React, { Fragment, useEffect } from 'react'
import PageHeader from '@atlaskit/page-header'
import { Observer } from 'mobx-react'
import Form from '@atlaskit/form/dist/cjs/Form'
import Field from '@atlaskit/form/dist/cjs/Field'
import { FieldTextStateless } from '@atlaskit/field-text'
import Button from '@atlaskit/button'
import FormFooter from '../FormFooter'
import LocationsStore from '../Location/Location.store'

const Location = () => {
  useEffect(() => {
    LocationsStore.search()
  }, [])
  return (
    <Observer>
      {() => (
        <Fragment>
          <PageHeader>Device Locations</PageHeader>
          <Form>
            <Field label="Shop ID">
              <FieldTextStateless
                name="shopIdFilter"
                type="text"
                shouldFitContainer
                onChange={LocationsStore.changeFilter}
                value={LocationsStore.shopIdFilter}
              />
            </Field>
            <Field label="Post Code">
              <FieldTextStateless
                name="postCodeFilter"
                type="text"
                shouldFitContainer
                onChange={LocationsStore.changeFilter}
                value={LocationsStore.postCodeFilter}
              />
            </Field>
            <Field label="City">
              <FieldTextStateless
                name="cityFilter"
                type="text"
                shouldFitContainer
                onChange={LocationsStore.changeFilter}
                value={LocationsStore.cityFilter}
              />
            </Field>
            <FormFooter>
              <Button
                appearance="secondary"
                onClick={LocationsStore.resetFilters}
              >
                Reset Filters
              </Button>
              <Button appearance="primary" onClick={LocationsStore.search}>
                Search
              </Button>
            </FormFooter>
          </Form>
        </Fragment>
      )}
    </Observer>
  )
}
export default Location
