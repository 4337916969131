import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'

const { string } = types

const ForgotPassword = types
  .model('ForgotPasswordStore', {
    email: string
  })
  .volatile(() => ({
    formRef: {}
  }))
  .actions(self => ({
    updateFormRef(ref) {
      self.formRef = ref
    },
    updateEmail(event) {
      self.email = event.target.value
    },
    submitForgotPassword: flow(function* submitForgotPassword(
      event,
      closeModal
    ) {
      event.preventDefault()
      try {
        const data = {
          url: `reset-password`,
          method: 'POST',
          successMessage: `Reset link sent to ${self.email}`,
          body: {
            email: self.email
          }
        }
        const response = yield rest(data)
        if (response.email) {
          closeModal()
        }
      } catch (e) {
        throw e
      }
    })
  }))
  .create({
    email: ''
  })

makeInspectable(ForgotPassword)

export default ForgotPassword
