const isLongEnough = (val, options = { minLength: 0, maxLength: 9999 }) =>
  val.length >= options.minLength && val.length <= options.maxLength

const containsOneNumber = val => /\d/g.test(val)

const isSameAsFirst = (pwd, pwd2) => pwd === pwd2

const isJson = string => {
  try {
    JSON.parse(string)
  } catch (e) {
    return false
  }
  return true
}

export { isLongEnough, containsOneNumber, isSameAsFirst, isJson }
