import React from 'react'
import { InlineEditStateless } from '@atlaskit/inline-edit'
import SingleLineTextInput from '@atlaskit/input'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'

import { CheckboxSelect } from '@atlaskit/select'
import store from './Permissions.store'

const rows = () =>
  store.data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.role,
        content: (
          <div style={{ paddingLeft: '8px' }}>
            <InlineEditStateless
              isLabelHidden
              areActionButtonsHidden
              isEditing={item.isEditing}
              onEditRequested={item.role !== 'admin' ? item.editing : () => {}}
              onCancel={item.editing}
              onConfirm={item.submitRole}
              readView={
                <div style={{ width: '150px' }}>
                  <SingleLineTextInput
                    isEditing={false}
                    value={item.role}
                    shouldFitContainer
                  />
                </div>
              }
              editView={
                <SingleLineTextInput
                  isEditing
                  isInitiallySelected
                  value={item.role}
                  onChange={item.updateRole}
                  shouldFitContainer
                />
              }
            />
          </div>
        ),
      },
      {
        key: item.schema,
        content: (
          <CheckboxSelect
            placeholder="Choose Roles"
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            options={store.getLabels()}
            value={item.getData()}
            onChange={item.updateSchema}
            isDisabled={item.role === 'admin'}
            onBlur={item.submitRole}
          />
        ),
      },
      {
        content: (
          <div className="tableRight">
            {item.role !== 'admin' && (
              <DeleteButton
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Delete Role',
                    content: `Are you sure you want to delete ${item.role}?`,
                    appearance: 'warning',
                    action: () => store.deletePermission(item.id),
                  })
                }
              />
            )}
          </div>
        ),
      },
    ],
  }))

export default rows
