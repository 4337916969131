const quarterHours = ['00', '15', '30', '45']
const halfHours = ['00', '30']

export const quaterHourTimes = []
for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 4; j++) {
    quaterHourTimes.push(('0' + i).slice(-2) + ':' + quarterHours[j])
  }
}

export const halfHourTimes = []
for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 2; j++) {
    halfHourTimes.push(('0' + i).slice(-2) + ':' + halfHours[j])
  }
}
