import React from 'react'
import { object, bool } from 'prop-types'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Button, { ButtonGroup } from '@atlaskit/button'
import isEmpty from 'lodash/isEmpty'
import { ReactMic } from 'react-mic'

import store from './Video.store'

const EventInfoModal = ({ active = {}, classes }) => {
  const { pre_race_instance_comments, isFemale, setActive, filePath } = active
  const {
    clearAll,
    closeModal,
    updateRef,
    audioData,
    isRecording,
    updateRecording,
    uploadData,
    previewClick,
  } = store
  const actions = [
    {
      text: 'Close',
      onClick: () => {
        clearAll()
        closeModal()
      },
    },
  ]

  return (
    <Modal
      actions={actions}
      heading={'Record Audio'}
      onClose={store.closeModal}
    >
      <form
        ref={ref => {
          updateRef('formRef', ref)
        }}
        encrypt="multipart/form-data"
      />
      {/* cant spoof this in JS for some reason ?? */}

      <p>{pre_race_instance_comments}</p>

      <div className={classes.buttonContainer}>
        <ButtonGroup>
          <Button
            isSelected={isFemale()}
            onClick={() => {
              setActive('female_video_blank')
            }}
          >
            Female Video
          </Button>
          <Button
            isSelected={!isFemale()}
            onClick={() => {
              setActive('male_video_blank')
            }}
          >
            Male Video
          </Button>
        </ButtonGroup>
      </div>

      <video
        controls={false}
        ref={ref => {
          updateRef('videoRef', ref)
        }}
      >
        <source src={filePath()} type="video/mp4" />
      </video>

      <audio
        controls={false}
        src={audioData ? audioData.blobURL : ''}
        className="video_audio_controls"
        ref={ref => {
          updateRef('audioControlRef', ref)
        }}
      />

      <ReactMic
        record={isRecording}
        className="sound-wave"
        onStop={ref => {
          updateRef('audioData', ref)
        }}
        strokeColor="#ffffff"
        backgroundColor="#000000"
      />

      <div className={classes.buttonContainer}>
        <ButtonGroup>
          <Button onClick={previewClick}>Preview</Button>
          <Button appearance="danger" onClick={updateRecording}>
            {isRecording ? 'STOP' : 'Record'}
          </Button>
          <Button
            appearance="primary"
            onClick={uploadData}
            isDisabled={isEmpty(audioData)}
          >
            Save
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  )
}

EventInfoModal.propTypes = {
  classes: object,
  isNew: bool,
  active: object,
}

export default observer(EventInfoModal)
