import makeInspectable from 'mobx-devtools-mst'
import { types, flow, getSnapshot } from 'mobx-state-tree'
import { format } from 'date-fns'

import rest from '@utils/rest'
import { getBucketContentUrl } from './utils'
import { contentIds } from '../../utils/globalVar'
import { history } from '@stores/routerStore'

const { string, array, enumeration, integer } = types

const getPath = () => {
  const ENVIRONMENT_DOMAIN = process.env.NODE_ENV
  if (ENVIRONMENT_DOMAIN) {
    if (ENVIRONMENT_DOMAIN === 'production') {
      return 'production-racingpost.com'
    }
    if (ENVIRONMENT_DOMAIN === 'staging') {
      return 'staging-racingpost.com'
    }
    if (ENVIRONMENT_DOMAIN === 'development') {
      return 'dev-racingpost.com'
    }
  }
  return 'dev-racingpost.com'
}
const DOMAIN = getPath()

export const bucket = `https://s3.eu-west-1.amazonaws.com/bsd-assets.${DOMAIN}`

const FeatureRace = types.model('FeatureRace', {
  id: string,
  featureRaceName: string,
  featureRaceDate: string,
  displayFromDate: string,
  raceTime: string,
  createdAt: string,
  updatedAt: string,
  meetingId: types.maybeNull(string),
  meetingCountry: types.maybeNull(string),
  courseName: string,
  raceId: string,
  bookmakers: string,
  leftContent: integer,
  leftContentImage: types.maybeNull(string),
  leftContentHeight: types.maybeNull(types.integer),
  leftContentWidth: types.maybeNull(types.integer),
  rightContent: integer,
  rightContentImage: types.maybeNull(string),
  rightContentHeight: types.maybeNull(types.integer),
  rightContentWidth: types.maybeNull(types.integer),
})

const today = format(new Date(), 'yyyy-MM-dd')
const bookmakersString = contentIds.map((bookmaker) => bookmaker).toString()

const EMPTY_CLONE = {
  id: '',
  featureRaceName: '',
  featureRaceDate: today,
  displayFromDate: today,
  raceTime: '',
  createdAt: '',
  updatedAt: '',
  meetingId: '',
  meetingCountry: '',
  courseName: '',
  raceId: '',
  bookmakers: bookmakersString,
  leftContent: 0,
  leftContentImage: '',
  leftContentHeight: 0,
  leftContentWidth: 0,
  rightContent: 0,
  rightContentImage: '',
  rightContentHeight: 0,
  rightContentWidth: 0,
}
let previousRaceId = ''
let previousLeftContent = false
let previousRightContent = false

const FeatureRaceStore = types
  .model('EditScopeStore', {
    data: array(FeatureRace),
    state: enumeration('State', ['pending', 'done', 'error']),
    leftContentPreview: types.maybeNull(string),
    rightContentPreview: types.maybeNull(string),
    modalData: FeatureRace,
    isPreviewOpen: types.boolean,
  })
  .actions((self) => ({
    update(event) {
      self.modalData[event.target.name] = event.target.value
    },
    openModal(featureRace) {
      self.getFeatureRaces()
      const modalData = featureRace
        ? { ...getSnapshot(featureRace) }
        : EMPTY_CLONE
      self.modalData = modalData
      previousRaceId = modalData.raceId
      previousLeftContent = !!modalData.leftContent
      previousRightContent = !!modalData.rightContent
      if (modalData.leftContent) {
        self.setLeftContent(modalData.raceId)
      }
      if (modalData.rightContent) {
        self.setRightContent(modalData.raceId)
      }
      history.push(`/feature-races/schedule`)
    },

    closeModal() {
      self.modalData = EMPTY_CLONE
      history.push(`/feature-races`)
    },

    setLeftContent: flow(function* setLeftContent(raceId) {
      const response = yield fetch(
        `${bucket}/FEATURE_RACE/${raceId}/LEFT_CONTENT`,
      )
      const base64 = yield response.text()
      self.modalData.leftContentImage = base64
    }),

    setRightContent: flow(function* setRightContent(raceId) {
      const response = yield fetch(
        `${bucket}/FEATURE_RACE/${raceId}/RIGHT_CONTENT`,
      )
      const base64 = yield response.text()
      self.modalData.rightContentImage = base64
    }),

    getFeatureRaces: flow(function* getFeatureRaces() {
      try {
        const res = yield rest({
          url: `feature-races`,
        })
        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res.data
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch feature races', error)
        self.state = 'error'
      }
    }),
    saveFeatureRace: flow(function* saveFeatureRace() {
      let options
      const cleanBody = {
        featureRaceName: self.modalData.featureRaceName,
        bookmakers: `${self.modalData.bookmakers},`,
        raceId: self.modalData.raceId,
        raceTime: self.modalData.raceTime,
        courseName: self.modalData.courseName,
        featureRaceDate: format(
          new Date(self.modalData.featureRaceDate),
          'yyyy-MM-dd',
        ),

        displayFromDate: format(
          new Date(self.modalData.displayFromDate),
          'yyyy-MM-dd',
        ),

        leftContentImage: self.modalData.leftContentImage,
        rightContentImage: self.modalData.rightContentImage,
        leftContent: !!self.modalData.leftContentImage,
        rightContent: !!self.modalData.rightContentImage,
        createdAt: self.modalData.createdAt,
        updatedAt: self.modalData.updatedAt,
      }
      const removeLeftContent =
        previousLeftContent && self.modalData.leftContentImage === ''
      const removeRightContent =
        previousRightContent && self.modalData.rightContentImage === ''

      if (self.modalData.id === '') {
        options = {
          method: 'POST',
          url: 'feature-races',
          body: cleanBody,
          successMessage: `${self.modalData.featureRaceName} Successfully Added`,
        }
      } else {
        options = {
          method: 'PATCH',
          url: `feature-races/${self.modalData.id}`,
          body: {
            ...cleanBody,
            id: self.modalData.id,
            previousRaceId,
            previousLeftContent,
            removeLeftContent,
            removeRightContent,
          },
          successMessage: `${self.modalData.featureRaceName} Successfully Updated`,
        }
      }
      self.closeModal()
      yield rest(options)
      self.getFeatureRaces()
    }),

    openPreview: flow(function* openPreview({ isLeftContent, raceId }) {
      const bucketUrl = getBucketContentUrl({ isLeftContent, raceId })
      const response = yield fetch(bucketUrl)

      if (response.status === 200) {
        const base64 = yield response.text()

        if (isLeftContent) {
          self.leftContentPreview = base64
        } else {
          self.rightContentPreview = base64
        }
      } else {
        if (isLeftContent) {
          self.leftContentPreview = self.modalData.leftContentImage
            ? self.modalData.leftContentImage
            : 'NO_PREVIEW'
        } else {
          self.rightContentPreview = self.modalData.rightContentImage
            ? self.modalData.rightContentImage
            : 'NO_PREVIEW'
        }
      }
      self.isPreviewOpen = true
    }),

    closePreview() {
      self.isPreviewOpen = false
      self.leftContentPreview = null
      self.rightContentPreview = null
    },

    deleteFeatureRace: flow(function* deleteFeatureRace(id, raceId) {
      self.state = 'pending'
      try {
        yield rest({
          url: `feature-races/${id}?raceId=${raceId}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        self.getFeatureRaces()
        self.state = 'done'
      } catch (error) {
        console.error('Failed to delete race', error)
        self.state = 'error'
      }
    }),
    clearScheduleForm() {
      self.modalData = EMPTY_CLONE
    },
  }))
  .create({
    data: [],
    modalData: EMPTY_CLONE,
    state: 'done',
    leftContentPreview: null,
    rightContentPreview: null,
    isPreviewOpen: false,
  })

makeInspectable(FeatureRaceStore)

export default FeatureRaceStore
