import React from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'

import store from './Adverts.store'

const Preview = () => {
  const { closePreview, preview } = store
  const actions = [{ text: 'Close', onClick: () => closePreview() }]

  return (
    <Modal actions={actions} heading={'Advert Preview'} onClose={closePreview}>
      <img
        src={preview}
        style={{
          display: 'block',
          margin: 'auto',
        }}
      />
    </Modal>
  )
}

export default observer(Preview)
