import React from 'react'
import PageHeader from '@atlaskit/page-header'
import NavLink from '@components/NavLink/NavLink'

const Authentication = () => (
  <div>
    <PageHeader>Authentication</PageHeader>
    {/*
    hide till complete
    <NavLink url={'/authentication/rpd'} text={'RPD'} />
    */}
    <NavLink url={'/authentication/rpt'} text={'RPT'} />
  </div>
)

export default Authentication
