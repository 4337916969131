import React from 'react'
import PageHeader from '@atlaskit/page-header'
import NavLink from '@components/NavLink/NavLink'

const Translations = () => (
  <div>
    <PageHeader>Translations</PageHeader>
    <NavLink url={'/translations/tokens'} text={'Tokens'} />
    <NavLink url={'/translations/dialogues'} text={'Dialogues'} />
    <NavLink url={'/translations/groups'} text={'Groups'} />
    <NavLink url={'/translations/languages'} text={'Languages'} />
  </div>
)

export default Translations
