export default {
  cells: [
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'logo',
      content: 'Logo',
      isSortable: true,
    },
    {
      key: 'regions.length',
      content: 'Regions',
    },
    {
      key: 'oddIds',
      content: 'Bookmaker Ids',
    },
    {
      key: 'contentIds',
      content: 'Content Ids',
    },
    {
      key: 'more',
    },
  ],
}
