import React from 'react'
import WarningModal from '@ui/WarningModal/Warning.modal'

const ModalContainer = () => (
  <div>
    <WarningModal />
  </div>
)

export default ModalContainer
