import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import DynamicTable from '@atlaskit/dynamic-table'
import PageHeader from '@components/PageHeader/PageHeader'
import store from './Authentication.store'
import Modal from './Authentication.modal'
import rows from './rows'
import head from './tableHead'
import injectSheet from 'react-jss'
import { object } from 'prop-types'
import Spacer from '@components/Spacer/Spacer'

const styles = {
  toggle: {
    '& * ': {
      // hack for atlas kit toggle box
      boxSizing: 'content-box !important',
    },
  },
}

class Authentication extends Component {
  componentDidMount() {
    store.getAuthentications()
  }

  render() {
    const { data, state, modalOpen } = store

    return (
      <Fragment>
        <PageHeader text={`${store.isRpt ? 'RPT' : 'RPD'}`} />

        <DynamicTable
          head={head}
          rows={rows(data, this.props.classes)}
          rowsPerPage={data.length < 10 ? undefined : 10}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={state === 'pending'}
          isFixedSize
        />

        {modalOpen && <Modal />}
        <Spacer />
      </Fragment>
    )
  }
}

Authentication.propTypes = {
  classes: object,
}

export default injectSheet(styles)(observer(Authentication))
