import React, { Fragment, useEffect } from 'react'
import { Observer } from 'mobx-react'
import PageHeader from '@atlaskit/page-header'
import Select from '@atlaskit/select'
import Form from '@atlaskit/form/dist/cjs/Form'
import Field from '@atlaskit/form/dist/cjs/Field'
import { FieldTextStateless } from '@atlaskit/field-text'
import Button from '@atlaskit/button'
import { Link } from 'react-router-dom'
import FormFooter from '../FormFooter'
import AdvertsStore from '../Adverts/Adverts.store'
import AdvertsHelp from './HelpModal'
import { Route } from 'react-router'

const Adverts = () => {
  useEffect(() => {
    AdvertsStore.search()
  }, [])
  return (
    <Observer>
      {() => (
        <Fragment>
          <PageHeader>Filter</PageHeader>
          <Form>
            <Field label="Name">
              <FieldTextStateless
                name="nameFilter"
                type="text"
                shouldFitContainer
                onChange={AdvertsStore.changeFilter}
                value={AdvertsStore.nameFilter}
              />
            </Field>
            <Field label="Bookmaker ID">
              <FieldTextStateless
                name="bookmakerFilter"
                type="text"
                shouldFitContainer
                onChange={AdvertsStore.changeFilter}
                value={AdvertsStore.bookmakerFilter}
              />
            </Field>
            <Field label="Status">
              <Select
                name="statusFilter"
                options={[
                  { label: 'Active', value: 'Active' },
                  { label: 'Scheduled', value: 'Scheduled' },
                  { label: 'Expired', value: 'Expired' },
                ]}
                placeholder="Status"
                onChange={(item) =>
                  AdvertsStore.changeFilter({
                    target: { name: 'statusFilter', value: item.value },
                  })
                }
              />
            </Field>
            <FormFooter>
              <Button
                appearance="secondary"
                onClick={AdvertsStore.resetFilters}
              >
                Reset Filters
              </Button>
              <Button appearance="primary" onClick={AdvertsStore.search}>
                Search
              </Button>
            </FormFooter>
            <FormFooter>
              <Link to={'/adverts/help'}>
                <Button appearance="primary">Help</Button>
              </Link>
              <Route path={'/adverts/help'} component={AdvertsHelp} />
            </FormFooter>
          </Form>
        </Fragment>
      )}
    </Observer>
  )
}
export default Adverts
