import React from 'react'
import { func } from 'prop-types'
import Button from '@atlaskit/button'
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove'

const DeleteButton = ({ onClick }) => (
  <Button appearance="danger" onClick={onClick}>
    <EditorRemoveIcon />
  </Button>
)

DeleteButton.propTypes = {
  onClick: func.isRequired,
}

export default DeleteButton
