import { types, flow, getSnapshot } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import set from 'lodash/set'
import get from 'lodash/get'
import OrganisationStore from '@components/Organisation/Organisation.store'
import RegionStore from '@components/Region/Region.store'
import LocationStore from '@components/Location/Location.store'
import UserStore from '../../stores/userStore.js'
import ConfigStore, {
  ConfigModel,
  defaultConfig,
} from '@components/Config/Config.store'

const { boolean, maybeNull, string, array, enumeration, optional } = types

const Organisation = types.model('Organisation', {
  id: string,
  name: string,
  config: maybeNull(ConfigModel),
})

const Region = types.model('Region', {
  id: string,
  name: string,
  organisationId: string,
  organisation: Organisation,
  config: maybeNull(ConfigModel),
  isNew: false,
})

const Location = types.model('Location', {
  id: string,
  name: string,
  regionId: string,
  region: Region,
  config: maybeNull(ConfigModel),
})

const Screen = types.model('Screen', {
  id: string,
  name: string,
  locationId: string,
  location: Location,
  config: maybeNull(ConfigModel),
})

const ModalData = types.model('ModalData', {
  id: optional(string, ''),
  name: optional(string, ''),
  locationId: optional(string, ''),
  location: Location,
  isNew: optional(boolean, false),
})

const ConfigModalData = types.model('ConfigModalData', {
  nodeId: string,
  cumulativeConfig: types.model({
    organisation: maybeNull(ConfigModel, () => defaultConfig()),
    region: maybeNull(ConfigModel, () => defaultConfig()),
    location: maybeNull(ConfigModel, () => defaultConfig()),
    screen: maybeNull(ConfigModel, () => defaultConfig()),
  }),
})
const emptyConfigModalData = () => ({
  nodeId: '',
  cumulativeConfig: {
    organisation: defaultConfig(),
    region: defaultConfig(),
    location: defaultConfig(),
    screen: defaultConfig(),
  },
})

const emptyClone = ({ organisationId = '' } = {}) => ({
  id: '',
  name: '',
  locationId: '',
  location: {
    id: '',
    name: '',
    regionId: '',
    region: {
      id: '',
      name: '',
      organisationId,
      organisation: { id: organisationId, name: '' },
    },
  },
  isNew: true,
})

const ScreenStore = types
  .model('ScreenStore', {
    data: array(Screen),
    state: enumeration('State', ['pending', 'done', 'error']),
    modalOpen: false,
    modalData: ModalData,
    configEditorOpen: boolean,
    configModalData: ConfigModalData,
  })
  .volatile(() => ({
    modalRef: {},
  }))
  .views(self => ({
    getLabels(data = self.data) {
      return data.map(item => ({ label: item.name, value: item.id }))
    },
    getSingleLabel(id) {
      return self.getLabels().find(item => item.value === id)
    },
    getLabelsForOrganisationid(organisationId) {
      return self.getLabels(
        organisationId === ''
          ? self.data
          : self.data.filter(x => x.organisationId === organisationId),
      )
    },
  }))
  .actions(self => ({
    updateFormRef(ref) {
      self.modalRef = ref
    },
    closeConfigModal() {
      self.configModalData = emptyConfigModalData()
      self.configEditorOpen = false
    },
    openConfigModal(location) {
      const modalData = { ...getSnapshot(location) }
      const configModalData = {
        nodeId: location.id,
        cumulativeConfig: {
          organisation:
            get(modalData, 'location.region.organisation.config') ||
            defaultConfig(),
          region: get(modalData, 'location.region.config') || defaultConfig(),
          location: get(modalData, 'location.config') || defaultConfig(),
          screen: get(modalData, 'config') || defaultConfig(),
        },
      }

      self.configModalData = configModalData
      self.configEditorOpen = true
    },
    update(event, path = 'modalData') {
      const change =
        get({ ...self[path] }, event.target.name) !== event.target.value

      if (change) {
        set(self[path], event.target.name, event.target.value)

        if (event.target.name === 'location.region.organisationId') {
          self.modalData.location.regionId = ''
          self.modalData.locationId = ''
        }
        if (event.target.name === 'location.regionId') {
          self.modalData.locationId = ''
        }
      }
    },
    openModal(screen) {
      OrganisationStore.getOrganisations()
      RegionStore.getRegions()
      LocationStore.getLocations()
      const modalData = screen
        ? { ...getSnapshot(screen) }
        : emptyClone({ organisationId: UserStore.organisationId })
      self.modalData = modalData
      self.modalOpen = true
    },
    closeModal() {
      self.modalData = emptyClone()
      self.modalOpen = false
    },

    removeConfig: flow(function* removeConfig() {
      const configId = self.modalData.config.id
      if (configId && configId.length > 0) {
        yield ConfigStore.deleteConfig(configId)
      }
      self.modalData.config = null
      self.getScreens()
    }),

    saveScreen: flow(function* saveScreen(screen = self.modalData) {
      if (self.modalRef.validate && self.modalRef.validate().isInvalid) return

      let options
      if (screen.id === '') {
        const cleanBody = { ...screen }
        delete cleanBody.id
        options = {
          method: 'POST',
          url: 'screen',
          body: cleanBody,
          successMessage: `${screen.name} Successfully Added`,
        }
      } else {
        options = {
          method: 'PATCH',
          url: `screen/${screen.id}`,
          body: self.modalData,
          successMessage: `${screen.name} Successfully Updated`,
        }
      }

      yield rest(options)

      self.getScreens()
      self.closeModal()
    }),

    getScreens: flow(function* getScreens(organisationId) {
      self.state = 'pending'
      try {
        const res = yield rest({
          url: `screen${
            organisationId ? `?organisationId=${organisationId}` : ''
          }`,
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch screens', error)
        self.state = 'error'
      }
    }),

    deleteScreen: flow(function* deleteScreen(id) {
      self.state = 'pending'
      try {
        yield rest({
          url: `screen/${id}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        self.state = 'done'
        self.getScreens()
      } catch (error) {
        console.error('Failed to fetch screens', error)
        self.state = 'error'
      }
    }),
  }))
  .create({
    data: [],
    state: 'done',
    modalOpen: false,
    modalData: emptyClone(),
    configEditorOpen: false,
    configModalData: emptyConfigModalData(),
  })

makeInspectable(ScreenStore)

export default ScreenStore
