import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import map from 'lodash/map'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'

import config from '@config/config'
import flagStore from '@stores/flagStore'
import data from './data'

const { string, boolean, array, maybeNull, number } = types

const today = dayjs().format('YYYYMMDD')

const Race = types
  .model('Race', {
    horse_name: maybeNull(string),
    pre_race_instance_comments: maybeNull(string),
    course_country_code: string,
    time: string,
    course_style_name: string,
    diffusion_course_name: string,
    race_instance_uid: number,
    active: false,
    activeTarget: '',
    state: false,
  })
  .views(self => ({
    generateId() {
      return `${dayjs(self.time).format('YYYYMMDD_HHmm')}_${get(data, [
        `${self.diffusion_course_name}`,
      ])}_${self.course_country_code}.mp4`
    },
    filePath() {
      return `${config.s3}/${self.activeTarget}/${today}/${self.generateId()}`
    },
    isFemale() {
      return self.activeTarget === 'female_video_blank'
    },
  }))
  .actions(self => ({
    downloadFile: flow(function* downloadFile(target) {
      const url = `${config.s3}/${target}/${today}/${self.generateId()}`
      try {
        const getData = yield fetch(url)
        const blob = yield getData.blob()
        fileDownload(blob, self.generateId())
      } catch (e) {
        flagStore.addFlag({
          title: 'Error downloading video file',
          type: 'error',
        })
      }
    }),
    generateNew: flow(function* generateNew() {
      self.state = true

      try {
        const res = yield fetch(`${config.videoApi}${self.race_instance_uid}`)
        yield res.json()
        self.state = false
      } catch (error) {
        self.state = false
        console.error('Fail', error)
      }
    }),
    setActive(target = '') {
      self.active = true
      self.activeTarget = target
      if (VideoStore.videoRef.load) {
        VideoStore.videoRef.load()
      }
    },
    clearActive() {
      self.active = false
      self.activeTarget = ''
    },
  }))

const VideoStore = types
  .model('VideoStore', {
    state: boolean,
    videoState: false,
    races: array(Race),
    previewModal: false,
    recordModal: false,
    auto_audio: array(string),
    manual_audio: array(string),
    female_video_blank: array(string),
    male_video_blank: array(string),
    isRecording: false,
  })
  .volatile(() => ({
    videoRef: {},
    formRef: {},
    audioData: {},
    audioControlRef: {},
  }))
  .views(self => ({
    getActive() {
      return self.races.find(item => item.active)
    },
    filterAutoVideo(target, ref) {
      return !self[target].find(item => item === ref)
    },
  }))
  .actions(self => ({
    uploadData: flow(function* uploadData() {
      const active = self.getActive()
      const activeId = active.generateId().replace('.mp4', '')
      const file = new File([self.audioData.blob], `${activeId}.webm`, {
        type: 'video/webm',
        lastModified: Date.now(),
      })
      const formData = new FormData(self.formRef)
      formData.append('file', file)
      formData.append('url', activeId)
      formData.append('isMale', !self.getActive().isFemale)

      try {
        yield fetch(config.videoApi, {
          method: 'POST',
          body: formData,
          headers: {
            'x-access-token': localStorage.getItem('backoffice-jwt'),
          },
        })
      } catch (e) {
        console.log(e)
        flagStore.addFlag({
          title: 'Error uploading file',
          type: 'error',
        })
      }
    }),
    previewClick() {
      self.audioData.pause()
      self.audioData.currentTime = 0
      self.audioData.play()
      self.videoRef.pause()
      self.videoRef.currentTime = 0
      self.videoRef.play()
    },
    updateRef(target, ref) {
      self[target] = ref
    },
    onStop(blob) {
      self.audioData = blob
    },
    updateRecording() {
      self.videoRef.paused ? self.videoRef.play() : self.videoRef.pause()
      self.isRecording = !self.isRecording
    },
    openModal(target) {
      self[target] = true
    },
    closeModal() {
      self.previewModal = false
    },
    clearAll() {
      self.races.forEach(item => item.clearActive())
    },
    fetchRaces: flow(function* fetchRaces() {
      self.state = true

      try {
        const res = yield fetch(config.horseApi)
        const json = yield res.json()

        const finalData = json.data.reduce(
          (acc, item) =>
            dayjs(item.time).format('DD-MM') === dayjs().format('DD-MM')
              ? acc.concat({
                  horse_name: get(
                    item,
                    'spotlight_verdict_selection.horse_name',
                  ),
                  pre_race_instance_comments:
                    item.verdict.pre_race_instance_comments,
                  course_country_code: item.verdict.course_country_code,
                  time: item.verdict.race_datetime,
                  course_style_name: item.verdict.course_style_name,
                  diffusion_course_name: item.verdict.diffusion_course_name,
                  race_instance_uid: item.verdict.race_instance_uid,
                })
              : acc,
          [],
        )

        const sortData = sortBy(finalData, ['diffusion_course_name', 'time'])
        self.races = sortData
        self.state = false
      } catch (error) {
        self.state = false
        console.error('Fail', error)
      }
    }),
    fetchVideos: flow(function* fetchVideos() {
      self.videoState = true

      try {
        const res = yield fetch(config.videoApi)
        const json = yield res.json()

        self.auto_audio = map(
          json.data.auto_audio,
          item => item.Key.split('/')[2],
        )

        self.manual_audio = map(
          json.data.manual_audio,
          item => item.Key.split('/')[2],
        )
        self.female_video_blank = map(
          json.data.female_video_blank,
          item => item.Key.split('/')[2],
        )
        self.male_video_blank = map(
          json.data.male_video_blank,
          item => item.Key.split('/')[2],
        )

        self.videoState = false
      } catch (error) {
        self.videoState = false
        console.error('Fail', error)
      }
    }),
  }))
  .create({
    state: false,
    videoState: false,
    meetings: [],
    auto_audio: [],
    manual_audio: [],
    female_video_blank: [],
    male_video_blank: [],
  })

makeInspectable(VideoStore)

export default VideoStore
