export default {
  cells: [
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'auth',
      content: 'Auth Code',
      isSortable: true,
      width: 25,
    },
    {
      key: 'hardware',
      content: 'Hardware Token',
      isSortable: true,
      width: 25,
    },
    {
      key: 'location',
      content: 'Location',
      isSortable: true,
    },
    {
      key: 'organisation',
      content: 'Organisation',
      isSortable: true,
    },
    {
      key: 'ip',
      content: 'IP',
      isSortable: true,
    },
    {
      key: 'login',
      content: 'Last Login',
      isSortable: true,
    },
    {
      key: 'allowed',
      content: 'Authenticated',
      isSortable: true,
    },
    {
      key: 'more',
    },
  ],
}
