import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import OrganisationStore from '@components/Organisation/Organisation.store'

const { string, array, enumeration } = types

const Organisation = types.model('Organisation', {
  name: string,
  id: string,
})

const RptConfig = types.model('RptConfig', {
  id: string,
  name: string,
  config: string,
  organisationId: string,
  organisation: Organisation,
  isNew: false,
})

const EMPTY_CLONE = {
  id: '',
  name: '',
  config: '',
  organisationId: '',
  organisation: {
    name: '',
    id: '',
  },
  isNew: false,
}

const RptConfigStore = types
  .model('RptConfigStore', {
    data: array(RptConfig),
    state: enumeration('State', ['pending', 'done', 'error']),
    modalOpen: false,
    modalData: RptConfig,
  })
  .volatile(() => ({
    modalRef: {},
  }))
  .actions(self => ({
    updateFormRef(ref) {
      self.modalRef = ref
    },
    update(event) {
      self.modalData[event.target.name] = event.target.value
    },
    openModal(data = EMPTY_CLONE) {
      OrganisationStore.getOrganisations()
      const correctData = {
        ...data,
        organisation: { ...data.organisation },
        isNew: data.id ? false : true,
      }
      self.modalData = correctData
      self.modalOpen = true
    },
    closeModal() {
      self.modalData = EMPTY_CLONE
      self.modalOpen = false
    },
    getRptConfigs: flow(function* getRptConfigs() {
      self.state = 'pending'

      try {
        const res = yield rest({
          url: 'config-rpt',
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    deleteRptConfig: flow(function* deleteRptConfig(id) {
      self.state = 'pending'
      try {
        yield rest({
          url: `config-rpt/${id}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        self.state = 'done'
        self.getRptConfigs()
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),

    saveRptConfig: flow(function* saveRptConfig() {
      if (self.modalRef.validate().isInvalid) return

      if (self.modalData.isNew) {
        yield self.addNewOraganisation()
      } else {
        yield self.updateRptConfig()
      }

      self.getRptConfigs()
      self.closeModal()
    }),
    addNewOraganisation: flow(function* addNewOraganisation() {
      const { name, config, organisationId } = self.modalData
      const body = {
        name,
        config,
        organisationId,
      }
      const options = {
        method: 'POST',
        url: 'config-rpt',
        body,
        successMessage: `${name} Successfully Added`,
      }
      yield rest(options)
    }),
    updateRptConfig: flow(function* updateRptConfig() {
      const { name, config, organisationId, id } = self.modalData
      const body = {
        name,
        config,
        organisationId,
      }
      const options = {
        method: 'PATCH',
        url: `config-rpt/${id}`,
        body,
        successMessage: `${name} Successfully Updated`,
      }
      yield rest(options)
    }),
  }))
  .create({
    data: [],
    modalData: EMPTY_CLONE,
    state: 'done',
    modalOpen: false,
  })

makeInspectable(RptConfigStore)

export default RptConfigStore
