import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import flagStore from '@stores/flagStore'

const { enumeration, string, array } = types

const Tokens = types.model('Tokens', {
  id: string,
  name: string,
})

const Group = types
  .model('Group', {
    id: string,
    name: string,
    tokens: array(Tokens),
    isEditing: false,
  })
  .views(self => ({
    getLabels() {
      return self.tokens.map(({ id: value, name: label, token_group }) => ({
        label,
        value,
        token_group,
      }))
    },
  }))
  .actions(self => ({
    updateSchema(items) {
      self.tokens = items.map(({ label: name, value: id }) => ({
        id,
        name,
      }))
    },
    editing() {
      self.isEditing = !self.isEditing
    },
    updateName(event) {
      self.name = event.target.value
    },
    updateGroupName: flow(function* updateGroup() {
      self.state = 'pending'
      try {
        const { id, name } = self

        const data = {
          url: `group/${id}`,
          method: 'PATCH',
          successMessage: 'Successfully updated group name',
          body: {
            name,
          },
        }

        yield rest(data)
        Groups.getGroups()

        self.state = 'done'
      } catch (error) {
        console.error('Failed to update group', error)
        self.state = 'error'
      }
    }),
    updateGroupTokens: flow(function* updateGroup() {
      self.state = 'pending'
      const tokens = self.tokens

      const deleteOptions = {
        url: `token-group?groupId=${self.id}`,
        method: 'DELETE',
      }

      try {
        yield rest(deleteOptions)
      } catch (error) {
        console.error('Failed to update group', error)
        self.state = 'error'
      }

      try {
        for (const token of tokens) {
          const { id } = token

          const data = {
            url: 'token-group',
            method: 'POST',
            body: {
              tokenId: id,
              groupId: self.id,
            },
          }

          yield rest(data)
        }

        flagStore.addFlag({
          title: 'Your changes have been saved',
          type: 'success',
        })

        Groups.getGroups()

        self.state = 'done'
      } catch (error) {
        console.error('Failed to update group', error)
        self.state = 'error'
      }
    }),
  }))

const Groups = types
  .model('Groups', {
    state: enumeration('State', ['pending', 'done', 'error']),
    data: array(Group),
  })
  .views(self => ({
    getLabels() {
      return self.data.map(({ id: value, name: label }) => ({ label, value }))
    },
  }))
  .actions(self => ({
    getGroups: flow(function* getGroups() {
      self.state = 'pending'
      try {
        const res = yield rest({
          url: 'group',
        })
        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.data = res
        }
      } catch (error) {
        console.error('Failed to fetch group', error)
        self.state = 'error'
      }
    }),
    postNewGroup: flow(function* postNewGroup() {
      self.state = 'pending'
      try {
        const data = {
          url: 'group',
          method: 'POST',
          successMessage: 'Successfully added new group',
          body: {
            name: 'New Group',
          },
        }

        const res = yield rest(data)
        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.getGroups()
        }
      } catch (error) {
        console.error('Failed to add new group', error)
        self.state = 'error'
      }
    }),
    deleteGroup: flow(function* deleteGroup(id) {
      self.state = 'pending'
      try {
        const res = yield rest({
          url: `group/${id}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.getGroups()
        }
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.modalState = 'error'
      }
    }),
  }))
  .create({
    state: 'done',
    data: [],
  })

makeInspectable(Groups)

export default Groups
