import React from 'react'
import Button, { ButtonGroup } from '@atlaskit/button'
import ToolTip from '@atlaskit/tooltip'
import store from './Tokens.store'
import childStore from './TokensModal/Tokens.modal.store'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import FakeLink from '@components/UI/FakeLink/FakeLink'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'

const rows = (data, classes) =>
  data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.name,
        content: (
          <FakeLink
            text={item.name}
            onClick={() => {
              store.openModal()
              childStore.cloneChild(item)
            }}
          />
        ),
      },
      {
        key: item.softName,
        content: item.softName,
      },
      {
        key: item.groups,
        content: (
          <div className={classes.toolTipContainer}>
            {item.groups.map(item => (
              <span
                key={item.id}
                dangerouslySetInnerHTML={{
                  __html: `${item.name},&nbsp;`, // used as couldnt render &nbsp; easily
                }}
              />
            ))}
          </div>
        ),
      },
      {
        key: item.languages,
        content: (
          <div className={classes.toolTipContainer}>
            {item.allLangs().map(item => (
              <ToolTip key={item.id} content={item.name}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${item.id.toUpperCase()},&nbsp;`, // used as couldnt render &nbsp; easily
                  }}
                />
              </ToolTip>
            ))}
          </div>
        ),
      },
      {
        key: 'actions',
        content: (
          <div className="tableRight">
            <ButtonGroup>
              <Button
                appearance="default"
                onClick={() => {
                  store.openModal()
                  childStore.cloneChild(item)
                }}
              >
                Edit
              </Button>
              <DeleteButton
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Delete Token',
                    content: `Are you sure you want to delete ${item.name}?`,
                    appearance: 'warning',
                    action: () => store.deleteToken(item.id),
                  })
                }
              />
            </ButtonGroup>
          </div>
        ),
      },
    ],
  }))

export default rows
