import React from 'react'
import { node, oneOfType, arrayOf } from 'prop-types'

const FormFooter = ({ children }) => (
  <div
    style={{
      marginTop: '20px',
      display: 'flex',
      width: '100%',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    }}
  >
    {children}
  </div>
)

FormFooter.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
}

export default FormFooter
