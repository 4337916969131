import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Tabs from '@atlaskit/tabs'
import Modal from '@atlaskit/modal-dialog'

import Details from './Details/Details'
import Password from './Password/Password'

import DetailsStore from './Details/Details.store'
import PasswordStore from './Password/Password.store'

const UserModal = ({ closeModal }) => {
  const tabs = [
    { label: 'Details', content: <Details /> },
    { label: 'Change Password', content: <Password /> },
  ]

  let isProfile = true

  const actions = [
    {
      text: 'Save',
      onClick: event =>
        isProfile
          ? DetailsStore.postUser(event)
          : PasswordStore.submitForm(event),
    },
    { text: 'Close', onClick: () => closeModal() },
  ]

  return (
    <Modal actions={actions} heading="My Profile" onClose={closeModal}>
      <Tabs
        tabs={tabs}
        onSelect={(tab, index) => {
          isProfile = index === 0
        }}
      />
    </Modal>
  )
}

UserModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default observer(UserModal)
