import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { FieldTextAreaStateless } from '@atlaskit/field-text-area'
import Modal from '@atlaskit/modal-dialog'
import Spacer from '@components/Spacer/Spacer'
import flagStore from '@stores/flagStore'
import rest from '@utils/rest'

const configAsPrettyJSON = (config) => {
  const copy = { ...config }
  return JSON.stringify(copy, undefined, 4)
}

const addToClipboard = (value, onClose) => {
  navigator.clipboard
    .writeText(value)
    .then(() => {
      flagStore.addFlag({
        title: 'Added to clipboard',
        type: 'success',
      })
      onClose()
    })
    .catch((e) => {
      console.log(e)
      flagStore.addFlag({
        title: 'Failed to add to clipboard',
        type: 'error',
      })
    })
}

const ConfigViewer = ({ screenId, onClose }) => {
  const [config, setConfig] = useState({})

  const fetchScreenConfig = async (screenId) => {
    const result = await rest({ url: `screen/config/${screenId}` })
    setConfig(result)
  }

  useEffect(() => {
    fetchScreenConfig(screenId)
  }, [screenId])

  const setEnvironment = () => {
    let environment = null
    switch (process.env.NODE_ENV) {
      case 'local':
        environment = 'http://localhost:1234'
        break
      case 'development':
        environment = 'https://bsd.dev-racingpost.com'
        break
      case 'staging':
        environment = 'https://bsd.staging-racingpost.com'
        break
      case 'production':
        environment = 'https://bsd.racingpost.com'
        break
      default:
        environment = 'https://bsd.staging-racingpost.com'
    }
    return environment
  }

  const actions = [
    {
      text: 'Copy as V3.0 BSD url',
      onClick: () => {
        const environment = setEnvironment()
        addToClipboard(`${environment}/v3.0?screenId=${screenId}`, onClose)
      },
    },
    {
      text: 'Copy as next BSD url',
      onClick: () => {
        const environment = setEnvironment()
        addToClipboard(`${environment}/next?screenId=${screenId}`, onClose)
      },
    },
    {
      text: 'Copy screen ID',
      onClick: () => {
        addToClipboard(screenId, onClose)
      },
    },
    { text: 'Close', onClick: onClose },
  ]

  return (
    <Modal
      heading={`Config for screenId: ${screenId}`}
      onClose={onClose}
      actions={actions}
    >
      <FieldTextAreaStateless
        name="config"
        isReadOnly
        type="text"
        shouldFitContainer
        value={configAsPrettyJSON(config)}
        minimumRows={Object.keys(config).length + 4}
        isSpellCheckEnabled={false}
      />

      <Spacer />
    </Modal>
  )
}

ConfigViewer.propTypes = {
  screenId: PropTypes.string,
  onClose: PropTypes.func,
}

export default observer(ConfigViewer)
