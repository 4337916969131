import { types } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import jwtDecode from 'jwt-decode'
import { get } from 'lodash'
import ls from '@utils/localStorage'
import { history } from '@stores/routerStore'

const { string, boolean } = types

const UserStore = types
  .model('UserStore', {
    token: string,
    userId: string,
    organisationId: string,
    isSuperOrg: boolean,
  })
  .views(self => ({
    getRole() {
      try {
        const { role } = jwtDecode(self.token)
        return role
      } catch (error) {
        throw error
      }
    },
    isAdmin() {
      return self.token && self.getRole() === 'admin'
    },
  }))
  .actions(self => ({
    updateToken({ accessToken }, rememberMe) {
      const decodedJwt = jwtDecode(accessToken)
      self.token = accessToken
      self.userId = get(decodedJwt, 'user-accountId')
      self.organisationId = get(decodedJwt, 'organisationId')
      self.isSuperOrg = ['1', '2'].includes(
        get(decodedJwt, 'organisationId', null),
      )
      if (rememberMe) {
        ls.set('jwt', accessToken)
      }
    },
    logout() {
      self.token = ''
      self.userId = ''
      ls.remove('jwt')
    },
    jwtLogin() {
      const localToken = ls.get('jwt')
      if (localToken) {
        const decodedJwt = jwtDecode(localToken)
        self.token = localToken
        self.userId = get(decodedJwt, 'user-accountId')
        self.organisationId = get(decodedJwt, 'organisationId') || ''
        self.isSuperOrg = ['1', '2'].includes(
          get(decodedJwt, 'organisationId', null),
        )
      } else {
        if (history.location.pathname !== '/login') {
          history.push(`/login${history.location.search}`)
        }
      }
    },
  }))
  .create({
    token: '',
    userId: '',
    organisationId: '',
    isSuperOrg: false,
  })

makeInspectable(UserStore)

export default UserStore
