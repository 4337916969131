import { types, destroy } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import uuid from 'uuid/v4'

const { string, maybe, enumeration, array, number } = types

const Flag = types
  .model('Flag', {
    id: string,
    title: maybe(string),
    text: maybe(string),
    type: enumeration('State', [
      'error',
      'info',
      'normal',
      'success',
      'warning'
    ]),
    flagTimeOut: number
  })
  .actions(self => ({
    afterCreate() {
      setTimeout(() => {
        flagStore.removeFlag(self)
      }, self.flagTimeOut)
    }
  }))

const flagStore = types
  .model('flagStore', {
    flags: array(Flag)
  })
  .actions(self => ({
    addFlag(flag) {
      self.flags.unshift({ id: uuid(), ...flag, flagTimeOut: 3000 })
    },
    removeFlag(item) {
      destroy(item)
    },
    clearFlags() {
      self.flags = []
    }
  }))
  .create({
    flags: []
  })

makeInspectable(flagStore)

export default flagStore
