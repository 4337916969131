import React from 'react'
import { string } from 'prop-types'

const Translations = ({ example }) => (
  <div>
    <h1>{example}</h1>
  </div>
)

Translations.propTypes = {
  example: string,
}

export default Translations
