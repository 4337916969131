const colors = {
  main: '',
  secondary: '',
  mainBg: '#F4F4F4',
  secondaryBg: '#FBFCFB',
  activeNav: '#000',
  activeNavLine: '#D8232A',
  inactiveNav: '#9AA9B5',
  borderColor: '#DADADA',
  globalLinkColor: '#0052CC',
  navLinkColor: 'rgb(66, 82, 110)',
  lightHover: 'rgba(9,30,66,0.04)',
  title: '#122749',
}

export default colors
