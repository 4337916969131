import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import DynamicTable from '@atlaskit/dynamic-table'
import Button from '@atlaskit/button'
import PageHeader from '@components/PageHeader/PageHeader'
import store from './RptConfig.store'
import Modal from './RptConfig.modal'
import rows from './rows'
import head from './tableHead'
import organisationStore from '@components/Organisation/Organisation.store'
import Spacer from '@components/Spacer/Spacer'

class RptConfig extends Component {
  componentDidMount() {
    store.getRptConfigs()
    organisationStore.getOrganisations()
  }

  render() {
    const { data, state, modalOpen, openModal } = store

    return (
      <Fragment>
        <PageHeader text="RPT">
          <Button onClick={() => openModal()} appearance="primary">
            Create New
          </Button>
        </PageHeader>

        <DynamicTable
          head={head}
          rows={rows(data)}
          rowsPerPage={data.length < 30 ? undefined : 30}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={state === 'pending'}
          isFixedSize
        />

        {modalOpen && <Modal />}
        <Spacer />
      </Fragment>
    )
  }
}

export default observer(RptConfig)
