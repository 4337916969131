import React from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'
import Select from '@atlaskit/select'
import { MODAL_ZINDEX } from '@utils/globalVar'
import organisationStore from '@components/Organisation/Organisation.store'
import UserStore from '../../stores/userStore.js'
import store from '@components/Region/Region.store'

const RegionModal = () => {
  const { closeModal, updateFormRef, modalData, update, saveRegion } = store

  const actions = [
    {
      text: 'Save',
      onClick: () => saveRegion(),
      appearance: 'primary',
    },
    { text: 'Close', onClick: () => closeModal() },
  ]

  return (
    <Modal
      actions={actions}
      heading={modalData.isNew ? 'Add new Region' : 'Edit Region'}
      onClose={closeModal}
    >
      <Form
        shouldFitContainer
        ref={(ref) => {
          updateFormRef(ref)
        }}
      >
        <Field label="Name" required isRequired>
          <FieldTextStateless
            name="name"
            type="text"
            shouldFitContainer
            value={modalData.name}
            onChange={update}
          />
        </Field>

        <Field label="Organisation" isRequired required>
          <Select
            name={'organisationId'}
            isDisabled={!UserStore.isAdmin()}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: MODAL_ZINDEX }),
            }}
            onChange={(item) =>
              update({
                target: {
                  name: 'organisationId',
                  value: item.value,
                },
              })
            }
            options={organisationStore.getLabels()}
            placeholder="Choose a Organisation"
            value={organisationStore.getSingleLabel(modalData.organisationId)}
          />
        </Field>
      </Form>
    </Modal>
  )
}

export default observer(RegionModal)
