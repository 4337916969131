import React, { Component } from 'react'
import { observer } from 'mobx-react'
import injectSheet from 'react-jss'
import { object } from 'prop-types'

const presenter = (Presenter, View, styles) => {
  const ViewComp = observer(View)
  class WrappedView extends Component {
    static View = View
    constructor(props) {
      super(props)
      const prps = Object.assign({}, View.defaultProps, props)
      this.pm = props.pm || new Presenter(prps, this)
    }

    componentDidMount() {
      this.pm.componentDidMount && this.pm.componentDidMount()
    }

    componentWillUnmount() {
      this.pm.componentWillUnmount && this.pm.componentWillUnmount()
    }

    render() {
      return <ViewComp {...this.props} />
    }
  }

  WrappedView.propTypes = {
    pm: object
  }

  return styles ? injectSheet(styles)(WrappedView) : WrappedView
}

export default presenter
