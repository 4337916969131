import React from 'react'
import injectSheet from 'react-jss'
import { object, string, bool, func, oneOfType } from 'prop-types'
import ReactSVG from 'react-svg'
import cx from 'classnames'
import { history } from '@stores/routerStore'
import Tooltip from '@atlaskit/tooltip'
import colors from '@styles/colors'

const isActive = (pathNameToMatch) =>
  history.location.pathname === pathNameToMatch

const style = (theme) => ({
  navContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    '&:hover $activeLine:not($activeLineActive)': {
      backgroundColor: theme.colors.inactiveNav,
    },
  },
  iconContainer: {
    padding: '12.5px 0',
    transition: theme.transition,
    width: '65px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'default',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeLine: {
    position: 'absolute',
    left: 0,
    height: '70%',
    width: '3px',
  },
  iconCustomSvg: {
    stroke: theme.colors.inactiveNav,
  },
  activeLineActive: {
    backgroundColor: theme.colors.activeNavLine,
  },
  rotate: {
    stroke: theme.colors.activeNav,
    transform: 'rotate(180deg)',
  },
})

export const NavIcon = ({
  classes,
  imagePath,
  activeRotate,
  clickAction,
  pathNameToMatch,
  tooltipContent,
}) => {
  const {
    iconContainer,
    iconSvg,
    rotate,
    navContainer,
    activeLine,
    activeLineActive,
    iconCustomSvg,
  } = classes

  const content =
    typeof imagePath === 'string' ? (
      <ReactSVG path={imagePath} className={iconCustomSvg} />
    ) : (
      <div className={iconSvg}>
        {imagePath({ primaryColor: colors.inactiveNav, size: 'large' })}
      </div>
    )

  return (
    <div className={navContainer}>
      <div
        className={cx(activeLine, {
          [activeLineActive]: isActive(pathNameToMatch),
        })}
      />
      <div
        className={cx(iconContainer, {
          [rotate]: activeRotate,
        })}
        onClick={() => clickAction()}
      >
        {tooltipContent ? (
          <Tooltip content={tooltipContent} position="right">
            {content}
          </Tooltip>
        ) : (
          content
        )}
      </div>
    </div>
  )
}

NavIcon.propTypes = {
  classes: object,
  imagePath: oneOfType([string, func]),
  activeState: bool,
  activeRotate: bool,
  clickAction: func,
  pathNameToMatch: string,
  tooltipContent: string,
}

NavIcon.defaultProps = {
  clickAction: () => {},
}

export default injectSheet(style)(NavIcon)
