import React, { useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'
import Select from '@atlaskit/select'
import Banner from '@atlaskit/banner'
import Button from '@atlaskit/button'
import WarningIcon from '@atlaskit/icon/glyph/warning'
import Tooltip from '@atlaskit/tooltip'
import { history } from '@stores/routerStore'
import { DatePicker } from '@atlaskit/datetime-picker'
import { useQuery } from '@apollo/client'
import { format } from 'date-fns'
import addDays from 'date-fns/addDays'
import { utcToZonedTime } from 'date-fns-tz'

import Store from '../Config/Config.store'
import FeatureRaceStore from './featureRace.store'
import { contentIds } from '../../utils/globalVar'
import { allHorseMeetingsQuery } from '../Adverts/EditScope/AdvertQueries'
import {
  horseRacesByMeetingId,
  internationalHorseRacesByMeetingId,
} from './featureRaceQueries'
import { base64, getMeetingOptions } from './utils'

import {
  ButtonGroup,
  FileContainer,
  Column,
  RemainingChars,
} from './Schedule.styles'

const AddSchedule = () => {
  const {
    update,
    modalData,
    saveFeatureRace,
    openPreview,
    closeModal,
  } = FeatureRaceStore

  const today = format(new Date(), 'yyyy-MM-dd')
  const plusFiveDays = format(addDays(new Date(today), 5), 'yyyy-MM-dd')
  const nextDay = modalData.featureRaceDate
    ? format(addDays(new Date(modalData.featureRaceDate), 1), 'yyyy-MM-dd')
    : ''

  const [fileTooLarge, setFileTooLarge] = useState(false)
  const [saveAttempted, setSaveAttempted] = useState(false)
  const featureRaceNameRemainingChars = 50 - modalData.featureRaceName.length

  const fileSizeWarning = () => {
    return (
      <Banner
        appearance="warning"
        icon={<WarningIcon label="" secondaryColor="inherit" />}
        isOpen
      >
        File must be less than 500KB
      </Banner>
    )
  }

  const warningPopup = (message) => {
    return (
      <Banner
        appearance="warning"
        icon={<WarningIcon label="" secondaryColor="inherit" />}
        isOpen
      >
        {message}
      </Banner>
    )
  }

  const areMandatoryFieldsSelected = () => {
    return (
      !!modalData.featureRaceDate &&
      !!modalData.displayFromDate &&
      !!modalData.courseName &&
      !!modalData.raceId &&
      !!modalData.bookmakers
    )
  }

  const { data: meetingData } = useQuery(allHorseMeetingsQuery, {
    variables: {
      countryCodes: ['GB', 'IRE', 'USA', 'CAN', 'SAF', 'FR', 'UAE'],
      startDateUTC: modalData.featureRaceDate,
      endDateUTC: nextDay,
    },
    skip: !modalData.featureRaceDate,
  })

  const { data: raceData } = useQuery(horseRacesByMeetingId, {
    variables: {
      ids: [modalData.meetingId],
    },
    skip:
      !modalData.meetingId || !['GB', 'IRE'].includes(modalData.meetingCountry),
  })

  const { data: internationalRaceData } = useQuery(
    internationalHorseRacesByMeetingId,
    {
      variables: {
        ids: [modalData.meetingId],
      },
      skip:
        !modalData.meetingId ||
        ['GB', 'IRE'].includes(modalData.meetingCountry),
    },
  )

  const UKRaces = raceData
    ? raceData.horseMeetingsById[0].raceConnection.edges.map((race) => {
        const date = format(
          utcToZonedTime(race.node.datetime, 'Europe/London'),
          'yyyy-MM-dd',
        )
        const time = format(
          utcToZonedTime(race.node.datetime, 'Europe/London'),
          'HH:mm',
        )
        return { label: time, value: race.node.id, raceDate: date }
      })
    : []

  const internationalRaces = internationalRaceData
    ? internationalRaceData.internationalMeetingsById[0].raceConnection.edges.map(
        (race) => {
          const date = format(
            utcToZonedTime(race.node.datetime, 'Europe/London'),
            'yyyy-MM-dd',
          )
          const time = format(
            utcToZonedTime(race.node.datetime, 'Europe/London'),
            'HH:mm',
          )
          return { label: time, value: race.node.id, raceDate: date }
        },
      )
    : []

  const bookmakers = contentIds.map((bookmaker) => {
    return { label: bookmaker, value: bookmaker }
  })
  const isHeightIncorrect =
    (modalData.leftContentHeight || modalData.rightContentHeight) > 1775

  const isWidthIncorrect =
    (modalData.leftContentWidth || modalData.rightContentWidth) > 1050

  const actions = [
    {
      text: 'Save',
      appearance: 'primary',
      onClick: () => {
        setSaveAttempted(true)

        if (
          !fileTooLarge &&
          !isHeightIncorrect &&
          !isWidthIncorrect &&
          areMandatoryFieldsSelected()
        ) {
          saveFeatureRace()
        }
      },
    },
    {
      text: 'Close',
      onClick: () => {
        closeModal()
        history.push(`/feature-races`)
      },
    },
  ]
  const contentDirection =
    !modalData.leftContentImage || !modalData.rightContentImage
      ? 'row'
      : 'column'
  return (
    <Modal
      actions={actions}
      heading="Schedule"
      onClose={Store.closeAddScreenModal}
      width={'50%'}
      height={'90%'}
    >
      <Form shouldFitContainer>
        <RemainingChars>
          {`${featureRaceNameRemainingChars} characters remaining`}
        </RemainingChars>
        <Field label="Feature Race Name">
          <FieldTextStateless
            name="name"
            type="text"
            maxLength={50}
            shouldFitContainer
            value={modalData.featureRaceName}
            onChange={(e) => {
              const value = e.target.value && e.target.value
              update({ target: { value: value, name: 'featureRaceName' } })
            }}
            defaultValue={modalData.featureRaceName}
          />
        </Field>

        <Field label="Choose date" isRequired required>
          <DatePicker
            onChange={(e) => {
              update({ target: { value: e, name: 'featureRaceDate' } })
              update({ target: { value: '', name: 'raceId' } })
              update({ target: { value: '', name: 'raceTime' } })
              update({ target: { value: '', name: 'courseName' } })
            }}
            value={modalData.featureRaceDate || today}
            minDate={today}
            maxDate={plusFiveDays}
            dateFormat="DD-MM-YYYY"
          />
        </Field>

        <Field label="Choose Meeting" isRequired required>
          <Select
            name={'meetings'}
            options={
              meetingData ? getMeetingOptions(meetingData.AllMeeting) : []
            }
            value={modalData.courseName}
            onChange={(e) => {
              update({ target: { value: e.courseName, name: 'courseName' } })
              update({ target: { value: e.country, name: 'meetingCountry' } })
              update({ target: { value: e.value, name: 'meetingId' } })
            }}
            placeholder={modalData.courseName}
          />
        </Field>

        <Field label="Choose feature race" isRequired required>
          <Select
            name={'featureRace'}
            options={UKRaces.length > 0 ? UKRaces : internationalRaces}
            value={modalData.raceTime}
            onChange={(e) => {
              update({ target: { value: e.value, name: 'raceId' } })
              update({ target: { value: e.label, name: 'raceTime' } })
              update({ target: { value: e.raceDate, name: 'featureRaceDate' } })
            }}
            placeholder={modalData.raceTime}
          />
        </Field>
        <Field
          label="Choose the bookmakers to show feature race by content id"
          isRequired
        >
          <Select
            name={'contentId'}
            isMulti
            options={bookmakers}
            value={modalData.bookmakers
              .split(',')
              .filter((bookmaker) => !!bookmaker)
              .map((bookmaker) => {
                return { label: bookmaker, value: bookmaker }
              })}
            onChange={(e) => {
              const bookmakers = e
                .map((bookmakerObject) => bookmakerObject.value)
                .join()
              update({ target: { value: bookmakers, name: 'bookmakers' } })
            }}
          />
        </Field>
        <Field label="Display from" isRequired>
          <DatePicker
            onChange={(e) => {
              update({ target: { value: e, name: 'displayFromDate' } })
            }}
            minDate={today}
            value={modalData.displayFromDate || today}
            maxDate={modalData.featureRaceDate}
            dateFormat="DD-MM-YYYY"
          />
        </Field>
        <FileContainer contentDirection={contentDirection}>
          {!modalData.leftContentImage ? (
            <Tooltip content={'Max height: 1775px. Max width: 1050px. '}>
              <Field label="Left content image">
                <FieldTextStateless
                  type="file"
                  name="leftContentImage"
                  id="leftContentImage"
                  onChange={(e) => {
                    base64(
                      e,
                      setFileTooLarge,
                      'leftContentHeight',
                      'leftContentWidth',
                      'leftContentImage',
                    )
                  }}
                  defaultValue={modalData.leftContentImage}
                />
              </Field>
            </Tooltip>
          ) : (
            <Field label="Left content image">
              <ButtonGroup>
                <Button
                  onClick={() =>
                    openPreview({
                      isLeftContent: true,
                      raceId: modalData.raceId,
                    })
                  }
                >
                  View
                </Button>
                <Button
                  onClick={() =>
                    update({ target: { value: '', name: 'leftContentImage' } })
                  }
                >
                  Clear
                </Button>
              </ButtonGroup>
            </Field>
          )}
          <Column>
            {!modalData.rightContentImage ? (
              <Tooltip content={'Max height: 1775px. Max width: 1050px. '}>
                <Field label="Right content image">
                  <FieldTextStateless
                    type="file"
                    name="rightContentImage"
                    id="rightContentImage"
                    onChange={(e) => {
                      base64(
                        e,
                        setFileTooLarge,
                        'rightContentHeight',
                        'rightContentWidth',
                        'rightContentImage',
                      )
                    }}
                    defaultValue={modalData.rightContentImage}
                  />
                </Field>
              </Tooltip>
            ) : (
              <Field label="Right content image">
                <ButtonGroup>
                  <Button
                    onClick={() =>
                      openPreview({
                        isLeftContent: false,
                        raceId: modalData.raceId,
                      })
                    }
                  >
                    View
                  </Button>
                  <Button
                    onClick={() =>
                      update({
                        target: { value: '', name: 'rightContentImage' },
                      })
                    }
                  >
                    Clear
                  </Button>
                </ButtonGroup>
              </Field>
            )}
            <div>{fileTooLarge && fileSizeWarning()}</div>
            <div>
              {isHeightIncorrect &&
                warningPopup('Content height must be less than 1775px')}
              {isWidthIncorrect &&
                warningPopup('Content Width must be less than 1050px')}
              {!areMandatoryFieldsSelected() &&
                saveAttempted &&
                warningPopup('Please fill in all mandatory fields')}
            </div>
          </Column>
        </FileContainer>
      </Form>
    </Modal>
  )
}

export default observer(AddSchedule)
