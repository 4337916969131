export default {
  cells: [
    {
      key: 'language',
      content: 'Language',
      isSortable: true,
    },
    {
      key: 'text',
      content: 'Translation',
      isSortable: true,
    },
    {
      key: 'delete',
      content: '',
      isSortable: false,
    },
  ],
}
