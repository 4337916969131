import React from 'react'
import Tooltip from '@atlaskit/tooltip'
import ImageIcon from '@atlaskit/icon/glyph/image'
import Button, { ButtonGroup } from '@atlaskit/button'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'
import dayjs from 'dayjs'

import DeleteButton from '@components/DeleteButton/DeleteButton'
import store from './Adverts.store'
import EditScopeStore from './EditScope/EditScope.store'

const rows = (data) =>
  data.map((item, index) => {
    let status = 'Active'
    if (dayjs().isBefore(dayjs(item.startDate))) {
      status = 'Scheduled'
    }
    if (dayjs().isAfter(dayjs(item.expirationDate))) {
      status = 'Expired'
    }
    return {
      key: index,
      cells: [
        {
          key: item.advertName,
          content: item.advertName,
        },
        { key: item.advertType, content: item.advertType },
        { key: item.advertPlacement, content: item.advertPlacement },
        { key: item.contentId, content: item.contentId },
        { key: status, content: status },

        {
          key: item.startDate,
          content: dayjs(item.startDate).format('DD-MM-YYYY'),
        },
        {
          key: item.expirationDate,
          content: item.expirationDate
            ? dayjs(item.expirationDate).format('DD-MM-YYYY')
            : '',
        },
        {
          content: (
            <Tooltip content={'Preview'} position={'bottom'}>
              <Button
                appearance="primary"
                onClick={() => store.openPreview(item)}
              >
                <ImageIcon />
              </Button>
            </Tooltip>
          ),
        },
        {
          content: (
            <ButtonGroup>
              <DeleteButton
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Delete Image',
                    content: `Are you sure you want to delete ${item.advertName}?`,
                    appearance: 'warning',
                    actionText: 'Delete',
                    action: () =>
                      store.deleteAdvert(
                        item.advertName,
                        item.contentId,
                        item.advertType,
                        item.advertPlacement,
                      ),
                  })
                }
              />
            </ButtonGroup>
          ),
        },
        {
          content: (
            <div>
              <div style={{ marginBottom: '8px' }}>
                <Button spacing="none" onClick={() => store.openModal(item)}>
                  Edit Properties
                </Button>
              </div>
              <Button
                spacing="none"
                onClick={() => EditScopeStore.openScope(item)}
              >
                Edit Scope
              </Button>
            </div>
          ),
        },
      ],
    }
  })

export default rows
