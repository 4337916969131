import React from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import Select from '@atlaskit/select'
import { FieldTextStateless } from '@atlaskit/field-text'
import { MODAL_ZINDEX } from '@utils/globalVar'
import store from './NewDialogues.Modal.store'
import parentStore from '../Dialogues.store'
import langStore from '@components/Languages/Languages.store'

const NewDialoguesModal = () => {
  const actions = [
    {
      text: 'Save',
      onClick: () => store.addNew(),
    },
    { text: 'Close', onClick: () => parentStore.closeModal() },
  ]

  return (
    <Modal
      actions={actions}
      heading={'Add new Dialogue'}
      onClose={parentStore.closeModal}
    >
      <Form shouldFitContainer>
        <Field label="Name" required isRequired>
          <FieldTextStateless
            name={'name'}
            type="text"
            shouldFitContainer
            value={store.name}
            onChange={store.update}
          />
        </Field>

        <Field label="Sport" required isRequired>
          <Select
            options={parentStore.renderLabels('sports')}
            placeholder="Choose a Sport"
            value={parentStore.selectedSport}
            onChange={val => parentStore.updateSelect(val, 'selectedSport')}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: MODAL_ZINDEX }) }}
          />
        </Field>

        <Field label="Language" required isRequired>
          <Select
            options={langStore.getDataForSelect()}
            placeholder="Choose a Language"
            value={parentStore.selectedLanguage}
            onChange={val => parentStore.updateSelect(val, 'selectedLanguage')}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: MODAL_ZINDEX }) }}
          />
        </Field>
      </Form>
    </Modal>
  )
}

export default observer(NewDialoguesModal)
