import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'
import EditScopeStore from './EditScope.store'
import { useQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { scopeIncludeEnum } from '../AdvertUtils'
import { allHorseMeetingsQuery } from './AdvertQueries'
import {
  StyledDivHover,
  ZoomedSelectedOptions,
  ZoomedOptions,
  SaveOrCancelContainer,
  OptionsContainer,
  InstructionText,
} from './EditScope.styles'

const today = moment().format('YYYY-MM-DD')
const nextWeek = moment().add(7, 'days').format('YYYY-MM-DD')

const SelectMeetings = (props) => {
  const [isOpen, setIsOpen] = useState(true)
  const closeModal = useCallback(() => setIsOpen(false), [])
  const { updateScope } = EditScopeStore

  const {
    data: MeetingData,
    error: MeetingError,
    loading: MeetingLoading,
  } = useQuery(allHorseMeetingsQuery, {
    variables: {
      countryCodes: ['GB', 'IRE', 'USA', 'FR', 'SAF', 'UAE'],
      startDateUTC: today,
      endDateUTC: nextWeek,
    },
  })

  if (MeetingLoading) {
    return <p>LOADING</p>
  }
  if (MeetingError) {
    return <p>Error loading meetings</p>
  }
  const allMeetingsForScope =
    MeetingData &&
    MeetingData.AllMeeting.map((meeting) => {
      return {
        id: meeting.id,
        dateTime: meeting.dateTime,
        courseName: meeting.courseName,
        uniqueName: `${moment(meeting.dateTime).format('Do MMM')}: ${
          meeting.courseName
        }`,
        include: scopeIncludeEnum.include,
      }
    })

  const sortedMeetingsByTime = allMeetingsForScope.sort(
    (a, b) => new Date(a.dateTime) - new Date(b.dateTime),
  )

  const onMeetingClick = (meeting) => {
    const meetingNames = props.liveSelectedMeetingArray
      ? props.liveSelectedMeetingArray.map((meeting) => meeting.uniqueName)
      : []
    if (!meetingNames.includes(meeting.uniqueName)) {
      props.setLiveSelectedMeetingArray([
        ...props.liveSelectedMeetingArray,
        {
          id: meeting.id,
          uniqueName: meeting.uniqueName,
          include: meeting.include,
        },
      ])
    }
  }

  const removeMeetingClick = (meetingToRemove) => {
    const removeMeeting = props.liveSelectedMeetingArray.filter(
      (meeting) => meeting.uniqueName !== meetingToRemove.uniqueName,
    )
    props.setLiveSelectedMeetingArray(removeMeeting)
  }
  return (
    <div>
      {isOpen && (
        <Modal
          onClose={(e) => {
            closeModal(e)
            props.handleClose(e)
          }}
        >
          <OptionsContainer>
            <ZoomedOptions>
              <InstructionText>Click on a meeting to add</InstructionText>
              {sortedMeetingsByTime &&
                sortedMeetingsByTime.map((meeting) => {
                  return (
                    <StyledDivHover
                      key={`${meeting.courseName}${meeting.dateTime}`}
                      onClick={() => onMeetingClick(meeting)}
                    >
                      {`${moment(meeting.dateTime).format('Do MMM')}: ${
                        meeting.courseName
                      }`}
                    </StyledDivHover>
                  )
                })}
            </ZoomedOptions>
            <ZoomedSelectedOptions>
              <InstructionText>
                Display advert at these meetings (Click to remove)
              </InstructionText>
              {props.liveSelectedMeetingArray &&
                props.liveSelectedMeetingArray.map((meeting) => {
                  return (
                    <StyledDivHover
                      key={`${meeting.id}${meeting.uniqueName}`}
                      onClick={() => removeMeetingClick(meeting)}
                    >
                      {` ${meeting.uniqueName}`}
                    </StyledDivHover>
                  )
                })}
            </ZoomedSelectedOptions>
          </OptionsContainer>
          <SaveOrCancelContainer>
            <Button
              onClick={() => {
                updateScope({
                  target: {
                    value: props.liveSelectedMeetingArray,
                    name: 'selectedMeetings',
                  },
                })
                updateScope({
                  target: { value: [], name: 'jurisdictionExclusions' },
                })
                updateScope({
                  target: { value: [], name: 'jurisdictions' },
                })
                closeModal()
                props.handleClose()
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                closeModal()
                props.handleClose()
              }}
            >
              Cancel
            </Button>
          </SaveOrCancelContainer>
        </Modal>
      )}
    </div>
  )
}

export default observer(SelectMeetings)
