import { groupBy, sortBy } from 'lodash'

import { getBase64 } from '../Adverts/AdvertUtils'
import FeatureRaceStore, { bucket } from './featureRace.store'

export const base64 = (
  e,
  setFileTooLarge,
  heightName,
  widthName,
  imageName,
) => {
  const { update } = FeatureRaceStore
  return getBase64(e.target.files[0]).then((result) => {
    const i = new Image()
    i.onload = function () {
      update({
        target: { value: Number(i.height), name: heightName },
      })
      update({
        target: { value: Number(i.width), name: widthName },
      })
    }
    i.src = result
    const stringLength = result.length - 'data:image/png;base64,'.length
    const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812
    const sizeInKb = sizeInBytes / 1000
    if (sizeInKb > 500) {
      setFileTooLarge(true)
    } else {
      setFileTooLarge(false)
      update({
        target: {
          value: result,
          name: imageName,
        },
      })
    }
  })
}

export const getMeetingOptions = (allMeetings) => {
  const groupedMeetingsByCountry = groupBy(
    allMeetings,
    (meeting) => meeting.countryCode,
  )

  const countryCodes = Object.keys(groupedMeetingsByCountry)
  const meetingOptions = countryCodes.map((countryCode) => {
    const countryOptions = sortBy(
      groupedMeetingsByCountry[countryCode].map((meeting) => {
        return {
          label: meeting.courseName,
          value: meeting.id,
          country: meeting.countryCode,
          courseName: meeting.courseName,
        }
      }),
      (meeting) => meeting.courseName,
      ['asc'],
    )
    return {
      label: countryCode,
      options: countryOptions,
    }
  })

  const gbMeetingOption = meetingOptions.find(
    (meeting) => meeting.label === 'GB',
  )
  const ireMeetingOption = meetingOptions.find(
    (meeting) => meeting.label === 'IRE',
  )
  const internationalMeetingOptions = sortBy(
    meetingOptions.filter(
      (meeting) => meeting.label !== 'GB' && meeting.label !== 'IRE',
    ),
    (meeting) => meeting.label,
    ['asc'],
  )

  const orderedMeetingOptions = [
    gbMeetingOption && gbMeetingOption,
    ireMeetingOption && ireMeetingOption,
    ...internationalMeetingOptions,
  ].filter((option) => option)

  return orderedMeetingOptions
}

export const getBucketContentUrl = ({ isLeftContent, raceId }) => {
  if (isLeftContent) {
    return `${bucket}/FEATURE_RACE/${raceId}/LEFT_CONTENT`
  } else {
    return `${bucket}/FEATURE_RACE/${raceId}/RIGHT_CONTENT`
  }
}
