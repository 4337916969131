import React from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import store from './Warning.modal.store'

const WarningModal = () => {
  const actions = (actionText) => [
    {
      text: actionText || 'Delete',
      onClick: () => {
        store.ok()
      },
    },
    {
      text: 'Cancel',
      onClick: () => store.toggle(),
    },
  ]

  if (store.isOpen) {
    return (
      <Modal
        actions={actions(store.actionText)}
        heading={store.heading}
        onClose={store.toggle}
        appearance={store.appearance}
      >
        <p>{store.content}</p>
      </Modal>
    )
  }

  return null
}

export default observer(WarningModal)
