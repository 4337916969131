import React from 'react'
import { string } from 'prop-types'
import { Validator } from '@atlaskit/form'
import {
  isLongEnough,
  containsOneNumber,
  isSameAsFirst,
  isJson,
} from './validatorLogic'

const IsLongEnough = (
  <Validator
    func={isLongEnough}
    invalid="Min 6 chars length,"
    options={{ minLength: 6, maxLength: 50 }}
  />
)

const ContainsOneNumber = (
  <Validator func={containsOneNumber} invalid="At least 1 number," />
)

const NotSameAsFirst = password => (
  <Validator
    func={val => isSameAsFirst(val, password)}
    invalid="Passwords do not match,"
  />
)

const isValidJson = <Validator func={isJson} invalid="This is invalid JSON," />

NotSameAsFirst.propTypes = {
  password: string,
}

export { IsLongEnough, ContainsOneNumber, NotSameAsFirst, isValidJson }
