import React from 'react'
import { number, string } from 'prop-types'
import Button, { ButtonGroup } from '@atlaskit/button'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import FakeLink from '@components/UI/FakeLink/FakeLink'
import store from './Location.store'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'
import LocationStore from './Location.store'
import ConfigButton from '@components/UI/ConfigButton'

const PiCount = ({ amount, locationId }) => (
  <div
    onClick={() => LocationStore.goToFilteredDeviceList(locationId)}
    style={{
      height: '25px',
      width: '25px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'red',
      color: 'white',
      cursor: 'pointer',
    }}
  >
    {amount}
  </div>
)

PiCount.propTypes = {
  amount: number,
  locationId: string,
}

const rows = data =>
  data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.name,
        content: (
          <FakeLink text={item.name} onClick={() => store.openModal(item)} />
        ),
      },
      {
        key: item.shopId,
        content: item.shopId,
      },
      {
        key: item.city,
        content: item.city,
      },
      {
        key: item.postCode,
        content: item.postCode,
      },
      {
        key: item.region.organisation.name,
        content: item.region.organisation.name,
      },
      {
        key: item.region.name,
        content: item.region.name,
      },
      {
        key: 'config',
        content: (
          <PiCount
            amount={item.configPis ? item.configPis.length : 0}
            locationId={item.id}
          />
        ),
      },
      {
        content: (
          <div className="tableRight">
            <ButtonGroup>
              <ConfigButton onClick={() => store.openConfigModal(item)} />

              <Button onClick={() => store.openModal(item)}>Edit</Button>

              <DeleteButton
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Delete User',
                    content: `Are you sure you want to delete ${item.name}?`,
                    appearance: 'warning',
                    action: () => store.deleteLocation(item.id),
                  })
                }
              />
            </ButtonGroup>
          </div>
        ),
      },
    ],
  }))

export default rows
