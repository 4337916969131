import React from 'react'
import { Link } from 'react-router-dom'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'
import store from './Dialogues.store'

const rows = data =>
  data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.name,
        content: <Link to={`dialogues/${item.id}`}>{item.name}</Link>,
      },
      {
        key: item.sport.name,
        content: item.sport.name,
      },
      {
        key: item.language.name,
        content: item.language.name,
      },
      {
        content: (
          <div className="tableRight" style={{ minHeight: '31px' }}>
            {item.isDeletable && (
              <DeleteButton
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Delete Dialogue',
                    content: `Are you sure you want to delete ${item.name}?`,
                    appearance: 'warning',
                    action: () => store.deleteDialogues(item.id),
                  })
                }
              />
            )}
          </div>
        ),
      },
    ],
  }))

export default rows
