import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'
import {
  IsLongEnough,
  ContainsOneNumber,
  NotSameAsFirst,
  IsRequired,
} from '@utils/Validators'
import store from './ResetPassword.store.js'

const ResetPassword = ({ closeModal, id }) => {
  const {
    updateFormRef,
    updateField,
    password,
    confirmPassword,
    submitNewPassword,
  } = store

  const actions = [
    {
      text: 'Save',
      onClick: event => submitNewPassword(event, id, closeModal),
    },
    { text: 'Close', onClick: () => closeModal() },
  ]

  return (
    <Modal actions={actions} heading="Reset Password" onClose={closeModal}>
      <Form shouldFitContainer ref={updateFormRef}>
        <Field
          validators={[IsLongEnough, ContainsOneNumber, IsRequired]}
          validateOnBlurlabel="Password"
          required
          isRequired
          label="Password"
        >
          <FieldTextStateless
            name="password"
            type="password"
            shouldFitContainer
            value={password}
            onChange={updateField}
          />
        </Field>
        <Field
          validators={[
            IsRequired,
            IsLongEnough,
            ContainsOneNumber,
            NotSameAsFirst(password),
          ]}
          label="Confirm Password"
          required
          isRequired
        >
          <FieldTextStateless
            name="confirmPassword"
            type="password"
            shouldFitContainer
            value={confirmPassword}
            onChange={updateField}
          />
        </Field>
      </Form>
    </Modal>
  )
}

ResetPassword.propTypes = {
  closeModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}

export default observer(ResetPassword)
