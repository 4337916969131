import { types } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'

const { string, boolean } = types

const SearchDropdownStore = types
  .model('SearchDropdownStore', {
    visible: boolean,
    value: string,
    isActive: boolean,
  })
  .actions((self) => ({
    updateValue(event) {
      self.value = event.target.value
    },
    toggleVisibilty() {
      self.visible = !self.visible
    },
    submit(onSubmit) {
      self.visible = false
      self.isActive = true
      onSubmit(self.value)
    },
    close(onSubmit) {
      self.visible = false
      self.isActive = false
      self.value = ''
      onSubmit('')
    },
  }))
  .create({
    visible: false,
    value: '',
    isActive: false,
  })

makeInspectable(SearchDropdownStore)

export default SearchDropdownStore
