import React, { Fragment, useEffect } from 'react'
import { observer } from 'mobx-react'
import TableTree, {
  Cell,
  Header,
  Headers,
  Row,
  Rows,
} from '@atlaskit/table-tree'

import PageHeader from '@components/PageHeader/PageHeader'
import Store from './Config.store'
import get from 'lodash/get'
import ConfigTableEditorModal from '@components/Config/ConfigTableEditorModal'
import ConfigViewer from '@components/Config/ConfigViewer'
import SettingsIcon from '@atlaskit/icon/glyph/settings'
import Button, { ButtonGroup } from '@atlaskit/button'
import injectSheet from 'react-jss'
import AddItemIcon from '@atlaskit/icon/glyph/add-item'
import AddScreenModal from './AddScreen.modal'
import OrganisationStore from '../Organisation/Organisation.store'

const styles = {
  title: {
    marginRight: 'auto',
    alignItems: 'center',

    // ATLAS KIT MAKES FOR DIRTY OVERIDES
    '& > span > span': {
      top: '13px',
    },
  },
}

const getConfigId = (source) => get(source, 'config.id', '')

const Config = ({ classes }) => {
  useEffect(() => {
    Store.getOrganisations()
    OrganisationStore.getOrganisations()
    Store.getAvailableConfigVersions()
  }, [])

  const items = Store.organisations
    .reduce((acc, organisation) => {
      acc = [
        ...acc,
        {
          id: organisation.id,
          title: organisation.name,
          cumulativeConfig: { organisation: getConfigId(organisation) },
          configLevel: 'organisation',
          children: (organisation.regions || []).reduce((acc, region) => {
            acc = [
              ...acc,
              {
                id: region.id,
                title: region.name,
                cumulativeConfig: {
                  organisation: getConfigId(organisation),
                  region: getConfigId(region),
                },
                configLevel: 'region',
                children: (region.locations || []).reduce((acc, location) => {
                  acc = [
                    ...acc,
                    {
                      id: location.id,
                      title: location.name,
                      cumulativeConfig: {
                        organisation: getConfigId(organisation),
                        region: getConfigId(region),
                        location: getConfigId(location),
                      },
                      configLevel: 'location',
                      cumulativeIds: {
                        organisationId: organisation.id,
                        regionId: region.id,
                        locationId: location.id,
                      },
                      children: (location.screens || []).reduce(
                        (acc, screen) => {
                          acc = [
                            ...acc,
                            {
                              id: screen.id,
                              title: screen.name,
                              cumulativeConfig: {
                                organisation: getConfigId(organisation),
                                region: getConfigId(region),
                                location: getConfigId(location),
                                screen: getConfigId(screen),
                              },
                              configLevel: 'screen',
                              children: [],
                            },
                          ]
                          return acc
                        },
                        [],
                      ),
                    },
                  ]
                  return acc
                }, []),
              },
            ]
            return acc.sort((a, b) => a.title.localeCompare(b.title))
          }, []),
        },
      ]
      return acc
    }, [])
    .sort((a, b) => a.title.localeCompare(b.title))

  return (
    <Fragment>
      <PageHeader text="Configuration Tree" />

      <TableTree>
        <Headers>
          <Header width={300}>Title</Header>
        </Headers>
        <Rows
          items={items}
          render={({
            id,
            title,
            cumulativeConfig,
            configLevel,
            cumulativeIds,
            children,
          }) => (
            <Row itemId={id} items={children} hasChildren={children.length > 0}>
              <Cell className={classes.title}>{title}</Cell>
              <Cell>
                <ButtonGroup>
                  {configLevel === 'location' && (
                    <Button
                      onClick={() => Store.openAddScreenModal(cumulativeIds)}
                    >
                      <AddItemIcon />
                    </Button>
                  )}

                  {configLevel === 'screen' && (
                    <Button
                      onClick={() => {
                        Store.openModal({
                          nodeId: id,
                          cumulativeConfig: { ...cumulativeConfig },
                          screenId: configLevel,
                          editable: false,
                        })
                      }}
                    >
                      {id}
                    </Button>
                  )}

                  <Button
                    onClick={() => {
                      Store.openModal({
                        nodeId: id,
                        cumulativeConfig: { ...cumulativeConfig },
                        configLevel,
                        editable: true,
                      })
                    }}
                  >
                    <SettingsIcon />
                  </Button>
                </ButtonGroup>
              </Cell>
            </Row>
          )}
        />
      </TableTree>

      {Store.modalOpen && (
        <Fragment>
          {Store.modalData.editable ? (
            <ConfigTableEditorModal
              nodeId={Store.modalData.nodeId}
              cumulativeConfig={Store.modalData.cumulativeConfig}
              closeModal={Store.closeModal}
              configLevel={Store.modalData.configLevel}
              update={Store.update}
              configPath="cumulativeConfig"
            />
          ) : (
            <ConfigViewer
              onClose={Store.closeModal}
              screenId={Store.modalData.nodeId}
            />
          )}
        </Fragment>
      )}

      {Store.addScreenModalOpen && (
        <AddScreenModal {...Store.addScreenModalData} />
      )}
    </Fragment>
  )
}

export default injectSheet(styles)(observer(Config))
