import React from 'react'
import { object } from 'prop-types'
import hoc from '@utils/hoc'
import DynamicTable from '@atlaskit/dynamic-table'
import PageHeader from '@components/PageHeader/PageHeader'
import { FieldTextAreaStateless } from '@atlaskit/field-text-area'
import { Field } from '@atlaskit/form'
import Hr from '@components/Hr/Hr'
import Button, { ButtonGroup } from '@atlaskit/button'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import Spacer from '@components/Spacer/Spacer'

import rows from './rows'
import head from './tableHead'

import store from './DialogueEdit.store'

const styles = {
  splitContainer: {
    display: 'flex',
  },
  leftCol: {
    paddingRight: '40px',
    width: '40%',
  },
  rightCol: {
    paddingLeft: '40px',
    width: '60%',
  },
}

class PM {
  componentDidMount() {
    store.getDialogues()
  }
}

const DialogueEdit = ({ classes }) => (
  <div>
    <div className={classes.splitContainer}>
      <div className={classes.leftCol}>
        <PageHeader text={store.name} />

        <DynamicTable
          head={head}
          rows={rows(store.dialogue_title_names)}
          rowsPerPage={undefined}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={store.state === 'pending'}
          isFixedSize
          defaultSortKey="title"
          defaultSortOrder="ASC"
        />

        <Spacer />
      </div>
      <div className={classes.rightCol}>
        <PageHeader text="Q & A">
          <ButtonGroup>
            <Button onClick={() => store.saveAll()} appearance="primary">
              Save All Changes
            </Button>
            <Button onClick={() => store.addNewRow()} appearance="deafult">
              Add New
            </Button>
          </ButtonGroup>
        </PageHeader>

        {store.getActive().map(item => (
          <div key={item.id}>
            <Hr />
            <Field label="Question" required isRequired>
              <FieldTextAreaStateless
                value={item.question}
                name="question"
                onChange={item.update}
                shouldFitContainer
              />
            </Field>
            <Field label="Answer" required isRequired>
              <FieldTextAreaStateless
                name="answer"
                value={item.answer}
                onChange={item.update}
                shouldFitContainer
              />
            </Field>

            <PageHeader>
              <DeleteButton onClick={() => store.deleteRow(item.id)} />
            </PageHeader>
          </div>
        ))}

        <Spacer />
      </div>
    </div>
  </div>
)

DialogueEdit.propTypes = {
  classes: object,
}

export default hoc(PM, DialogueEdit, styles)
