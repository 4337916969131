import { types } from 'mobx-state-tree'

const WarningModalStore = types
  .model('WarningModalStore', {
    heading: '',
    content: '',
    appearance: '',
    actionText: '',
    isOpen: false,
  })
  .volatile(() => ({
    action: {},
    data: {},
  }))
  .actions((self) => ({
    toggle() {
      self.isOpen = !self.isOpen
    },
    ok() {
      self.toggle()
      self.action()
    },
    open(options) {
      const { heading, content, appearance, action, actionText } = options
      self.heading = heading
      self.content = content
      self.appearance = appearance
      self.action = action
      self.actionText = actionText
      self.toggle()
    },
  }))
  .create({
    type: '',
    heading: '',
    content: '',
    appearance: '',
    actionText: '',
    isOpen: false,
  })

export default WarningModalStore
