import React from 'react'
import injectSheet from 'react-jss'
import { object, string, oneOfType, array } from 'prop-types'
import { observer } from 'mobx-react'
import Header from '@atlaskit/page-header'

const styles = {
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  childContainer: {
    display: 'flex',
  },
}

export const PageHeader = ({ classes, text, children }) => {
  const { headerContainer, childContainer } = classes
  return (
    <div className={headerContainer}>
      <Header>{text}</Header>
      <div className={childContainer}>{children}</div>
    </div>
  )
}

PageHeader.propTypes = {
  classes: object,
  text: string,
  children: oneOfType([object, array]),
}

export default injectSheet(styles)(observer(PageHeader))
