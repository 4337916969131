import React from 'react'
import injectSheet from 'react-jss'
import { string, func, object } from 'prop-types'

const style = {
  link: {
    color: '#0052CC',

    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: '#0065FF',
    },
  },
}
const FakeLink = ({ text, onClick, classes }) => (
  <div onClick={onClick} className={classes.link}>
    {text}
  </div>
)

FakeLink.propTypes = {
  text: string,
  onClick: func,
  classes: object,
}

FakeLink.defaultProps = {
  onClick: () => {},
}

export default injectSheet(style)(FakeLink)
