import { types, flow, getSnapshot } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import { set } from 'lodash'
import RegionStore from '@components/Region/Region.store'
import LocationStore from '@components/Location/Location.store'
import UserStore from '../../../stores/userStore'
import { scopeIncludeEnum } from '../AdvertUtils'
import { isEmpty, isEqual, xorWith } from 'lodash'

const { string, array, enumeration } = types

const Region = types.model('Region', {
  label: string,
  value: string,
  include: types.string,
})

const Shop = types.model('Shop', {
  label: string,
  value: string,
  include: types.string,
})

const Meeting = types.model('Meeting', {
  id: string,
  uniqueName: string,
  include: types.string,
})

const Jurisdiction = types.model('jurisdiction', {
  name: string,
  include: types.string,
})

const Race = types.model('Race', {
  id: string,
  meeting: string,
  dateTime: string,
})

const AdvertScope = types.model('AdvertScope', {
  id: string,
  advertType: string,
  regions: types.maybeNull(array(Region)),
  regionExclusions: types.maybeNull(array(Shop)),
  shops: types.maybeNull(array(Shop)),
  selectedShops: types.maybeNull(array(Shop)),
  meetings: types.maybeNull(array(Meeting)),
  selectedMeetings: types.maybeNull(array(Meeting)),
  jurisdictions: types.maybeNull(array(Jurisdiction)),
  jurisdictionExclusions: types.maybeNull(array(Meeting)),
  selectedRaces: types.maybeNull(array(Race)),
})

const EMPTY_SCOPE = {
  id: '',
  advertType: '',
  regions: [{ value: '', label: '', include: scopeIncludeEnum.include }],
  regionExclusions: [
    { value: '', label: '', include: scopeIncludeEnum.include },
  ],
  shops: [{ value: '', label: '', include: scopeIncludeEnum.include }],
  selectedShops: [{ value: '', label: '', include: scopeIncludeEnum.include }],
  meetings: [
    {
      id: '',
      uniqueName: '',
      include: scopeIncludeEnum.include,
    },
  ],
  selectedMeetings: [
    {
      id: '',
      uniqueName: '',
      include: scopeIncludeEnum.include,
    },
  ],
  jurisdictions: [{ name: '', include: scopeIncludeEnum.include }],
  jurisdictionExclusions: [
    {
      id: '',
      uniqueName: '',
      include: scopeIncludeEnum.include,
    },
  ],
  selectedRaces: [
    {
      id: '',
      meeting: '',
      dateTime: '',
    },
  ],
}

const EditScopeStore = types
  .model('EditScopeStore', {
    data: array(AdvertScope),
    state: enumeration('State', ['pending', 'done', 'error']),
    scopeData: AdvertScope,
    initialScopeData: AdvertScope,
    scopeOpen: false,
  })
  .actions((self) => ({
    updateFormRef(ref) {
      self.modalRef = ref
    },
    updateScope(event, path = 'scopeData') {
      set(self[path], event.target.name, event.target.value)
      self.scopeData[event.target.name] = event.target.value
    },
    updateInitialScope(event, path = 'initialScopeData') {
      set(self[path], event.target.name, event.target.value)
      self.initialScopeData[event.target.name] = event.target.value
    },
    openScope: flow(function* openScope(scope) {
      yield RegionStore.getRegions(UserStore.organisationId)
      yield LocationStore.getLocations(UserStore.organisationId)
      const scopeData = scope ? { ...getSnapshot(scope) } : EMPTY_SCOPE
      self.scopeData = scopeData
      self.scopeOpen = true
    }),
    closeScope() {
      self.scopeData = EMPTY_SCOPE
      self.initialScopeData = EMPTY_SCOPE
      self.scopeOpen = false
    },

    getDBShops: flow(function* getDBShops() {
      const limit = 50
      let skip = 0
      const options = {
        method: 'GET',
        url: `advert-shops?advertId=${self.scopeData.id}&$limit=${limit}&$skip=${skip}`,
      }
      const data = []
      const res = yield rest(options)
      data.push(res.data)
      const totalShops = res.total
      if (totalShops > 50) {
        const extraCallsNeeded = Math.ceil(totalShops / 50) - 1
        for (let i = 0; i < extraCallsNeeded; i++) {
          skip += 50
          const options = {
            method: 'GET',
            url: `advert-shops?advertId=${self.scopeData.id}&$limit=${limit}&$skip=${skip}`,
          }
          const res = yield rest(options)
          data.push(res.data)
        }
      }
      return data.flat()
    }),

    getDBMeetings: flow(function* getDBMeetings() {
      const limit = 50
      let skip = 0
      const options = {
        method: 'GET',
        url: `advert-meeting?advertId=${self.scopeData.id}&$limit=${limit}&$skip=${skip}`,
      }
      const data = []
      const res = yield rest(options)
      data.push(res.data)
      const totalMeetings = res.total
      if (totalMeetings > 50) {
        const extraCallsNeeded = Math.ceil(totalMeetings / 50) - 1
        for (let i = 0; i < extraCallsNeeded; i++) {
          skip += 50
          const options = {
            method: 'GET',
            url: `advert-meeting?advertId=${self.scopeData.id}&$limit=${limit}&$skip=${skip}`,
          }
          const res = yield rest(options)
          data.push(res.data)
        }
      }
      return data.flat()
    }),

    getDBRaces: flow(function* getDBRaces() {
      const limit = 50
      let skip = 0
      const options = {
        method: 'GET',
        url: `advert-race?advertId=${self.scopeData.id}&$limit=${limit}&$skip=${skip}`,
      }
      const data = []
      const res = yield rest(options)
      data.push(res.data)
      const totalRaces = res.total
      if (totalRaces > 50) {
        const extraCallsNeeded = Math.ceil(totalRaces / 50) - 1
        for (let i = 0; i < extraCallsNeeded; i++) {
          skip += 50
          const options = {
            method: 'GET',
            url: `advert-race?advertId=${self.scopeData.id}&$limit=${limit}&$skip=${skip}`,
          }
          const res = yield rest(options)
          data.push(res.data)
        }
      }
      return data.flat()
    }),

    getDBRegions: flow(function* getDBRegions() {
      const limit = 50
      let skip = 0
      const options = {
        method: 'GET',
        url: `advert-region?advertId=${self.scopeData.id}&$limit=${limit}&$skip=${skip}`,
      }
      const data = []
      const res = yield rest(options)
      data.push(res.data)
      const totalRegions = res.total
      if (totalRegions > 50) {
        const extraCallsNeeded = Math.ceil(totalRegions / 50) - 1
        for (let i = 0; i < extraCallsNeeded; i++) {
          skip += 50
          const options = {
            method: 'GET',
            url: `advert-region?advertId=${self.scopeData.id}&$limit=${limit}&$skip=${skip}`,
          }
          const res = yield rest(options)
          data.push(res.data)
        }
      }
      return data.flat()
    }),

    getDBJurisdictions: flow(function* getDBJurisdictions() {
      const limit = 50
      let skip = 0
      const options = {
        method: 'GET',
        url: `advert-jurisdiction?advertId=${self.scopeData.id}&$limit=${limit}&$skip=${skip}`,
      }
      const data = []
      const res = yield rest(options)
      data.push(res.data)
      const totalJurisdictions = res.total
      if (totalJurisdictions > 50) {
        const extraCallsNeeded = Math.ceil(totalJurisdictions / 50) - 1
        for (let i = 0; i < extraCallsNeeded; i++) {
          skip += 50
          const options = {
            method: 'GET',
            url: `advert-jurisdiction?advertId=${self.scopeData.id}&$limit=${limit}&$skip=${skip}`,
          }
          const res = yield rest(options)
          data.push(res.data)
        }
      }
      return data.flat()
    }),

    saveRegions: flow(function* saveRegions() {
      let options
      const cleanBody = self.scopeData.regions.reduce((acc, curr) => {
        curr.include === 'include' &&
          acc.push({
            name: curr.label,
            regionId: curr.value,
            advertId: self.scopeData.id,
          })

        return acc
      }, [])

      options = {
        method: 'POST',
        url: 'advert-region',
        body: cleanBody,
        successMessage: `Coverage Settings Successfully Added`,
      }

      yield rest(options)

      self.closeScope()
    }),

    saveJurisdictions: flow(function* saveJurisdictions() {
      let options
      const cleanBody = self.scopeData.jurisdictions.reduce((acc, curr) => {
        curr.include === 'include' &&
          acc.push({ jurisdiction: curr.name, advertId: self.scopeData.id })

        return acc
      }, [])

      options = {
        method: 'POST',
        url: 'advert-jurisdiction',
        body: cleanBody,
        successMessage: `Coverage Settings Successfully Added`,
      }

      yield rest(options)

      self.closeScope()
    }),

    saveShops: flow(function* saveShops() {
      const excludeOrShowShops =
        self.scopeData.selectedShops &&
        self.scopeData.selectedShops.length !== 0
          ? self.scopeData.selectedShops
          : self.scopeData.regionExclusions

      let options
      const cleanBody = excludeOrShowShops.length
        ? excludeOrShowShops.reduce((acc, curr) => {
            acc.push({
              shopName: curr.label,
              shopId: curr.value,
              advertId: self.scopeData.id,
              include: curr.include,
            })

            return acc
          }, [])
        : [
            {
              shopName: '',
              shopId: '',
              advertId: self.scopeData.id,
              include: '',
            },
          ]

      if (cleanBody.length === 1 && cleanBody[0].shopId === '') {
        options = {
          method: 'DELETE',
          url: `advert-shops?advertId=${self.scopeData.id}`,
          body: cleanBody,
          successMessage: `Coverage Settings Successfully Added`,
        }
      } else {
        options = {
          method: 'POST',
          url: 'advert-shops',
          body: cleanBody,
          successMessage: `Coverage Settings Successfully Added`,
        }
      }

      yield rest(options)
      self.closeScope()
    }),

    saveMeetings: flow(function* saveMeetings() {
      const excludeOrShowMeetings =
        self.scopeData.selectedMeetings &&
        self.scopeData.selectedMeetings.length !== 0
          ? self.scopeData.selectedMeetings
          : self.scopeData.jurisdictionExclusions

      let options
      const cleanBody = excludeOrShowMeetings.length
        ? excludeOrShowMeetings.reduce((acc, curr) => {
            acc.push({
              meeting: curr.uniqueName,
              meetingId: curr.id,
              advertId: self.scopeData.id,
              include: curr.include,
            })

            return acc
          }, [])
        : [
            {
              meeting: '',
              meetingId: '',
              advertId: self.scopeData.id,
              include: '',
            },
          ]

      if (cleanBody.length === 1 && cleanBody[0].meeting === '') {
        options = {
          method: 'DELETE',
          url: `advert-meeting?advertId=${self.scopeData.id}`,
          body: cleanBody,
          successMessage: `Coverage Settings Successfully Added`,
        }
      } else {
        options = {
          method: 'POST',
          url: 'advert-meeting',
          body: cleanBody,
          successMessage: `Coverage Settings Successfully Added`,
        }
      }

      yield rest(options)
      self.closeScope()
    }),
    saveRaces: flow(function* saveRaces() {
      let options
      const cleanBody = self.scopeData.selectedRaces.length
        ? self.scopeData.selectedRaces.reduce((acc, curr) => {
            acc.push({
              raceId: curr.id,
              meeting: curr.meeting,
              dateTime: curr.dateTime,
              advertId: self.scopeData.id,
            })

            return acc
          }, [])
        : [
            {
              id: '',
              meeting: '',
              dateTime: '',
              advertId: self.scopeData.id,
            },
          ]

      if (cleanBody.length === 1 && cleanBody[0].id === '') {
        options = {
          method: 'DELETE',
          url: `advert-race?advertId=${self.scopeData.id}`,
          body: cleanBody,
          successMessage: `Coverage Settings Successfully Added`,
        }
      } else {
        options = {
          method: 'POST',
          url: 'advert-race',
          body: cleanBody,
          successMessage: `Coverage Settings Successfully Added`,
        }
      }

      yield rest(options)
      self.closeScope()
    }),

    saveCoverage() {
      const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual))
      if (
        isArrayEqual(self.scopeData.regions, self.initialScopeData.regions) ===
        false
      ) {
        self.saveRegions()
      }
      if (
        self.scopeData.advertType === 'meeting' &&
        isArrayEqual(
          self.scopeData.jurisdictions,
          self.initialScopeData.jurisdictions,
        ) === false
      ) {
        self.saveJurisdictions()
      }

      if (
        isArrayEqual(
          self.scopeData.selectedShops,
          self.initialScopeData.selectedShops,
        ) === false ||
        isArrayEqual(
          self.scopeData.regionExclusions,
          self.initialScopeData.regionExclusions,
        ) === false
      ) {
        self.saveShops()
      }

      if (
        (self.scopeData.advertType === 'meeting' &&
          isArrayEqual(
            self.scopeData.selectedMeetings,
            self.initialScopeData.selectedMeetings,
          ) === false) ||
        isArrayEqual(
          self.scopeData.jurisdictionExclusions,
          self.initialScopeData.jurisdictionExclusions,
        ) === false
      ) {
        self.saveMeetings()
      }
      if (
        self.scopeData.advertType === 'race_specific' &&
        isArrayEqual(
          self.scopeData.selectedRaces,
          self.initialScopeData.selectedRaces,
        ) === false
      ) {
        self.saveRaces()
      }

      self.closeScope()
    },
  }))
  .create({
    data: [],
    state: 'done',
    scopeData: EMPTY_SCOPE,
    initialScopeData: EMPTY_SCOPE,
    scopeOpen: false,
  })

makeInspectable(EditScopeStore)

export default EditScopeStore
