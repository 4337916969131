import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'

const { string, array, enumeration, maybeNull } = types

const RpdConfig = types.model('RpdConfig', {
  id: string,
  name: string,
  logo: maybeNull(string),
  isNew: false,
})

const EMPTY_CLONE = {
  id: '',
  name: '',
  logo: '',
  isNew: false,
}

const RpdConfigStore = types
  .model('RpdConfigStore', {
    data: array(RpdConfig),
    state: enumeration('State', ['pending', 'done', 'error']),
    modalOpen: false,
    modalData: RpdConfig,
  })
  .volatile(() => ({
    modalRef: {},
  }))
  .views(self => ({
    getLabels() {
      return self.data.map(item => ({ label: item.name, value: item.id }))
    },
    getSingleLabel(id) {
      return self.getLabels().find(item => item.value === id)
    },
  }))
  .actions(self => ({
    updateFormRef(ref) {
      self.modalRef = ref
    },
    update(event) {
      self.modalData[event.target.name] = event.target.value
    },
    openModal(data = EMPTY_CLONE) {
      const correctData = {
        ...data,
        isNew: data.id ? false : true,
      }
      self.modalData = correctData
      self.modalOpen = true
    },
    closeModal() {
      self.modalData = EMPTY_CLONE
      self.modalOpen = false
    },
    getRpdConfigs: flow(function* getRpdConfigs() {
      self.state = 'pending'

      try {
        const res = yield rest({
          url: 'rpdConfig',
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res.data
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    deleteRpdConfig: flow(function* deleteRpdConfig(id) {
      self.state = 'pending'
      try {
        yield rest({
          url: `rpdConfig/${id}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        self.state = 'done'
        self.getRpdConfigs()
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),

    saveRpdConfig: flow(function* saveRpdConfig() {
      if (self.modalRef.validate().isInvalid) return

      if (self.modalData.isNew) {
        yield self.addNewOraganisation()
      } else {
        yield self.updateRpdConfig()
      }

      self.getRpdConfigs()
      self.closeModal()
    }),
    addNewOraganisation: flow(function* addNewOraganisation() {
      const body = {
        ...self.modalData,
        id: undefined,
      }
      const options = {
        method: 'POST',
        url: 'rpdConfig',
        body,
        successMessage: `${self.modalData.name} Successfully Added`,
      }
      yield rest(options)
    }),
    updateRpdConfig: flow(function* updateRpdConfig() {
      const options = {
        method: 'PATCH',
        url: `rpdConfig/${self.modalData.id}`,
        body: self.modalData,
        successMessage: `${self.modalData.name} Successfully Updated`,
      }
      yield rest(options)
    }),
  }))
  .create({
    data: [],
    modalData: EMPTY_CLONE,
    state: 'done',
    modalOpen: false,
  })

makeInspectable(RpdConfigStore)

export default RpdConfigStore
