import { types, flow, getSnapshot } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import LocationStore from '@components/Location/Location.store'
import OrganisationStore from '@components/Organisation/Organisation.store'
import userStore from '@stores/userStore'
import rest from '@utils/rest'
import UserStore from '../../stores/userStore'
import flagStore from '@stores/flagStore'
import set from 'lodash/set'
import RegionStore from '@components/Region/Region.store'

const Organisation = types.model('Organisation', {
  name: types.string,
})

const Region = types.model('Region', {
  name: types.string,
  organisationId: types.string,
  organisation: Organisation,
})

const Location = types.model('Organisation', {
  name: types.string,
  regionId: types.maybeNull(types.string),
  region: types.maybeNull(Region),
})

export const PiConfig = types.model('PiConfig', {
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  ip: types.string,
  awsInstance: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  hostname: types.maybeNull(types.string),
  activationCode: types.maybeNull(types.string),
  locationId: types.maybeNull(types.string),
  location: types.maybeNull(Location),
  isNew: types.maybeNull(types.boolean),
})

const DEFAULT_PI_CONFIG = () => ({
  id: null,
  ip: '',
  awsInstance: null,
  url: '',
  hostname: '',
  activationCode: '',
  locationId: null,
  location: {
    name: '',
    regionId: '',
    region: {
      name: '',
      organisationId: '',
      organisation: {
        name: '',
      },
    },
  },
})

const PiConfigStore = types
  .model('PiConfigStore', {
    data: types.array(PiConfig),
    state: types.enumeration('State', ['pending', 'done', 'error']),
    modalOpen: false,
    bulkModalOpen: false,
    bulkModalUrl: types.string,
    modalData: types.maybeNull(PiConfig),
    badActivationCode: false,
    selectedIds: types.array(types.string),
    nameFilter: '',
    organisationFilterId: '',
  })
  .volatile(() => ({
    modalRef: {},
  }))
  .views(self => ({
    getLabels() {
      return self.data.map(item => ({ label: item.name, value: item.id }))
    },
    getSingleLabel(id) {
      return self.getLabels().find(item => item.value === id)
    },
    isChecked(id) {
      return self.selectedIds.indexOf(id) > -1
    },
    bulkUpdateIsChecked() {
      return (
        self.selectedIds.length && self.selectedIds.length === self.data.length
      )
    },
  }))
  .actions(self => ({
    updateFormRef(ref) {
      self.modalRef = ref
    },
    update(event) {
      set(self.modalData, event.target.name, event.target.value)

      if (event.target.name === 'location.region.organisationId') {
        self.modalData.location.regionId = null
        self.modalData.locationId = null
      }

      if (event.target.name === 'location.regionId') {
        self.modalData.locationId = null
      }
    },
    resetPi: flow(function* resetPi(id) {
      const devices = id && !Array.isArray(id) ? [id] : self.selectedIds
      if (devices && devices.length) {
        yield rest({
          method: 'POST',
          url: 'bulk-reset-devices',
          body: {
            deviceIds: devices,
          },
          successMessage: `Device${devices.length > 1 ? '' : 's'} Reset`,
        })
      }
    }),
    addToBulkUpdate(id) {
      if (self.isChecked(id)) {
        self.selectedIds.splice(self.selectedIds.indexOf(id), 1)
      } else {
        self.selectedIds.push(id)
      }
    },
    addAllToBulkUpdate() {
      if (self.selectedIds.length) {
        self.selectedIds = []
      } else {
        self.selectedIds = self.data.map(({ id }) => id)
      }
    },
    openBulkModal() {
      self.bulkModalOpen = true
    },
    closeBulkModal() {
      self.bulkModalOpen = false
    },
    openModal(data) {
      OrganisationStore.getOrganisations()
      RegionStore.getRegions()
      LocationStore.getLocations(!userStore.isAdmin())

      const snapshot = data ? getSnapshot(data) : DEFAULT_PI_CONFIG()

      const correctData = {
        ...snapshot,
        isNew: snapshot.id ? false : true,
      }

      self.modalData = correctData
      self.modalOpen = true
    },
    closeModal() {
      self.modalData = null
      self.modalOpen = false
    },
    changeFilter(event) {
      self[event.target.name] = event.target.value
    },
    search: flow(function* search() {
      self.state = 'pending'

      try {
        const res = yield rest({
          url: `config-pi/?name=${self.nameFilter}&organisationId=${self.organisationFilterId}`,
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res.data
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    getPiConfigs: flow(function* getPiConfigs() {
      self.state = 'pending'

      try {
        const res = yield rest({
          url: `config-pi/${
            window.location.search.includes('?locationId=')
              ? window.location.search
              : ''
          }`,
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res.data
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    deletePiConfig: flow(function* deletePiConfig(id) {
      self.state = 'pending'
      try {
        yield rest({
          url: `config-pi/${id}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        self.state = 'done'
        self.getPiConfigs()
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    savePiConfig: flow(function* savePiConfig() {
      if (self.modalRef.validate().isInvalid) return

      if (self.modalData.isNew) {
        yield self.validateAuthCode()
      } else {
        yield self.updatePiConfig()
        self.closeModal()
        self.getPiConfigs()
      }
    }),
    resetFilters() {
      self.organisationFilterId = ''
      self.nameFilter = ''
      self.getPiConfigs()
    },
    bulkUpdateAllDevices: flow(function* bulkUpdateAllDevices() {
      if (self.modalRef.validate().isInvalid) return

      try {
        const options = {
          method: 'POST',
          url: 'bulk-edit-devices',
          successMessage: 'Updated URLS',
          body: {
            deviceIds: self.selectedIds,
            url: self.bulkModalUrl,
          },
        }
        yield rest(options)
      } catch (e) {
        flagStore.addFlag({
          text: '',
          type: 'error',
          title: 'Error bulk updating',
        })
      }
      self.selectedIds = []
      self.bulkModalOpen = false
      self.bulkModalUrl = ''
      self.search()
    }),
    updateDeviceUrl: flow(function* updateDeviceUrl(device) {
      const options = {
        method: 'PUT',
        url: `config-pi/${device.id}`,
        body: {
          ...device,
          url: self.bulkModalUrl,
        },
      }
      yield rest(options)
    }),
    updateBulkModalUrl(event) {
      self.bulkModalUrl = event.target.value
    },
    validateAuthCode: flow(function* validateAuthCode() {
      const options = {
        method: 'GET',
        url: `config-pi?activationCode=${self.modalData.activationCode}`,
      }

      const res = yield rest(options)

      if (!res.data || !res.data[0]) {
        self.badActivationCode = true
      } else {
        self.badActivationCode = false
        self.modalData = {
          ...DEFAULT_PI_CONFIG(),
          id: res.data[0].id,
          activationCode: null,
        }
      }
    }),
    updatePiConfig: flow(function* updatePiConfig() {
      const options = {
        method: 'PUT',
        url: `config-pi/${self.modalData.id}`,
        body: {
          ...self.modalData,
          organisationId: UserStore.isAdmin()
            ? self.modalData.organisationId
            : UserStore.organisationId,
        },
        successMessage: `${self.modalData.name} Successfully Updated`,
      }
      yield rest(options)
    }),
  }))
  .create({
    data: [],
    modalData: null,
    state: 'done',
    modalOpen: false,
    bulkModalOpen: false,
    selectedIds: [],
    bulkModalUrl: '',
    nameFilter: '',
    organisationFilterId: '',
  })

makeInspectable(PiConfigStore)

export default PiConfigStore
