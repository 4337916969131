import React from 'react'
import { CheckboxStateless } from '@atlaskit/checkbox'
import store from './Languages.store'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'

const rows = data =>
  data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: 1,
        content: (
          <CheckboxStateless
            isChecked={store.isInArray(item.id)}
            value={store.isInArray(item.id)}
            onChange={() =>
              store.isInArray(item.id)
                ? WarningModalStore.open({
                    heading: 'Delete Language',
                    content: `Are you sure you want to delete ${item.name}?`,
                    appearance: 'warning',
                    action: () => store.updateSelected(item, true),
                  })
                : store.updateSelected(item, false)
            }
          />
        ),
      },
      {
        key: item.name,
        content: item.name,
      },
      {
        key: item.nativeName,
        content: item.nativeName,
      },
      {
        key: item.id,
        content: item.id.toUpperCase(),
      },
    ],
  }))

export default rows
