import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import ManagedDynamicTable from '@components/ManagedAtlaskit/ManagedDynamicTable'
import Button from '@atlaskit/button'
import PageHeader from '@components/PageHeader/PageHeader'
import store from './Location.store'
import Modal from './Location.modal'
import rows from './rows'
import head from './tableHead'
import Spacer from '@components/Spacer/Spacer'
import ConfigTableEditorModal from '@components/Config/ConfigTableEditorModal'

class Location extends Component {
  componentDidMount() {
    store.search()
  }

  render() {
    const {
      data,
      state,
      modalOpen,
      openModal,
      configEditorOpen,
      closeConfigModal,
      configModalData,
      update,
    } = store

    return (
      <Fragment>
        <PageHeader text="Location">
          <Button onClick={() => openModal()} appearance="primary">
            Create New
          </Button>
        </PageHeader>
        <ManagedDynamicTable
          head={head}
          rows={rows(data)}
          isLoading={state === 'pending'}
          sortKey={'name'}
        />
        {modalOpen && <Modal />}
        {configEditorOpen && (
          <ConfigTableEditorModal
            nodeId={configModalData.nodeId}
            cumulativeConfig={configModalData.cumulativeConfig}
            closeModal={closeConfigModal}
            configLevel="location"
            update={(event) => update(event, 'configModalData')}
            configPath="cumulativeConfig"
            saveCallBack={store.getLocations}
          />
        )}
        <Spacer />
      </Fragment>
    )
  }
}

export default observer(Location)
