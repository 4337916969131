import React from 'react'
import { object, bool } from 'prop-types'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import store from './Video.store'

const EventInfoModal = ({ active }) => {
  const actions = [
    {
      text: 'Close',
      onClick: () => {
        store.clearAll()
        store.closeModal()
      },
    },
  ]

  return (
    <Modal actions={actions} heading={'Preview'} onClose={store.closeModal}>
      <video controls>
        <source src={`${active.filePath()}`} type="video/mp4" />
      </video>
    </Modal>
  )
}

EventInfoModal.propTypes = {
  classes: object,
  isNew: bool,
  active: object,
}

export default observer(EventInfoModal)
