import React from 'react'
import Button from '@atlaskit/button'
import SettingsIcon from '@atlaskit/icon/glyph/settings'
import Tooltip from '@atlaskit/tooltip'
import PropTypes from 'prop-types'

const ConfigButton = ({ onClick }) => (
  <Tooltip content="Edit Config" position="left">
    <Button onClick={onClick}>
      <SettingsIcon />
    </Button>
  </Tooltip>
)

ConfigButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default ConfigButton
