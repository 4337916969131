import React from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'
import store from './Authentication.store.js'
import LocationStore from '@components/Location/Location.store'
import OrganisationStore from '@components/Organisation/Organisation.store'
import { MODAL_ZINDEX } from '@utils/globalVar'
import Select from '@atlaskit/select'

const AuthenticationModal = () => {
  const {
    closeModal,
    updateFormRef,
    modalData,
    update,
    saveAuthentication,
  } = store

  const actions = [
    {
      text: 'Save',
      onClick: () => saveAuthentication(),
    },
    { text: 'Close', onClick: () => closeModal() },
  ]

  return (
    <Modal actions={actions} heading="Edit Auth config" onClose={closeModal}>
      <Form
        shouldFitContainer
        ref={ref => {
          updateFormRef(ref)
        }}
      >
        <Field label="Name" required isRequired>
          <FieldTextStateless
            name="name"
            type="text"
            shouldFitContainer
            value={modalData.name}
            onChange={update}
          />
        </Field>

        <Field label="Location" isRequired required>
          <Select
            name={'LocationId'}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: MODAL_ZINDEX }) }}
            onChange={item =>
              update({
                target: { name: 'hardware.locationId', value: item.value },
              })
            }
            options={LocationStore.getLabels()}
            placeholder="Choose a Location"
            value={LocationStore.getSingleLabel(modalData.hardware.locationId)}
          />
        </Field>

        <Field label="Organisation" isRequired required>
          <Select
            name={'organisationId'}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: MODAL_ZINDEX }) }}
            onChange={item =>
              update({
                target: { name: 'hardware.organisationId', value: item.value },
              })
            }
            options={OrganisationStore.getLabels()}
            placeholder="Choose a Organisation"
            value={OrganisationStore.getSingleLabel(
              modalData.hardware.organisationId,
            )}
          />
        </Field>
      </Form>
    </Modal>
  )
}

export default observer(AuthenticationModal)
