import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'

import LocationStore from '@components/Location/Location.store'
import OrganisationStore from '@components/Organisation/Organisation.store'
import flagStore from '@stores/flagStore'
import rest from '@utils/rest'

const { string, array, enumeration, maybeNull, boolean } = types

const EMPTY_CLONE = {
  id: '',
  name: '',
  authenticated: true,
  configRptId: '',
  hardwareId: '',
  hardware: { ip: '', browser: '', organisationId: '', locationId: '' },
  browser: '',
  lastLoginDate: '',
  isNew: true,
}

const ChildData = types.model('ChildData', {
  name: maybeNull(string),
  id: maybeNull(string),
})

const Hardware = types.model('Hardware', {
  ip: string,
  browser: string,
  organisationId: maybeNull(string),
  locationId: maybeNull(string),
  location: maybeNull(ChildData),
  organisation: maybeNull(ChildData),
})

const Authentication = types
  .model('Authentication', {
    id: string,
    name: maybeNull(string),
    authenticated: boolean,
    configRptId: maybeNull(string),
    hardwareId: string,
    hardware: Hardware,
    lastLoginDate: string,
  })
  .actions(self => ({
    toggleAuth: flow(function* toggleAuth() {
      const { id, authenticated, name, hardware } = self

      if (name && hardware.locationId && hardware.organisationId) {
        const options = {
          method: 'PATCH',
          url: `config-auth/${id}`,
          body: { authenticated: !authenticated },
          successMessage: `${name} Successfully Updated`,
        }

        yield rest(options)
        AuthenticationStore.getAuthentications()
      } else {
        flagStore.addFlag({
          title: 'Please fill in missing data.',
          type: 'error',
        })
        AuthenticationStore.openModal(self)
      }
    }),
  }))

const AuthenticationStore = types
  .model('AuthenticationStore', {
    data: array(Authentication),
    state: enumeration('State', ['pending', 'done', 'error']),
    modalOpen: false,
    modalData: Authentication,
    isRpt: false,
  })
  .volatile(() => ({
    modalRef: {},
  }))
  .views(self => ({
    getLabels() {
      return self.data.map(item => ({ label: item.name, value: item.id }))
    },
    getSingleLabel(id) {
      return self.getLabels().find(item => item.value === id)
    },
  }))
  .actions(self => ({
    updateFormRef(ref) {
      self.modalRef = ref
    },
    update(event) {
      set(self.modalData, event.target.name, event.target.value)
    },
    openModal(data = EMPTY_CLONE) {
      LocationStore.getLocations()
      OrganisationStore.getOrganisations()

      const modalData = cloneDeep(data)
      self.modalData = modalData
      self.modalOpen = true
    },
    closeModal() {
      self.modalData = EMPTY_CLONE
      self.modalOpen = false
    },
    getAuthentications: flow(function* getAuthentications() {
      self.state = 'pending'
      self.isRpt = window.location.pathname.split('/').pop() === 'rpt'

      try {
        const res = yield rest({
          url: 'config-auth',
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res.data
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    deleteAuthentication: flow(function* deleteAuthentication(id) {
      self.state = 'pending'
      try {
        yield rest({
          url: `config-auth/${id}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        self.state = 'done'
        self.getAuthentications()
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),

    saveAuthentication: flow(function* saveAuthentication() {
      if (self.modalRef.validate().isInvalid) return

      const { modalData } = self

      const options = {
        method: 'PATCH',
        url: `config-auth/${modalData.id}`,
        body: modalData,
      }

      yield rest(options)

      const body = {
        organisationId: modalData.hardware.organisationId,
        locationId: modalData.hardware.locationId,
      }

      const hardwareOptions = {
        method: 'PATCH',
        url: `hardware/${modalData.hardwareId}`,
        body: body,
      }

      yield rest(hardwareOptions)

      flagStore.addFlag({
        title: 'Your changes have been saved',
        type: 'success',
      })

      self.getAuthentications()
      self.closeModal()
    }),
  }))
  .create({
    data: [],
    modalData: EMPTY_CLONE,
    state: 'done',
    modalOpen: false,
  })

makeInspectable(AuthenticationStore)

export default AuthenticationStore
