import styled from 'styled-components'

export const StyledDivHover = styled.div`
  width: 100%;
  cursor: pointer;
  &:hover {
    background: rgba(9, 30, 66, 0.04);
  }
`
export const CoverageBorder = styled.div`
  display: flex;
  border: solid 2px black;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
`

export const RadioAndCheckboxContainer = styled.div`
  width: 280px;
`

export const DescriptionText = styled.p`
  font-size: 12px;
`

export const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 250px;
`

export const Selections = styled.div`
  overflow-y: scroll;
  background: #f8f8f8;
  width: 200px;
  max-height: 100px;
  padding: 10px;
  margin: 5px;
`

export const RaceSelections = styled.div`
  overflow-y: scroll;
  background: #f8f8f8;
  width: 450px;
  max-height: 100px;
  padding: 10px;
  margin: 0px 5px 5px 5px;
`

export const RaceCoverageContainer = styled.div`
  display: flex;
  border: solid 2px black;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  height: 200px;
`

export const RaceCoverageInnerContainer = styled.div`
  width: 100%;
`

export const RaceScrollHeader = styled.div`
  display: flex;
  height: 20px;
  width: 450px;
  justify-content: space-between;
  background: #dce0e6;
  margin-left: 5px;
`
export const ZoomedOptions = styled.div`
  overflow-y: auto;
  overflow: scroll;
  border: solid black 2px;
  margin-right: 100px;
  width: 400px;
  padding: 10px;
  max-height: 300px;
`

export const ZoomedSelectedOptions = styled.div`
  overflow-y: auto;
  overflow: scroll;
  border: solid black 2px;
  width: 400px;
  padding: 10px;
  max-height: 300px;
`

export const OptionsContainer = styled.div`
  display: flex;
  margin-top: 20px;
`
export const SaveOrCancelContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
`

export const InstructionText = styled.p`
  margin-bottom: 20px;
`
