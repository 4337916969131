import React, { Fragment, useEffect } from 'react'
import { observer } from 'mobx-react'
import Preview from './Preview'
import Button from '@atlaskit/button'
import { Route } from 'react-router'
import { Link } from 'react-router-dom'
import ManagedDynamicTable from '@components/ManagedAtlaskit/ManagedDynamicTable'
import PageHeader from '@components/PageHeader/PageHeader'
import store from '../FeatureRace/featureRace.store'
import rows from './rows'
import head from './tableHead'
import Schedule from './Schedule'

const Feature = () => {
  useEffect(() => {
    store.getFeatureRaces()
  }, [])
  const {
    data,
    state,
    leftContentPreview,
    rightContentPreview,
    clearScheduleForm,
    isPreviewOpen,
  } = store

  return (
    <Fragment>
      <PageHeader text="Feature Race Scheduler">
        <Link to={'/feature-races/schedule'}>
          <Button appearance="primary" onClick={() => clearScheduleForm()}>
            Schedule
          </Button>
        </Link>
        <Route path={'/feature-races/schedule'} component={Schedule} />
      </PageHeader>
      <ManagedDynamicTable
        head={head()}
        rows={rows(data)}
        isLoading={state === 'pending'}
        sortKey={'status'}
      />
      {leftContentPreview && isPreviewOpen && <Preview isLeftContent={true} />}
      {rightContentPreview && isPreviewOpen && (
        <Preview isLeftContent={false} />
      )}
    </Fragment>
  )
}

export default observer(Feature)
