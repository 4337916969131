import React from 'react'
import Button, { ButtonGroup } from '@atlaskit/button'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import FakeLink from '@components/UI/FakeLink/FakeLink'
import store from './Organisation.store'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'
import Badge from '@atlaskit/badge'
import ConfigButton from '@components/UI/ConfigButton'

const rows = (data) =>
  data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.name,
        content: (
          <FakeLink text={item.name} onClick={() => store.openModal(item)} />
        ),
      },
      {
        key: item.logo,
        content: item.logo,
      },
      {
        key: 'regionCount',
        content: (
          <Badge appearance="important">
            {item.regions ? item.regions.length : 0}
          </Badge>
        ),
      },
      {
        key: item.oddIds,
        content: item.oddIds,
      },
      {
        key: item.contentIds,
        content: item.contentIds,
      },
      {
        content: (
          <div className="tableRight">
            <ButtonGroup>
              <ConfigButton onClick={() => store.openConfigModal(item)} />
              <Button onClick={() => store.openModal(item)}>Edit</Button>
              <DeleteButton
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Delete User',
                    content: `Are you sure you want to delete ${item.name}?`,
                    appearance: 'warning',
                    action: () => store.deleteOrganisation(item.id),
                  })
                }
              />
            </ButtonGroup>
          </div>
        ),
      },
    ],
  }))

export default rows
