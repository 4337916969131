import React from 'react'
import dayjs from 'dayjs'
import Button, { ButtonGroup } from '@atlaskit/button'
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play'
import DownloadIcon from '@atlaskit/icon/glyph/download'
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu'
import store from './Video.store'

const rows = data =>
  data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.time,
        content: dayjs(item.time).format('HH:mm'),
      },
      {
        key: item.course_style_name,
        content: item.course_style_name,
      },
      {
        key: item.horse_name,
        content: item.horse_name,
      },
      {
        key: 'preview',
        content: (
          <DropdownMenu
            trigger={
              <Button appearance="subtle">
                <VidPlayIcon size="medium" />
              </Button>
            }
            position="right middle"
          >
            <DropdownItemGroup>
              <DropdownItem
                onClick={() => {
                  item.setActive('female_video_blank')
                  store.openModal('previewModal')
                }}
                isDisabled={store.filterAutoVideo(
                  'female_video_blank',
                  item.generateId(),
                )}
              >
                Female No Audio
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  item.setActive('male_video_blank')
                  store.openModal('previewModal')
                }}
                isDisabled={store.filterAutoVideo(
                  'male_video_blank',
                  item.generateId(),
                )}
              >
                Male No Audio
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  item.setActive('manual_audio')
                  store.openModal('previewModal')
                }}
                isDisabled={store.filterAutoVideo(
                  'manual_audio',
                  item.generateId(),
                )}
              >
                Real Audio
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  item.setActive('auto_audio')
                  store.openModal('previewModal')
                }}
                isDisabled={store.filterAutoVideo(
                  'auto_audio',
                  item.generateId(),
                )}
              >
                Audo audio
              </DropdownItem>
            </DropdownItemGroup>
          </DropdownMenu>
        ),
      },
      {
        key: 'download',
        content: (
          <DropdownMenu
            trigger={
              <Button appearance="subtle">
                <DownloadIcon size="medium" />
              </Button>
            }
            position="right middle"
          >
            <DropdownItemGroup>
              <DropdownItem
                onClick={() => item.downloadFile('female_video_blank')}
                isDisabled={store.filterAutoVideo(
                  'female_video_blank',
                  item.generateId(),
                )}
              >
                Female No Audio
              </DropdownItem>
              <DropdownItem
                onClick={() => item.downloadFile('male_video_blank')}
                isDisabled={store.filterAutoVideo(
                  'male_video_blank',
                  item.generateId(),
                )}
              >
                Male No Audio
              </DropdownItem>
              <DropdownItem
                onClick={() => item.downloadFile('manual_audio')}
                isDisabled={store.filterAutoVideo(
                  'manual_audio',
                  item.generateId(),
                )}
              >
                Real Audio
              </DropdownItem>
              <DropdownItem
                onClick={() => item.downloadFile('auto_audio')}
                isDisabled={store.filterAutoVideo(
                  'auto_audio',
                  item.generateId(),
                )}
              >
                Auto Audio
              </DropdownItem>
            </DropdownItemGroup>
          </DropdownMenu>
        ),
      },

      {
        key: 'actions',
        content: (
          <div className="tableRight">
            <ButtonGroup>
              <Button
                appearance="default"
                onClick={() => item.generateNew()}
                isLoading={item.state}
              >
                Generate New
              </Button>
              <Button
                appearance="primary"
                onClick={() => {
                  item.setActive('female_video_blank')
                  store.openModal('recordModal')
                }}
              >
                Record Audio
              </Button>
            </ButtonGroup>
          </div>
        ),
      },
    ],
  }))

export default rows
