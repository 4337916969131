import React from 'react'
import { object } from 'prop-types'
import DynamicTable from '@atlaskit/dynamic-table'
import Button from '@atlaskit/button'
import hoc from '@utils/hoc'
import PageHeader from '@components/PageHeader/PageHeader'
import head from './tableHead.js'
import rows from './rows.js'
import TokensModal from './TokensModal/Tokens.modal'
import store from './Tokens.store.js'
import langStore from '@components/Languages/Languages.store'
import Spacer from '@components/Spacer/Spacer'

class PM {
  componentDidMount() {
    store.fetchTokens()
    langStore.getItems()
  }
}

const styles = {
  toolTipContainer: {
    display: 'flex',
  },
}

export const Tokens = ({ classes }) => (
  <div>
    <PageHeader text="Tokens">
      <Button onClick={store.openModal} appearance="primary">
        Create New
      </Button>
    </PageHeader>
    <DynamicTable
      head={head}
      rows={rows(store.data, classes)}
      loadingSpinnerSize="large"
      rowsPerPage={undefined}
      isLoading={store.loading}
      defaultSortKey="name"
      defaultSortOrder="ASC"
    />

    <Spacer />

    {store.modalOpen && <TokensModal />}
  </div>
)

Tokens.propTypes = {
  classes: object,
}

export default hoc(PM, Tokens, styles)
