import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import DynamicTable from '@atlaskit/dynamic-table'
import Button from '@atlaskit/button'
import PageHeader from '@components/PageHeader/PageHeader'
import store from './RpdConfig.store'
import Modal from './RpdConfig.modal'
import rows from './rows'
import head from './tableHead'
import Spacer from '@components/Spacer/Spacer'

class RpdConfig extends Component {
  componentDidMount() {
    store.getRpdConfigs()
  }

  render() {
    const { data, state, modalOpen, openModal } = store

    return (
      <Fragment>
        <PageHeader text="RPD">
          <Button onClick={() => openModal()} appearance="primary">
            Create New
          </Button>
        </PageHeader>

        <DynamicTable
          head={head}
          rows={rows(data)}
          rowsPerPage={data.length < 10 ? undefined : 10}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={state === 'pending'}
          isFixedSize
        />

        {modalOpen && <Modal />}
        <Spacer />
      </Fragment>
    )
  }
}

export default observer(RpdConfig)
