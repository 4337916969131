import styled from 'styled-components'

export const ButtonGroup = styled.div`
  display: flex;
  gap: 5px;
`

export const FileContainer = styled.div`
  display: flex;
  justify-content: 'space-evenly';
  flex-direction: ${({ contentDirection }) => contentDirection};
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const RemainingChars = styled.span`
  position: absolute;
  font-size: 12px;
  right: 20px;
  margin-top: 10px;
`
