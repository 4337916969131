import React from 'react'
import { observer } from 'mobx-react'
import DynamicTable from '@atlaskit/dynamic-table'
import PropTypes from 'prop-types'
import Select from '@atlaskit/select'
import { Field } from '@atlaskit/form'

const keys = {
  screenDisplayType: { displayName: 'Display Type' },
  screenType: {
    displayName: 'Screen Type',
  },
  testScreen: {
    displayName: 'Is test screen',
  },
  screenNumber: {
    displayName: 'Screen Number',
  },
}

const displayOptions = [
  { label: 'Wall mounted', value: 'WALL_MOUNTED' },
  { label: 'Slanted', value: 'SLANTED' },
  { label: '-', value: null },
]
const screenTypeOptions = [
  { label: 'Large 4K Screen', value: 'LARGE_4K_SCREEN' },
  { label: 'Small HD Screen', value: 'SMALL_HD_SCREEN' },
]

const testScreenOptions = [
  { label: 'true', value: true },
  { label: 'false', value: false },
]

const screenNumberOptions = Array.from({ length: 20 }, (_, index) => ({
  label: index + 1,
  value: index + 1,
}))

const values = {
  screenDisplayType: displayOptions,
  screenType: screenTypeOptions,
  testScreen: testScreenOptions,
  screenNumber: [{ label: '-', value: null }, ...screenNumberOptions],
}

const head = () => ({
  cells: [{ key: 'name', content: 'Screen Option' }],
})

const rows = ({ update, config }) => {
  const currentConfig = config.screen.config

  return Object.keys(keys).map((key) => {
    return {
      key,
      cells: [
        {
          key,
          content: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p>{keys[key].displayName || key}</p>
            </div>
          ),
        },
        {
          key: 'authenticated',
          content: (
            <Field label={null}>
              <Select
                name={key}
                options={values[key]}
                onChange={(item) => {
                  update({
                    target: {
                      name: `cumulativeConfig.screen.config.${key}`,
                      value: item.value,
                    },
                  })
                }}
                innerProps={{
                  style: { width: '100%', marginRight: '20px' },
                }}
                value={values[key].find(
                  (option) => option.value === currentConfig[key],
                )}
              />
            </Field>
          ),
        },
      ],
    }
  })
}

const ScreenConfigEditor = ({ update, config }) => {
  return (
    <div>
      <DynamicTable
        head={head()}
        rows={rows({
          update,
          config,
        })}
      />
    </div>
  )
}

ScreenConfigEditor.propTypes = {
  update: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
}

export default observer(ScreenConfigEditor)
