import React from 'react'
import { observer } from 'mobx-react'

import Modal from '@atlaskit/modal-dialog'
import Form, { Field, Validator } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'

import store from './PiConfig.store.js'

const BulkModal = () => {
  const {
    closeBulkModal,
    updateFormRef,
    bulkModalUrl,
    updateBulkModalUrl,
    bulkUpdateAllDevices,
  } = store

  const actions = [
    {
      text: 'Save',
      onClick: () => bulkUpdateAllDevices(),
    },
    { text: 'Close', onClick: () => closeBulkModal() },
  ]

  return (
    <Modal
      actions={actions}
      heading={`Bulk Edit ${store.selectedIds.length} Device URLS`}
      onClose={closeBulkModal}
    >
      <Form
        shouldFitContainer
        ref={(ref) => {
          updateFormRef(ref)
        }}
        onSubmit={() => bulkUpdateAllDevices()}
      >
        <Field
          label="URL"
          required
          isRequired
          validators={[
            <Validator
              key="urlValidator"
              func={(val) => /(http:|https:)\/\/[\w]+\.[\w]+/.test(val)}
              invalid={'Must be a valid url (start with https://)'}
            />,
          ]}
        >
          <FieldTextStateless
            name="name"
            type="text"
            shouldFitContainer
            value={bulkModalUrl}
            onChange={updateBulkModalUrl}
          />
        </Field>
      </Form>
    </Modal>
  )
}

export default observer(BulkModal)
