export default {
  cells: [
    {
      key: 'email',
      content: 'Email',
      isSortable: true,
    },

    {
      key: 'firstName',
      content: 'First Name',
      isSortable: true,
    },
    {
      key: 'lastName',
      content: 'Last Name',
      isSortable: true,
    },
    {
      key: 'phoneNumber',
      content: 'Phone Number',
      isSortable: true,
    },
    {
      key: 'role',
      content: 'Role',
      isSortable: true,
    },
    {
      key: 'more',
    },
  ],
}
