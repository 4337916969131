import React from 'react'
import Button from '@atlaskit/button'
import { FieldTextStateless } from '@atlaskit/field-text'
import store from './DialogueEdit.store'

const rows = data =>
  data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.dialogue_title.name,
        content: (
          <Button
            appearance={
              store.activeId === item.dialogueTitleId
                ? 'default'
                : 'subtle-link'
            }
            onClick={() => store.setActive(item.dialogueTitleId)}
          >
            {item.dialogue_title.name}
          </Button>
        ),
      },
      {
        key: item.title,
        content: (
          <FieldTextStateless
            isEditing
            isInitiallySelected
            value={item.title}
            onChange={item.updateField}
            shouldFitContainer
            isLabelHidden
          />
        ),
      },
    ],
  }))

export default rows
