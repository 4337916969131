import React from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'
import Select from '@atlaskit/select'
import store from './Screen.store.js'
import { MODAL_ZINDEX } from '@utils/globalVar'
import organisationStore from '@components/Organisation/Organisation.store'
import UserStore from '../../stores/userStore.js'
import RegionStore from '@components/Region/Region.store'
import LocationStore from '@components/Location/Location.store'

const ScreenModal = () => {
  const { closeModal, updateFormRef, modalData, update, saveScreen } = store

  const actions = [
    {
      text: 'Save',
      onClick: () => saveScreen(),
    },
    { text: 'Close', onClick: () => closeModal() },
  ]

  const isAdmin = UserStore.isAdmin()
  return (
    <Modal
      actions={actions}
      heading={modalData.isNew ? 'Add new Screen' : 'Edit Screen'}
      onClose={closeModal}
    >
      <Form
        shouldFitContainer
        ref={ref => {
          updateFormRef(ref)
        }}
      >
        <Field label="Name" required isRequired>
          <FieldTextStateless
            name="name"
            type="text"
            shouldFitContainer
            value={modalData.name}
            onChange={update}
          />
        </Field>

        <Field label="Organisation" isRequired required>
          <Select
            isDisabled={!isAdmin}
            name={'organisationId'}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: base => ({ ...base, zIndex: MODAL_ZINDEX }),
            }}
            onChange={item =>
              update({
                target: {
                  name: 'location.region.organisationId',
                  value: item.value,
                },
              })
            }
            options={organisationStore.getLabels()}
            placeholder="Choose a Organisation"
            value={organisationStore.getSingleLabel(
              modalData.location.region.organisationId,
            )}
          />
        </Field>

        <Field label="Region" isRequired required>
          <Select
            name={'regionId'}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: base => ({ ...base, zIndex: MODAL_ZINDEX }),
            }}
            onChange={item =>
              update({
                target: { name: 'location.regionId', value: item.value },
              })
            }
            options={RegionStore.getLabelsForOrganisationid(
              modalData.location.region.organisationId,
            )}
            placeholder="Choose a region"
            value={RegionStore.getSingleLabel(modalData.location.regionId)}
          />
        </Field>

        <Field label="Location" isRequired required>
          <Select
            name={'locationId'}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: base => ({ ...base, zIndex: MODAL_ZINDEX }),
            }}
            onChange={item =>
              update({
                target: { name: 'locationId', value: item.value },
              })
            }
            options={LocationStore.getLabelsForRegionId(
              modalData.location.regionId,
            )}
            placeholder="Choose a Location"
            value={LocationStore.getSingleLabel(modalData.locationId)}
          />
        </Field>
      </Form>
    </Modal>
  )
}

export default observer(ScreenModal)
