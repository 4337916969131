import React from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'
import store from './RpdConfig.store.js'

const RpdConfigModal = () => {
  const { closeModal, updateFormRef, modalData, update, saveRpdConfig } = store

  const actions = [
    {
      text: 'Save',
      onClick: () => saveRpdConfig(),
    },
    { text: 'Close', onClick: () => closeModal() },
  ]

  return (
    <Modal
      actions={actions}
      heading={modalData.isNew ? 'Add new RpdConfig' : 'Edit RpdConfig'}
      onClose={closeModal}
    >
      <Form
        shouldFitContainer
        ref={ref => {
          updateFormRef(ref)
        }}
      >
        <Field label="Name" required isRequired>
          <FieldTextStateless
            name="name"
            type="text"
            shouldFitContainer
            value={modalData.name}
            onChange={update}
          />
        </Field>

        <Field label="Logo">
          <FieldTextStateless
            name="logo"
            type="text"
            shouldFitContainer
            value={modalData.logo}
            onChange={update}
          />
        </Field>
      </Form>
    </Modal>
  )
}

export default observer(RpdConfigModal)
