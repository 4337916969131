export default () => ({
  cells: [
    {
      key: 'advertName',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'advertType',
      content: 'Advert Type',
      isSortable: true,
    },
    {
      key: 'advertPlacement',
      content: 'Advert Placement',
      isSortable: true,
    },
    {
      key: 'contentId',
      content: 'Content Id',
      isSortable: true,
    },
    { key: 'status', content: 'Status', isSortable: true },

    {
      key: 'startDate',
      content: 'Start Date',
    },
    {
      key: 'expirationDate',
      content: 'Expiration Date',
    },
    { key: 'preview', content: 'Preview' },
    { key: 'delete', content: 'Delete' },
    { key: 'edit', content: 'Edit' },
  ],
})
