import React from 'react'
import hoc from '@utils/hoc'
import PageHeader from '@components/PageHeader/PageHeader'
import DynamicTable from '@atlaskit/dynamic-table'
import Button, { ButtonGroup } from '@atlaskit/button'
import Modal from './NewDialoguesModal/NewDialogues.Modal'
import rows from './rows'
import head from './tableHead'
import langStore from '@components/Languages/Languages.store'
import Spacer from '@components/Spacer/Spacer'

import store from './Dialogues.store.js'

class PM {
  componentDidMount() {
    store.getExtraInfo()
    store.getDialogues()
    langStore.getItems()
  }
}

const Dialogues = () => (
  <div>
    <PageHeader text="Dialogues">
      <ButtonGroup>
        <Button onClick={store.openModal} appearance="primary">
          Create New
        </Button>
      </ButtonGroup>
    </PageHeader>

    <DynamicTable
      head={head}
      rows={rows(store.data)}
      rowsPerPage={undefined}
      loadingSpinnerSize="large"
      isLoading={store.state === 'pending'}
      isFixedSize
    />

    {store.modalOpen && <Modal />}
    <Spacer />
  </div>
)

export default hoc(PM, Dialogues)
