import React from 'react'
import { object } from 'prop-types'
import { observer } from 'mobx-react'
import Form, { Field } from '@atlaskit/form'
import FieldText from '@atlaskit/field-text'
import {
  IsLongEnough,
  ContainsOneNumber,
  NotSameAsFirst,
} from '@utils/Validators'

import store from './Password.store'

export const Password = () => {
  const {
    oldPassword,
    update,
    password,
    passwordConfirm,
    updateFormRef,
  } = store

  return (
    <div style={{ width: '100% ' }}>
      <Form
        shouldFitContainer
        ref={ref => {
          updateFormRef(ref)
        }}
      >
        <Field label="Current Password" required isRequired validateOnBlur>
          <FieldText
            name="oldPassword"
            type="password"
            shouldFitContainer
            value={oldPassword}
            onChange={update}
          />
        </Field>

        <Field
          label="Password"
          required
          isRequired
          validators={[IsLongEnough, ContainsOneNumber]}
          validateOnBlur
        >
          <FieldText
            name="password"
            type="password"
            shouldFitContainer
            onChange={update}
            value={password}
          />
        </Field>

        <Field
          label="Confirm Password"
          required
          isRequired
          validateOnBlur
          validators={[
            IsLongEnough,
            ContainsOneNumber,
            NotSameAsFirst(password),
          ]}
        >
          <FieldText
            name="passwordConfirm"
            type="password"
            shouldFitContainer
            value={passwordConfirm}
            onChange={update}
          />
        </Field>
      </Form>
    </div>
  )
}

Password.propTypes = {
  classes: object,
}

export default observer(Password)
