import React from 'react'
import { func, string, object } from 'prop-types'
import { observer } from 'mobx-react'
import injectSheet from 'react-jss'
import { DropdownMenuStateless } from '@atlaskit/dropdown-menu'
import SearchIcon from '@atlaskit/icon/glyph/search'
import { FieldTextStateless } from '@atlaskit/field-text'
import Lozenge from '@atlaskit/lozenge'
import Close from '@atlaskit/icon/glyph/editor/close'
import Button from '@atlaskit/button'
import cx from 'classnames'
import store from './SearchDropdown.store'

const ENTER_KEY = 13

const styles = {
  mainContainer: {
    'margin-right': 20,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  searchWrapper: {
    justifyContent: 'center',
    padding: '10px',
    width: '250px',
  },
}

const SearchIconContainer = ({ size = 'small' }) => (
  <div style={{ marginLeft: '10px' }}>
    <SearchIcon size={size} />
  </div>
)

const CloseIcon = () => (
  <Lozenge appearance={'removed'}>
    <Close size="small" />
  </Lozenge>
)

SearchIconContainer.propTypes = {
  size: string,
}

CloseIcon.propTypes = {
  onSubmit: func,
}

const SearchDropdown = ({ onSubmit, title = '', classes, placeholder }) => (
  <div className={cx(classes.container, classes.mainContainer)}>
    {title}
    <DropdownMenuStateless
      isOpen={store.visible}
      onOpenChange={() =>
        store.isActive ? store.close(onSubmit) : store.toggleVisibilty()
      }
      trigger={
        store.isActive ? (
          <CloseIcon onSubmit={onSubmit} />
        ) : (
          <SearchIconContainer />
        )
      }
      triggerType="default"
      position="left"
    >
      <div className={cx(classes.container, classes.searchWrapper)}>
        <FieldTextStateless
          isLabelHidden
          placeholder={placeholder}
          onKeyUp={event =>
            event.keyCode === ENTER_KEY && store.submit(onSubmit)
          }
          value={store.value}
          onChange={store.updateValue}
        />
        <Button onClick={() => store.submit(onSubmit)} appearance="subtle-link">
          Search
        </Button>
      </div>
    </DropdownMenuStateless>
  </div>
)

SearchDropdown.propTypes = {
  title: string,
  onSubmit: func.isRequired,
  classes: object.isRequired,
  placeholder: string,
}

export default injectSheet(styles)(observer(SearchDropdown))
