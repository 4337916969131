import React, { useState } from 'react'
import { observer } from 'mobx-react'
import DynamicTable from '@atlaskit/dynamic-table'

const ManagedDynamicTable = ({
  head,
  rows,
  isLoading,
  rowsPerPage = 20,
  loadingSpinnerSize = 'large',
  sortKey: _sortKey,
  sortOrder: _sortorder = 'ASC',
}) => {
  const [page, onSetPage] = useState(1)
  const [sort, onSort] = useState({
    key: _sortKey || (head.cells[0] ? head.cells[0].key : undefined),
    order: _sortorder,
  })

  return (
    <DynamicTable
      head={head}
      rows={rows}
      rowsPerPage={rowsPerPage > rows.length ? undefined : rowsPerPage}
      loadingSpinnerSize={loadingSpinnerSize}
      isLoading={isLoading}
      onSetPage={page => {
        onSetPage(page)
      }}
      defaultpage={page}
      page={page}
      defaultSortKey={sort.key}
      sortKey={sort.key}
      defaultSortOrder={sort.order}
      sortOrder={sort.order}
      onSort={sort => {
        onSort({ key: sort.key, order: sort.sortOrder })
      }}
      isFixedSize
    />
  )
}

export default observer(ManagedDynamicTable)
