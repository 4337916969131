import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'
import EditScopeStore from './EditScope.store'
import { cloneDeep } from 'lodash'
import {
  StyledDivHover,
  ZoomedSelectedOptions,
  ZoomedOptions,
  SaveOrCancelContainer,
  OptionsContainer,
  InstructionText,
} from './EditScope.styles'

const SelectShops = (props) => {
  const [isOpen, setIsOpen] = useState(true)
  const closeModal = useCallback(() => setIsOpen(false), [])
  const { scopeData, updateScope } = EditScopeStore

  const onShopClick = (shop) => {
    const shopNames = props.liveSelectedShopArray.map((shop) => shop.label)
    if (!shopNames.includes(shop.label)) {
      const newShopArray = [...props.liveSelectedShopArray, shop]
      props.setLiveSelectedShopArray(newShopArray)
    }
  }

  const removeShopClick = (shopToRemove) => {
    const remainingShops = props.liveSelectedShopArray.filter(
      (shop) => shop.label !== shopToRemove.label,
    )
    props.setLiveSelectedShopArray(remainingShops)
  }

  const shops = cloneDeep(scopeData.shops)

  return (
    <div>
      {isOpen && (
        <Modal
          onClose={() => {
            closeModal()
            props.handleClose()
          }}
        >
          <OptionsContainer>
            <ZoomedOptions>
              <InstructionText>Click on a shop to add</InstructionText>
              {shops.map((shop) => {
                return (
                  <StyledDivHover
                    key={shop.label}
                    onClick={() => onShopClick(shop)}
                  >
                    {shop.label}
                  </StyledDivHover>
                )
              })}
            </ZoomedOptions>
            <ZoomedSelectedOptions>
              <InstructionText>
                Display advert at these shops (Click to remove)
              </InstructionText>
              {props.liveSelectedShopArray &&
                props.liveSelectedShopArray.map((shop) => {
                  return (
                    <StyledDivHover
                      key={shop.label}
                      onClick={() => removeShopClick(shop)}
                    >
                      {shop.label}
                    </StyledDivHover>
                  )
                })}
            </ZoomedSelectedOptions>
          </OptionsContainer>
          <SaveOrCancelContainer>
            <Button
              onClick={() => {
                closeModal()
                props.handleClose()
                updateScope({
                  target: {
                    value: props.liveSelectedShopArray,
                    name: 'selectedShops',
                  },
                }),
                  updateScope({
                    target: { value: [], name: 'regionExclusions' },
                  })
                updateScope({
                  target: { value: [], name: 'regions' },
                })
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                closeModal()
                props.handleClose()
              }}
            >
              Cancel
            </Button>
          </SaveOrCancelContainer>
        </Modal>
      )}
    </div>
  )
}

export default observer(SelectShops)
