import React from 'react'
import injectSheet from 'react-jss'
import { object } from 'prop-types'
import { observer } from 'mobx-react'
import cx from 'classnames'

import Nav from '@components/Nav/Nav'
import menuStore from '@stores/menuStore'

const styles = (theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    paddingLeft: 335,
    transition: theme.transition,
  },
  closedNav: {
    paddingLeft: 65,
  },
  mainBodyContainer: {
    'overflow-y': 'auto',
    width: '100%',
  },
  leftScrollContainer: {
    padding: `0px ${theme.padding.large}`,
    'min-width': 900,
  },
})

export const Container = (props) => {
  const { children, classes } = props
  const {
    container,
    closedNav,
    mainBodyContainer,
    leftScrollContainer,
  } = classes
  return (
    <main
      className={cx(container, {
        [closedNav]: !menuStore.subNavOpen,
      })}
    >
      <Nav />
      <section className={mainBodyContainer}>
        <div className={leftScrollContainer}>{children}</div>
      </section>
    </main>
  )
}

Container.propTypes = {
  children: object,
  classes: object,
  location: object,
}

export default injectSheet(styles)(observer(Container))
