import React from 'react'
import Button, { ButtonGroup } from '@atlaskit/button'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import FakeLink from '@components/UI/FakeLink/FakeLink'
import store from './RptConfig.store'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'

const rows = data =>
  data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.name,
        content: (
          <FakeLink text={item.name} onClick={() => store.openModal(item)} />
        ),
      },
      {
        key: item.id,
        content: item.id,
      },
      {
        key: item.organisation.name,
        content: item.organisation.name,
      },
      {
        content: (
          <div className="tableRight">
            <ButtonGroup>
              <Button onClick={() => store.openModal(item)}>Edit</Button>
              <DeleteButton
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Delete RPT Config',
                    content: `Are you sure you want to delete ${item.name}?`,
                    appearance: 'warning',
                    action: () => store.deleteRptConfig(item.id),
                  })
                }
              />
            </ButtonGroup>
          </div>
        ),
      },
    ],
  }))

export default rows
