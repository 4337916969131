import React from 'react'
import PageHeader from '@atlaskit/page-header'
import NavLink from '@components/NavLink/NavLink'

const Admin = () => (
  <div>
    <PageHeader>Admin</PageHeader>
    <NavLink url={'/admin/users'} text={'Users'} />
    <NavLink url={'/admin/roles'} text={'Roles'} />
    <NavLink url={'/admin/organisation'} text={'Organisation'} />
  </div>
)

export default Admin
