import React from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import styled from 'styled-components'

import store from './featureRace.store'

export const NoPreview = styled.p`
  font-size: 16px;
`

const Preview = ({ isLeftContent }) => {
  const { closePreview, leftContentPreview, rightContentPreview } = store
  const actions = [{ text: 'Close', onClick: () => closePreview() }]
  const src = isLeftContent ? leftContentPreview : rightContentPreview
  return (
    <Modal
      actions={actions}
      heading={`${isLeftContent ? 'Left' : 'Right'} Content Preview`}
      onClose={closePreview}
    >
      {src !== 'NO_PREVIEW' ? (
        <img
          src={src}
          style={{
            display: 'block',
            margin: 'auto',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
        />
      ) : (
        <NoPreview>No Content Uploaded</NoPreview>
      )}
    </Modal>
  )
}

export default observer(Preview)
