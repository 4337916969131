import React from 'react'
import Form, { Field } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'
import hoc from '@utils/hoc'
import store from './Details.store'

class PM {
  componentDidMount() {
    store.getUser()
  }
}

export const Details = () => {
  const {
    email,
    firstName,
    lastName,
    phoneNumber,
    update,
    updateFormRef,
    role,
  } = store

  return (
    <div style={{ width: '100%' }}>
      <Form
        shouldFitContainer
        ref={ref => {
          updateFormRef(ref)
        }}
      >
        <Field label="Email" required isRequired>
          <FieldTextStateless
            name="email"
            type="email"
            shouldFitContainer
            value={email}
            onChange={update}
          />
        </Field>

        <Field label="First Name" required isRequired>
          <FieldTextStateless
            name="firstName"
            type="text"
            shouldFitContainer
            value={firstName}
            onChange={update}
          />
        </Field>

        <Field label="Last Name" required isRequired>
          <FieldTextStateless
            name="lastName"
            type="text"
            shouldFitContainer
            value={lastName}
            onChange={update}
          />
        </Field>

        <Field label="Phone Number">
          <FieldTextStateless
            name="phoneNumber"
            type="string"
            shouldFitContainer
            value={phoneNumber}
            onChange={update}
          />
        </Field>

        <Field label="Role">
          <FieldTextStateless
            name="role"
            type="string"
            shouldFitContainer
            value={role}
            isReadOnly
          />
        </Field>
      </Form>
    </div>
  )
}

Details.propTypes = {}

export default hoc(PM, Details)
