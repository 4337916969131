import React from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'

const styles = {
  hr: {
    height: '15px'
  }
}

export const Spacer = ({ classes }) => <div className={classes.hr} />

Spacer.propTypes = {
  classes: PropTypes.object
}

export default injectSheet(styles)(Spacer)
