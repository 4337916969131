import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import userStore from '@stores/userStore'

const { enumeration, string } = types

const Password = types
  .model('Password', {
    state: enumeration('State', ['pending', 'done', 'error']),
    oldPassword: string,
    password: string,
    passwordConfirm: string,
  })
  .volatile(() => ({
    formRef: {},
  }))
  .actions(self => ({
    updateFormRef(ref) {
      self.formRef = ref
    },
    update(e) {
      self[e.target.name] = e.target.value
    },
    submitForm: flow(function* submitForm(e) {
      e.preventDefault()

      if (self.formRef.validate().isInvalid) {
        return false
      }

      self.state = 'pending'

      try {
        const data = {
          url: `user-account/${userStore.userId}`,
          method: 'PATCH',
          successMessage: 'Password successfully updated',
          body: {
            oldPassword: self.oldPassword,
            password: self.password,
            passwordConfirm: self.passwordConfirm,
          },
        }
        const res = yield rest(data)

        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.oldPassword = ''
          self.password = ''
          self.passwordConfirm = ''
        }
      } catch (error) {
        console.error('Failed to update', error)
        self.state = 'error'
      }
    }),
  }))
  .create({
    state: 'done',
    oldPassword: '',
    password: '',
    passwordConfirm: '',
  })

makeInspectable(Password)

export default Password
