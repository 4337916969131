import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { object } from 'prop-types'
import cx from 'classnames'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import Dropdown, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu'
import jwtDecode from 'jwt-decode'
import menuStore from '@stores/menuStore'
import userStore from '@stores/userStore'
import { history } from '@stores/routerStore'
import NavIcon from '@components/NavIcon/NavIcon'
import NavSlideBar from '@components/NavSlideBar'
import UserModal from '@components/User/User.modal'
import WorldIcon from '@atlaskit/icon/glyph/world'
import HipchatSdVideoIcon from '@atlaskit/icon/glyph/hipchat/sd-video'
import userIcon from '@assets/icons/user.svg'
import settingsIcon from '@assets/icons/settings.svg'
import arrowIcon from '@assets/icons/arrow.svg'
import logo from '@assets/logo/logo.svg'
import LockIcon from '@atlaskit/icon/glyph/lock'
import PreferencesIcon from '@atlaskit/icon/glyph/preferences'
import ScreenIcon from '@atlaskit/icon/glyph/screen'
import MarketplaceIcon from '@atlaskit/icon/glyph/marketplace'
import FlagFilledIcon from '@atlaskit/icon/glyph/flag-filled'
import OverviewIcon from '@atlaskit/icon/glyph/overview'
import BacklogIcon from '@atlaskit/icon/glyph/backlog'
import MediaServicesScaleLargeIcon from '@atlaskit/icon/glyph/media-services/scale-large'
import StarIcon from '@atlaskit/icon/glyph/star'
import UserStore from '../../stores/userStore'
const styles = (theme) => ({
  navContainer: {
    display: 'flex',
    position: 'fixed',
    left: 0,
    height: '100%',
    zIndex: 1,
  },
  slimContainer: {
    width: 65,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.mainBg,
  },
  slimContainerTop: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  slimContainerBottom: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  drawContainer: {
    minWidth: 270,
    background: theme.colors.secondaryBg,
    padding: `0 ${theme.padding.large} ${theme.padding.large}`,
    borderLeft: theme.border,
    'overflow-x': 'scroll',
  },
  drawParent: {
    width: 0,
    overflow: 'hidden',
    transition: theme.transition,
    display: 'flex',
  },
  drawParentOpen: {
    width: 270,
  },
})

export const Nav = ({ classes, location }) => {
  const {
    navContainer,
    slimContainer,
    slimContainerTop,
    slimContainerBottom,
    drawContainer,
    drawParent,
    drawParentOpen,
  } = classes
  const {
    subNavOpen,
    toggleMenu,
    userModalOpen,
    closeUserModal,
    openUserModal,
  } = menuStore
  const dropdownItemStyle = { padding: 0 }

  const { exp } = jwtDecode(UserStore.token)
  const currentTimestamp = new Date().getTime() / 1000
  const tokenIsExpired = exp < currentTimestamp

  if (tokenIsExpired) {
    history.push(`/login`)
  }

  return (
    <nav className={navContainer}>
      <div className={slimContainer}>
        <div className={slimContainerTop}>
          <Link to="/">
            <NavIcon linkUrl="/" imagePath={logo} />
          </Link>
          {userStore.isAdmin() && (
            <Fragment>
              <Link to="/video">
                <NavIcon
                  pathNameToMatch="/video"
                  imagePath={HipchatSdVideoIcon}
                  tooltipContent="Video"
                />
              </Link>

              <Link to="/translations/tokens">
                <NavIcon
                  pathNameToMatch="/translations"
                  imagePath={WorldIcon}
                  tooltipContent="Translation"
                />
              </Link>

              <Link to="/authentication/rpt">
                <NavIcon
                  pathNameToMatch="/authentication"
                  imagePath={LockIcon}
                  tooltipContent="Authentication"
                />
              </Link>

              <Link to="/config/rpt">
                <NavIcon
                  pathNameToMatch="/config/rpt"
                  imagePath={PreferencesIcon}
                  tooltipContent="Configurations"
                />
              </Link>
            </Fragment>
          )}
          <Link to="/devices/list">
            <NavIcon
              pathNameToMatch="/devices/list"
              imagePath={ScreenIcon}
              tooltipContent="Device List"
            />
          </Link>
          <Dropdown
            trigger={
              <NavIcon pathNameToMatch="admin" imagePath={BacklogIcon} />
            }
            position="right"
          >
            <DropdownItemGroup>
              <DropdownItem style={dropdownItemStyle}>
                <Link to="/regions">
                  <NavIcon
                    pathNameToMatch="/regions"
                    imagePath={FlagFilledIcon}
                    tooltipContent="Regions"
                  />
                </Link>
              </DropdownItem>
              <DropdownItem style={dropdownItemStyle}>
                <Link to="/locations">
                  <NavIcon
                    pathNameToMatch="/locations"
                    imagePath={MarketplaceIcon}
                    tooltipContent="Locations"
                  />
                </Link>
              </DropdownItem>
              <DropdownItem style={dropdownItemStyle}>
                <Link to="/screens">
                  <NavIcon
                    pathNameToMatch="/screens"
                    imagePath={ScreenIcon}
                    tooltipContent="Screens"
                  />
                </Link>
              </DropdownItem>
              <DropdownItem style={dropdownItemStyle}>
                <Link to="/config">
                  <NavIcon
                    pathNameToMatch="/config"
                    imagePath={OverviewIcon}
                    tooltipContent="Device Config"
                  />
                </Link>
              </DropdownItem>
            </DropdownItemGroup>
          </Dropdown>
          <Link to="/adverts">
            <NavIcon
              pathNameToMatch="/adverts"
              imagePath={MediaServicesScaleLargeIcon}
              tooltipContent="Adverts"
            />
          </Link>
          {userStore.isAdmin() && (
            <Link to="/feature-races">
              <NavIcon
                pathNameToMatch="/feature-races"
                imagePath={StarIcon}
                tooltipContent="Feature Race"
              />
            </Link>
          )}
        </div>

        <div className={slimContainerBottom}>
          <Dropdown
            trigger={
              <NavIcon
                pathNameToMatch="profile"
                imagePath={userIcon}
                tooltipContent="Profile"
              />
            }
            position="right"
          >
            <DropdownItemGroup>
              <DropdownItem onClick={openUserModal}>My Account</DropdownItem>
              <Link to="/login" onClick={userStore.logout}>
                <DropdownItem>Logout</DropdownItem>
              </Link>
            </DropdownItemGroup>
          </Dropdown>
          {userStore.isAdmin() && (
            <Dropdown
              trigger={
                <NavIcon pathNameToMatch="admin" imagePath={settingsIcon} />
              }
              position="right"
            >
              <DropdownItemGroup>
                <Link to="/admin/users">
                  <DropdownItem>Users</DropdownItem>
                </Link>
                <Link to="/admin/roles">
                  <DropdownItem>Roles</DropdownItem>
                </Link>
                <Link to="/admin/organisation">
                  <DropdownItem>Organisations</DropdownItem>
                </Link>
              </DropdownItemGroup>
            </Dropdown>
          )}
          <NavIcon
            imagePath={arrowIcon}
            clickAction={toggleMenu}
            activeRotate={subNavOpen}
          />
        </div>
      </div>

      <div
        className={cx(drawParent, {
          [drawParentOpen]: subNavOpen,
        })}
      >
        <div className={drawContainer}>
          <NavSlideBar route={location.pathname} />
        </div>
      </div>

      {userModalOpen && <UserModal closeModal={closeUserModal} />}
    </nav>
  )
}

Nav.propTypes = {
  children: object,
  classes: object,
  location: object,
}

export default injectSheet(styles)(withRouter(observer(Nav)))
