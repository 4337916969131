import React from 'react'
import Button from '@atlaskit/button'
import DynamicTable from '@atlaskit/dynamic-table'
import hoc from '@utils/hoc'
import PageHeader from '@components/PageHeader/PageHeader'
import store from './Groups.store'
import rows from './rows'
import head from './tableHead'
import TokenStore from '@components/Tokens/Tokens.store'
import Spacer from '@components/Spacer/Spacer'

class PM {
  componentDidMount() {
    store.getGroups()
    TokenStore.fetchTokens()
  }
}

export const Groups = () => {
  const { state, postNewGroup } = store

  return (
    <div>
      <PageHeader text="Groups">
        <Button onClick={postNewGroup} appearance="primary">
          Create New
        </Button>
      </PageHeader>

      <DynamicTable
        head={head}
        rows={rows()}
        rowsPerPage={store.data.length < 10 ? undefined : 10}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={state === 'pending'}
        isFixedSize
      />
      <Spacer />
    </div>
  )
}

export default hoc(PM, Groups)
