import React from 'react'
import { CheckboxStateless } from '@atlaskit/checkbox'
import store from './PiConfig.store'

export default () => ({
  cells: [
    {
      key: 'select',
      width: 3,
      content: (
        <CheckboxStateless
          isChecked={store.bulkUpdateIsChecked()}
          onChange={() => store.addAllToBulkUpdate()}
        />
      ),
    },
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'organisation',
      content: 'Organisation',
    },
    { key: 'region', content: 'Region' },
    {
      key: 'Location',
      content: 'Location',
    },
  ],
})
