export default {
  cells: [
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
    },

    {
      key: 'organisation',
      content: 'Organisation',
      isSortable: true,
    },

    {
      key: 'more',
    },
  ],
}
