export default {
  cells: [
    {
      key: 'enabled',
      content: 'Enabled',
      isSortable: true,
      width: 10,
    },
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'nativeName',
      content: 'Native Name',
      isSortable: true,
    },
    {
      key: 'code',
      content: 'Code',
      isSortable: true,
    },
  ],
}
