import { types, flow, getSnapshot } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import ConfigStore, {
  ConfigModel,
  defaultConfig,
} from '@components/Config/Config.store'
import set from 'lodash/set'
import get from 'lodash/get'

const { optional, boolean, string, array, enumeration, maybeNull } = types

const Region = types.model('Region', {
  id: string,
  name: string,
})

const Organisation = types.model('Organisation', {
  id: string,
  name: string,
  logo: maybeNull(string),
  regions: array(Region),
  config: maybeNull(ConfigModel),
  contentIds: maybeNull(string),
  oddIds: maybeNull(string),
})

const ModalData = types.model('ModalData', {
  id: maybeNull(string, () => ''),
  name: maybeNull(string, () => ''),
  isNew: optional(boolean, false),
  contentIds: maybeNull(string, () => ''),
  oddIds: maybeNull(string, () => ''),
})

const ConfigModalData = types.model('ConfigModalData', {
  nodeId: string,
  cumulativeConfig: types.model({
    organisation: maybeNull(ConfigModel, () => defaultConfig()),
  }),
})
const emptyConfigModalData = () => ({
  nodeId: '',
  cumulativeConfig: {
    organisation: defaultConfig(),
  },
})

const emptyClone = () => ({
  id: '',
  name: '',
  isNew: true,
})

const OrganisationStore = types
  .model('OrganisationStore', {
    data: array(Organisation),
    state: enumeration('State', ['pending', 'done', 'error']),
    modalOpen: false,
    modalData: ModalData,
    configEditorOpen: boolean,
    configModalData: ConfigModalData,
  })
  .volatile(() => ({
    modalRef: {},
  }))
  .views((self) => ({
    getLabels() {
      return self.data.map((item) => ({ label: item.name, value: item.id }))
    },
    getSingleLabel(id) {
      return self.getLabels().find((item) => item.value === id) || null
    },
    getIdLabels(key) {
      const idType = () => {
        if (key === 'contentId') return 'contentIds'
        if (key === 'bookmakerId') return 'oddIds'
        return null
      }
      const type = idType()
      if (type) {
        let ids = []
        self.data.forEach((org) => {
          const oddIds = org[type]
            ? org[type].split(' ').filter((id) => id.length)
            : []
          ids = ids.concat(oddIds)
        })
        return ids.map((item) => ({
          label: item,
          value: item,
        }))
      }
      return []
    },
  }))
  .actions((self) => ({
    updateFormRef(ref) {
      self.modalRef = ref
    },
    closeConfigModal() {
      self.configModalData = emptyConfigModalData()
      self.configEditorOpen = false
    },
    openConfigModal(location) {
      const modalData = { ...getSnapshot(location) }
      const configModalData = {
        nodeId: location.id,
        cumulativeConfig: {
          organisation: get(modalData, 'config') || defaultConfig(),
        },
      }

      self.configModalData = configModalData
      self.configEditorOpen = true
    },
    update(event, path = 'modalData') {
      set(self[path], event.target.name, event.target.value)
    },
    addIdLabel(newId, idType) {
      if (self.modalData[idType]) {
        self.modalData[idType] = self.modalData[idType] + ' ' + newId
      } else {
        self.modalData[idType] = newId
      }
    },
    updateLabels(newSelect, idType) {
      const labels = newSelect.map((select) => select.value).join(' ')
      self.modalData[idType] = labels
    },
    openModal(organisation) {
      const modalData = organisation
        ? { ...getSnapshot(organisation) }
        : emptyClone()

      self.modalData = modalData
      self.modalOpen = true
    },
    closeModal() {
      self.modalData = emptyClone()
      self.modalOpen = false
    },
    getOrganisations: flow(function* getOrganisations() {
      self.state = 'pending'

      try {
        const res = yield rest({
          url: 'organisation',
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    deleteOrganisation: flow(function* deleteOrganisation(id) {
      self.state = 'pending'
      try {
        yield rest({
          url: `organisation/${id}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        self.state = 'done'
        self.getOrganisations()
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),

    saveOrganisation: flow(function* saveOrganisation() {
      if (self.modalRef.validate().isInvalid) return

      let result
      if (self.modalData.isNew) {
        result = yield self.addNewOraganisation()
      } else {
        result = yield self.updateOrganisation()
      }
      if (self.modalData.cumulativeConfig.organisation && result) {
        yield ConfigStore.saveConfig({
          ...self.modalData.cumulativeConfig.organisation,
          organisationId: result.id,
        })
      }

      self.getOrganisations()
      self.closeModal()
    }),
    addNewOraganisation: flow(function* addNewOraganisation() {
      const body = { ...self.modalData }
      delete body.id
      const options = {
        method: 'POST',
        url: 'organisation',
        body,
        successMessage: `${self.modalData.name} Successfully Added`,
      }
      return yield rest(options)
    }),
    updateOrganisation: flow(function* updateOrganisation() {
      const options = {
        method: 'PATCH',
        url: `organisation/${self.modalData.id}`,
        body: self.modalData,
        successMessage: `${self.modalData.name} Successfully Updated`,
      }
      return yield rest(options)
    }),

    removeConfig: flow(function* removeConfig() {
      const configId = self.modalData.config.id
      if (configId && configId.length > 0) {
        yield ConfigStore.deleteConfig(configId)
      }
      self.modalData.config = null
      self.getOrganisations()
    }),
  }))
  .create({
    data: [],
    modalData: emptyClone(),
    state: 'done',
    modalOpen: false,
    configEditorOpen: false,
    configModalData: emptyConfigModalData(),
  })

makeInspectable(OrganisationStore)

export default OrganisationStore
