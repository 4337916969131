export default {
  cells: [
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'Sport',
      content: 'Sport',
      isSortable: true,
    },
    {
      key: 'Language',
      content: 'Language',
      isSortable: true,
    },
    {
      key: 'more',
      width: 6,
    },
  ],
}
