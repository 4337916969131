import React from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'
import Select from '@atlaskit/select'
import store from './Location.store.js'
import { MODAL_ZINDEX } from '@utils/globalVar'
import organisationStore from '@components/Organisation/Organisation.store'
import UserStore from '../../stores/userStore.js'
import RegionStore from '@components/Region/Region.store'

const LocationModal = () => {
  const { closeModal, updateFormRef, modalData, update, saveLocation } = store

  const actions = [
    {
      text: 'Save',
      onClick: () => saveLocation(),
    },
    { text: 'Close', onClick: () => closeModal() },
  ]

  return (
    <Modal
      actions={actions}
      heading={modalData.isNew ? 'Add new Location' : 'Edit Location'}
      onClose={closeModal}
    >
      <Form
        shouldFitContainer
        ref={(ref) => {
          updateFormRef(ref)
        }}
      >
        <Field label="Name" required isRequired>
          <FieldTextStateless
            name="name"
            type="text"
            shouldFitContainer
            value={modalData.name}
            onChange={update}
          />
        </Field>

        <Field label="Shop ID">
          <FieldTextStateless
            name="shopId"
            type="text"
            shouldFitContainer
            value={modalData.shopId}
            onChange={update}
          />
        </Field>

        <Field label="Address 1" required isRequired>
          <FieldTextStateless
            name="addressOne"
            type="text"
            shouldFitContainer
            value={modalData.addressOne}
            onChange={update}
          />
        </Field>

        <Field label="Address 2">
          <FieldTextStateless
            name="addressTwo"
            type="text"
            shouldFitContainer
            value={modalData.addressTwo}
            onChange={update}
          />
        </Field>

        <Field label="City" required isRequired>
          <FieldTextStateless
            name="city"
            type="text"
            shouldFitContainer
            value={modalData.city}
            onChange={update}
          />
        </Field>

        <Field label="Post Code" required isRequired>
          <FieldTextStateless
            name="postCode"
            type="text"
            shouldFitContainer
            value={modalData.postCode}
            onChange={update}
          />
        </Field>

        <Field label="Organisation" isRequired required>
          <Select
            name={'organisationId'}
            isDisabled={!UserStore.isAdmin()}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: MODAL_ZINDEX }),
            }}
            onChange={(item) =>
              update({
                target: {
                  name: 'region.organisationId',
                  value: item.value,
                },
              })
            }
            options={organisationStore.getLabels()}
            placeholder="Choose a Organisation"
            value={organisationStore.getSingleLabel(
              modalData.region.organisationId,
            )}
          />
        </Field>

        <Field label="Region" isRequired required>
          <Select
            name={'regionId'}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: MODAL_ZINDEX }),
            }}
            onChange={(item) =>
              update({
                target: { name: 'regionId', value: item.value },
              })
            }
            options={RegionStore.getLabelsForOrganisationid(
              modalData.region.organisationId,
            )}
            placeholder="Choose a region"
            value={RegionStore.getSingleLabel(modalData.regionId)}
          />
        </Field>
      </Form>
    </Modal>
  )
}

export default observer(LocationModal)
