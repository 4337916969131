import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import { first } from '@utils/helpers'

const { enumeration, string, boolean, array, maybeNull, number } = types

const User = types
  .model('User', {
    id: string,
    email: string,
    phoneNumber: string,
    firstName: string,
    lastName: string,
    'permission.role': string,
    'permission.id': string,
    permissionId: string,
    organisationId: maybeNull(string),
    active: false,
  })
  .actions((self) => ({
    setActive() {
      self.active = true
    },
    setInactive() {
      self.active = false
    },
    update(event) {
      self[event.target.name] = event.target.value
    },
    updateOrganisation(data) {
      self.organisationId = data.value
    },
    updateSelect(data) {
      self['permission.role'] = data.label
      self['permission.id'] = data.value
    },
  }))

const Users = types
  .model('Users', {
    data: array(User),
    state: enumeration('State', ['pending', 'done', 'error']),
    editUserModal: boolean,
    newUserModal: boolean,
    emailNew: string,
    phoneNumberNew: string,
    firstNameNew: string,
    lastNameNew: string,
    roleNew: string,
    organisationIdNew: string,
    roleIdNew: string,
    password: string,
    passwordConfirm: string,
    total: number,
    limit: number,
    skip: number,
    currentPage: number,
  })
  .views((self) => ({
    getActive() {
      return first(self.data.filter((item) => item.active)) || {}
    },
  }))
  .volatile(() => ({
    modalRef: {},
  }))
  .actions((self) => ({
    openModal(data, ref) {
      self.data.forEach((item) => item.setInactive()) // turn all to not active
      data.setActive() // turn on new active
      self[ref] = true
    },
    updateFormRef(ref) {
      self.modalRef = ref
    },
    openNewModal() {
      self.newUserModal = true
    },
    updateNew(event) {
      self[event.target.name] = event.target.value
    },
    closeModal() {
      self.editUserModal = false
      self.newUserModal = false
    },
    searchUser: flow(function* searchUser(string) {
      try {
        self.state = 'pending'

        const res = yield rest({
          url: `user-account?searchString=${string}`,
          method: 'GET',
        })

        if (res.data) {
          self.data = res.data
        }
        self.state = 'done'
      } catch (error) {
        self.state = 'error'
      }
    }),
    updateNewSelect(data) {
      self['roleNew'] = data.label
      self['roleIdNew'] = data.value
    },
    updateOrganisationNew(data) {
      self.organisationIdNew = data.value
    },
    deleteUser: flow(function* deleteUser(id) {
      self.state = 'pending'
      try {
        const res = yield rest({
          url: `user-account/${id}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.getUsers()
        }
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    updateUser: flow(function* updateUser(id) {
      if (self.modalRef.validate().isInvalid) {
        return false
      }

      self.state = 'pending'

      const {
        email,
        firstName,
        lastName,
        phoneNumber,
        'permission.id': permissionId,
        organisationId,
      } = self.getActive()

      try {
        const data = {
          url: `user-account/${id}`,
          method: 'PATCH',
          successMessage: 'Successfully updated',
          body: {
            email,
            firstName,
            lastName,
            phoneNumber,
            permissionId,
            organisationId,
          },
        }
        const res = yield rest(data)

        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          self.closeModal()
        }
      } catch (error) {
        console.error('Failed to update', error)
        self.state = 'error'
      }
    }),
    getUsers: flow(function* getUsers() {
      self.state = 'pending'

      try {
        const res = yield rest({
          url: `user-account?$skip=${self.skip}`,
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res.data
          self.total = res.total
          self.limit = res.limit
        }
        self.state = 'done'
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    changePage(newPageNumber) {
      const skip = (newPageNumber - 1) * self.limit
      self.currentPage = newPageNumber
      self.skip = skip
      self.getUsers()
    },
    newUser: flow(function* newUser() {
      if (self.modalRef.validate().isInvalid || self.state === 'pending') {
        return false
      }

      self.state = 'pending'

      const {
        emailNew: email,
        firstNameNew: firstName,
        lastNameNew: lastName,
        roleIdNew: permissionId,
        organisationIdNew: organisationId,
        phoneNumberNew: phoneNumber,
        password,
        passwordConfirm,
      } = self

      const emptyClone = {
        emailNew: '',
        phoneNumberNew: '',
        firstNameNew: '',
        lastNameNew: '',
        roleNew: '',
        roleIdNew: '',
        organisationIdNew: '',
        password: '',
        passwordConfirm: '',
      }

      try {
        const data = {
          url: 'user-account',
          method: 'POST',
          successMessage: 'New User Added',
          body: {
            email,
            firstName,
            lastName,
            phoneNumber,
            permissionId,
            organisationId,
            password,
            passwordConfirm,
          },
        }

        const res = yield rest(data)

        if (res.message) {
          self.state = 'error'
        } else {
          self.state = 'done'
          Object.assign(self, emptyClone)
          self.getUsers()
          self.closeModal()
        }
      } catch (error) {
        console.error('Failed to update', error)
        self.state = 'error'
      }
    }),
  }))
  .create({
    data: [],
    state: 'done',
    editUserModal: false,
    newUserModal: false,
    modalRef: {},
    emailNew: '',
    phoneNumberNew: '',
    firstNameNew: '',
    lastNameNew: '',
    roleNew: '',
    roleIdNew: '',
    organisationIdNew: '',
    password: '',
    passwordConfirm: '',
    total: 0,
    limit: 0,
    skip: 0,
    currentPage: 1,
  })

makeInspectable(Users)

export default Users
