const data = {
  Aintree: 'AIN',
  Ascot: 'ASC',
  Ayr: 'AYR',
  Ballinrobe: 'BAL',
  'Bangor-on-Dee': 'BAN',
  Bath: 'BAT',
  Bellewstown: 'BEL',
  Beverley: 'BEV',
  Brighton: 'BRI',
  Carlisle: 'CRL',
  Cartmel: 'CRT',
  Catterick: 'CAT',
  Chelmsford: 'CFD',
  'Chelmsford (A.W)': 'CFD',
  Cheltenham: 'CHL',
  Chepstow: 'CHP',
  Chester: 'CHS',
  Clonmel: 'CLO',
  Cork: 'COR',
  Curragh: 'CUR',
  Doncaster: 'DON',
  'Down Royal': 'DRO',
  Downpatrick: 'DPT',
  Dundalk: 'DUN',
  'Dundalk (A.W)': 'DUN',
  Epsom: 'EPS',
  Exeter: 'EXE',
  Fairyhouse: 'FAI',
  Fakenham: 'FAK',
  'Ffos Las': 'FFO',
  Folkestone: 'FOL',
  Fontwell: 'FON',
  Galway: 'GAL',
  Goodwood: 'GOO',
  'Gowran Park': 'GOW',
  Hamilton: 'HAM',
  Haydock: 'HAY',
  Hereford: 'HER',
  Hexham: 'HEX',
  Huntingdon: 'HUN',
  Kelso: 'KEL',
  Kempton: 'KEM',
  'Kempton (A.W)': 'KEM',
  Kilbeggan: 'KLB',
  Killarney: 'KLN',
  Laytown: 'LAY',
  Leicester: 'LEI',
  Leopardstown: 'LEO',
  Limerick: 'LIM',
  'Limerick Junction': 'LMJ',
  Lingfield: 'LIN',
  'Lingfield (A.W)': 'LIN',
  Listowel: 'LIS',
  Ludlow: 'LUD',
  Mallow: 'MAL',
  'Market Rasen': 'MAR',
  Musselburgh: 'MUS',
  Naas: 'NAA',
  Navan: 'NAV',
  Newbury: 'NBY',
  Newcastle: 'NCS',
  'Newcastle (A.W)': 'NCS',
  Newmarket: 'NMK',
  'Newmarket (July)': 'NMK',
  'Newton Abbot': 'NAB',
  Nottingham: 'NOT',
  Perth: 'PER',
  'Phoenix Park': 'PHO',
  Plumpton: 'PLU',
  Pontefract: 'PON',
  Punchestown: 'PUN',
  Redcar: 'RED',
  Ripon: 'RIP',
  Roscommon: 'ROS',
  Salisbury: 'SAL',
  Sandown: 'SAN',
  Sedgefield: 'SED',
  Sligo: 'SLI',
  Southwell: 'STH',
  'Southwell (A.W)': 'STH',
  Stratford: 'STR',
  Taunton: 'TAU',
  Thirsk: 'THI',
  Thurles: 'THU',
  Tipperary: 'TIP',
  Towcester: 'TOW',
  Tralee: 'TRL',
  Tramore: 'TRM',
  Uttoxeter: 'UTT',
  Warwick: 'WAR',
  Wetherby: 'WET',
  Wexford: 'WEX',
  'Wexford (RH)': 'WXR',
  Wincanton: 'WCN',
  Windsor: 'WDR',
  Wolverhampton: 'WOL',
  'Wolverhampton (A.W)': 'WOL',
  Worcester: 'WOR',
  Yarmouth: 'YAR',
  York: 'YOR',
}

export default data
