import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import { Translation } from './TokensModal/Tokens.modal.store'

const { string, array, frozen } = types

const Token = types
  .model('Token', {
    id: string,
    name: string,
    softName: string,
    token_translations: array(Translation),
    groups: frozen(),
  })
  .views(self => ({
    allLangs() {
      return self.token_translations
        ? self.token_translations.map(item => item.language)
        : []
    },
  }))

const TokenStores = types
  .model('TokenStores', {
    data: array(Token),
    modalOpen: false,
    loading: false,
  })
  .views(self => ({
    getLabels() {
      return self.data.map(({ id, name }) => ({ label: name, value: id }))
    },
  }))
  .actions(self => ({
    openModal() {
      self.modalOpen = true
    },
    closeModal() {
      self.modalOpen = false
    },
    fetchTokens: flow(function* fetchTokens() {
      self.loading = true

      try {
        const res = yield rest({
          url: 'token',
        })
        self.data = res
      } catch (e) {
        console.log('error', e)
      }
      self.loading = false
    }),
    deleteToken: flow(function* deleteToken(id) {
      self.loading = true

      try {
        yield rest({
          url: `token/${id}`,
          method: 'DELETE',
        })
        self.fetchTokens()
      } catch (e) {
        console.log('error', e)
      }
      self.loading = false
    }),
  }))
  .create({
    data: [],
  })

makeInspectable(TokenStores)

export default TokenStores
