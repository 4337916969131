import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import parentStore from '../Dialogues.store'

const { enumeration, string } = types

const NewDialoguesModal = types
  .model('Groups', {
    state: enumeration('State', ['pending', 'done', 'error']),
    name: string,
    sport: string,
    lang: string,
  })
  .actions(self => ({
    update(event) {
      self.name = event.target.value
    },
    addNew: flow(function* addNew() {
      self.state = 'pending'
      try {
        const data = {
          url: 'dialogue',
          method: 'POST',
          successMessage: 'Successfully added new record',
          body: {
            name: self.name,
            languageId: parentStore.selectedLanguage.value,
            sportId: parentStore.selectedSport.value,
          },
        }
        yield rest(data)
        parentStore.getDialogues()
        parentStore.closeModal()
      } catch (error) {
        console.error('Failed to post new group', error)
        self.state = 'error'
      }
    }),
  }))
  .create({
    state: 'done',
    name: '',
    sport: '',
    lang: '',
  })

makeInspectable(NewDialoguesModal)

export default NewDialoguesModal
