import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import userStore from '@stores/userStore'
import rest from '@utils/rest'

import { history } from '@stores/routerStore'

const { string, enumeration, boolean } = types

const Login = types
  .model('BackgroundStore', {
    email: string,
    password: string,
    rememberMe: boolean,
    forgotPasswordModalVisible: boolean,
    resetPasswordId: string,
    state: enumeration('State', ['pending', 'done', 'error']),
  })
  .volatile(() => ({
    formRef: {},
  }))
  .actions(self => ({
    updateFormRef(ref) {
      self.formRef = ref
    },
    addResetPasswordId(id) {
      self.resetPasswordId = id
    },
    toggleForgotPasswordModal() {
      self.forgotPasswordModalVisible = !self.forgotPasswordModalVisible
    },
    clearResetPasswordId() {
      self.resetPasswordId = ''
    },
    sendLoginForm: flow(function* sendLoginForm(e) {
      e.preventDefault()

      if (self.formRef.validate().isInvalid) {
        return false
      }

      const data = {
        url: 'authentication',
        method: 'POST',
        body: {
          email: self.email,
          password: self.password,
          strategy: 'local',
        },
      }
      self.state = 'pending'

      try {
        const res = yield rest(data)

        if (res.message) {
          self.state = 'error'
        } else {
          userStore.updateToken(res, self.rememberMe)
          history.push('/')
          self.state = 'done'
          self.email = ''
          self.password = ''
        }
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    updateLoginDetails(e) {
      self[e.target.name] = e.target.value
    },
    toggleRemember() {
      self.rememberMe = !self.rememberMe
    },
  }))
  .create({
    email: '',
    password: '',
    state: 'done',
    forgotPasswordModalVisible: false,
    resetPasswordId: '',
    rememberMe: true,
  })

makeInspectable(Login)

export default Login
