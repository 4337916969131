import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Form, { Field } from '@atlaskit/form'
import Modal from '@atlaskit/modal-dialog'
import { DatePicker } from '@atlaskit/datetime-picker'
import { FieldTextStateless } from '@atlaskit/field-text'
import Select from '@atlaskit/select'
import { gridSize } from '@atlaskit/theme/constants'
import store from './Adverts.store'
import { possibleAdvertPlacement, getBase64 } from './AdvertUtils'
import WarningIcon from '@atlaskit/icon/glyph/warning'
import Banner from '@atlaskit/banner'
import OrganisationStore from '@components/Organisation/Organisation.store'
import FeatureRaceStore from '../FeatureRace/featureRace.store'

const AdvertsModal = () => {
  const { closeModal, modalData, update, saveAdvert, updateFormRef } = store

  const { data: featuredRaces } = FeatureRaceStore

  const filterRaces = (contentId) => {
    const activeRaces = featuredRaces.filter((race) => {
      const today = new Date()
      const raceDate = new Date(race.featureRaceDate)
      const hoursAndMinutes = race.raceTime.split(':')
      raceDate.setHours(hoursAndMinutes[0], hoursAndMinutes[1])

      return today < raceDate && race.bookmakers.includes(contentId)
    })

    return activeRaces.reduce((acc, race) => {
      acc[race.raceId] = {
        label: `${race.courseName} ${race.raceTime}`,
        value: race.raceId,
      }

      return acc
    }, {})
  }

  // const [isDisabledChecked, setIsDisabled] = useState(false)
  const [advertType, setAdvertType] = useState('')
  const [placement, setPlacement] = useState('')
  const [fileTooLarge, setFileTooLarge] = useState(false)
  const [saveAttempted, setSaveAttempted] = useState(false)
  const [charCount, setCharCount] = useState(
    modalData.termsAndConditions ? modalData.termsAndConditions.length : 0,
  )
  const [filteredFeatureRaces, setFilteredFeatureRaces] = useState(
    filterRaces(),
  )

  useEffect(() => {
    FeatureRaceStore.getFeatureRaces()
  }, [])

  useEffect(() => {
    if (!modalData.isNew) {
      setFilteredFeatureRaces(filterRaces(modalData.contentId))
    }
  }, [modalData])

  const fileSizeWarning = () => {
    return (
      <Banner
        appearance="warning"
        icon={<WarningIcon label="" secondaryColor="inherit" />}
        isOpen
      >
        File must be less than 500KB
      </Banner>
    )
  }

  const warningPopup = (message) => {
    return (
      <Banner
        appearance="warning"
        icon={<WarningIcon label="" secondaryColor="inherit" />}
        isOpen
      >
        {message}
      </Banner>
    )
  }

  const areMandatoryFieldsSelected = () => {
    return (
      !!modalData.startDate &&
      !!modalData.advertName &&
      !!modalData.contentId &&
      !!modalData.advertType &&
      !!modalData.advertPlacement &&
      !!modalData.advertWidth &&
      !!modalData.advertHeight
    )
  }

  const actions = [
    {
      text: 'Save',
      onClick: () => {
        setSaveAttempted(true)

        if (
          !fileTooLarge &&
          !isWidthIncorrect &&
          !isRPWidthIncorrect &&
          !isRPHeightIncorrect &&
          !isMeetingBarHeightIncorrect &&
          !isHeightIncorrect &&
          areMandatoryFieldsSelected()
        ) {
          saveAdvert()
        }
      },
    },
    { text: 'Close', onClick: () => closeModal() },
  ]

  const typeLabels = {
    general: { label: 'General Ad / Info Graphic', value: 'general' },
    meeting: { label: 'Meeting Level', value: 'meeting' },
    race_specific: { label: 'Race Specific', value: 'race_specific' },
    feature_race: { label: 'Feature Race', value: 'feature_race' },
  }

  const placementLabels = {
    gaps: { label: 'Meeting Gaps', value: 'gaps' },
    rpZone: { label: 'RP Zone', value: 'rpZone' },
    meeting: { label: 'Meeting Bar', value: 'meetingBar' },
    race: { label: 'Race', value: 'race' },
    race_specific: { label: 'Race Specific', value: 'race_specific' },
  }

  const isWidthIncorrect =
    ['gaps', 'race', 'race_specific'].includes(placement) &&
    modalData.advertWidth !== 520

  const isHeightIncorrect =
    ['race', 'race_specific'].includes(placement) &&
    modalData.advertHeight > 300

  const isRPWidthIncorrect =
    placement === 'rpZone' && modalData.advertWidth !== 480

  const isRPHeightIncorrect =
    placement === 'rpZone' && modalData.advertHeight !== 300

  const isMeetingBarHeightIncorrect =
    placement === 'meetingBar' && modalData.advertHeight > 50

  const characterLimit = 255

  const handleInputChange = (e) => {
    const newValue = e.target.value
    const newCharCount = newValue ? newValue.length : 0

    if (newCharCount <= characterLimit) {
      setCharCount(newCharCount)
      update({ target: { value: newValue, name: 'termsAndConditions' } })
    }
  }

  return (
    <Modal
      actions={actions}
      heading={modalData.isNew ? 'Add new Advert' : 'Edit Advert'}
      onClose={closeModal}
    >
      <Form
        shouldFitContainer
        ref={(ref) => {
          updateFormRef(ref)
        }}
      >
        <Field label="Name" isRequired>
          <FieldTextStateless
            label="Name"
            name="advertName"
            type="text"
            value={modalData.advertName.toUpperCase()}
            onChange={(e) => {
              const value = e.target.value && e.target.value.toUpperCase()
              update({ target: { value: value, name: 'advertName' } })
            }}
          />
        </Field>
        <div style={{ width: '40%' }}>
          <Field label="Content Id" isRequired>
            <Select
              name="contentId"
              options={OrganisationStore.getIdLabels('contentId')}
              placeholder="Content Id"
              onChange={(item) => {
                update({ target: { value: item.value, name: 'contentId' } })
                setFilteredFeatureRaces(filterRaces(item.value))
              }}
              defaultValue={OrganisationStore.getIdLabels('contentId').find(
                (label) => label.value === modalData.contentId,
              )}
            />
          </Field>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '100%' }}>
            <Field label="Advert Type" isRequired>
              <Select
                name="advertType"
                options={Object.values(typeLabels)}
                placeholder="Advert Type"
                onChange={(item) => {
                  update({ target: { value: item.value, name: 'advertType' } }),
                    setAdvertType(item.value)
                }}
                innerProps={{
                  style: { width: '100%', marginRight: '20px' },
                }}
                defaultValue={typeLabels[modalData.advertType]}
              />
            </Field>
          </div>
          {modalData.advertType === 'feature_race' && (
            <div style={{ width: '100%', marginLeft: '20px' }}>
              <Field label="Feature Race" isRequired>
                <Select
                  name="featureRace"
                  options={Object.values(filteredFeatureRaces)}
                  placeholder="Feature Race"
                  onChange={(item) => {
                    update({
                      target: {
                        value: item.value,
                        name: 'featureRaceId',
                      },
                    })
                  }}
                  defaultValue={filteredFeatureRaces[modalData.featureRaceId]}
                />
              </Field>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginLeft: '20px',
            }}
          >
            <Field label="Advert Placement" isRequired>
              <Select
                name="advertPlacement"
                options={possibleAdvertPlacement(advertType)}
                placeholder="Placement"
                onChange={(item) => {
                  update({
                    target: { value: item.value, name: 'advertPlacement' },
                  })
                  setPlacement(item.value)
                }}
                defaultValue={placementLabels[modalData.advertPlacement]}
              />
            </Field>
          </div>
        </div>
        <Field label="Advert Image" required isRequired>
          <FieldTextStateless
            type="file"
            name="advertImage"
            id="advertImage"
            onChange={async (e) => {
              const name = e.target.name
              const advertName = e.target.files[0].name
              getBase64(e.target.files[0]).then((result) => {
                const i = new Image()
                i.onload = function () {
                  update({
                    target: { value: Number(i.height), name: 'advertHeight' },
                  })
                  update({
                    target: { value: Number(i.width), name: 'advertWidth' },
                  })
                }
                i.src = result
                const stringLength =
                  result.length - 'data:image/png;base64,'.length
                const sizeInBytes =
                  4 * Math.ceil(stringLength / 3) * 0.5624896334383812
                const sizeInKb = sizeInBytes / 1000
                if (sizeInKb > 500) {
                  setFileTooLarge(true)
                } else {
                  setFileTooLarge(false)
                  update({
                    target: {
                      file: result,
                      name: name,
                      advertName: modalData.advertName
                        ? modalData.advertName
                        : advertName,
                    },
                  })
                }
              })
            }}
            defaultValue={modalData.advertImage}
          ></FieldTextStateless>
        </Field>
        <div>{fileTooLarge && fileSizeWarning()}</div>
        <div style={{ display: 'flex' }}>
          <Field
            label="Advert Width"
            validate={(value) => {
              if (value > 2100 && placement === 'meetingBar') {
                return 'Advert width must be smaller than 2100px'
              }
            }}
            isRequired
          >
            <FieldTextStateless
              name="advertWidth"
              type="Number"
              value={modalData.advertWidth}
              disabled={true}
              onChange={(e) => {
                const value = e.target.value
                update({
                  target: { value: Number(value), name: 'advertWidth' },
                })
              }}
            />
          </Field>
          <Field label="Advert Height" isRequired>
            <FieldTextStateless
              name="advertHeight"
              type="Number"
              disabled={true}
              value={modalData.advertHeight}
              onChange={(e) => {
                const value = placement === 'meetingBar' ? 50 : e.target.value
                update({
                  target: { value: Number(value), name: 'advertHeight' },
                })
              }}
            />
          </Field>
        </div>
        <div style={{ display: 'flex' }}>
          <Field
            label="Start Date"
            innerProps={{ style: { margin: '20px' } }}
            isRequired
          >
            <DatePicker
              innerProps={{
                style: { width: gridSize() * 20, marginRight: '20px' },
              }}
              onChange={(e) => {
                update({ target: { value: e, name: 'startDate' } })
              }}
              defaultValue={modalData.startDate}
            />
          </Field>
          <Field label="Expiration Date">
            <DatePicker
              innerProps={{ style: { width: gridSize() * 20 } }}
              onChange={(e) => {
                update({ target: { value: e, name: 'expirationDate' } })
              }}
              defaultValue={
                modalData.expirationDate ? modalData.expirationDate : ''
              }
            />
          </Field>
        </div>

        {/* 
        //TODO, fix time filters on adverts, work needed in SSR, Add back in once complete.
        <Radio
          isChecked={isDisabledChecked}
          name="allDay"
          value="true"
          label="Display all day"
          onChange={() => {
            setIsDisabled(() => true)
            update({
              target: {
                value: '00:00',
                name: 'startTime',
              },
            })
            update({
              target: {
                value: '23:59',
                name: 'endTime',
              },
            })
          }}
        />
        <Radio
          isChecked={!isDisabledChecked}
          name="betweenTimes"
          value="false"
          label="Display between times"
          onChange={() => setIsDisabled(() => false)}
        />

        <div style={{ display: 'flex' }}>
          <Field label="Start Time" innerProps={{ style: { margin: '20px' } }}>
            <TimePicker
              isDisabled={isDisabledChecked}
              times={quaterHourTimes}
              timeFormat="HH:mm"
              innerProps={{
                style: { width: gridSize() * 20, marginRight: '20px' },
              }}
              onChange={(e) => {
                update({
                  target: {
                    value: isDisabledChecked ? '' : e,
                    name: 'startTime',
                  },
                })
              }}
              defaultValue={modalData.startTime}
            />
          </Field>
          <Field label="End Time">
            <TimePicker
              isDisabled={isDisabledChecked || undefined}
              times={quaterHourTimes}
              timeFormat="HH:mm"
              innerProps={{ style: { width: gridSize() * 20 } }}
              onChange={(e) => {
                update({
                  target: {
                    value: isDisabledChecked ? '' : e,
                    name: 'endTime',
                  },
                })
              }}
              defaultValue={modalData.endTime}
            />
          </Field>
        </div> */}

        {/* <AdvertsPriceStatus /> Add back in when early price work is done*/}
        <Field label="Terms And Conditions">
          <FieldTextStateless
            shouldFitContainer={true}
            label="TermsAndConditions"
            name="termsAndConditions"
            type="text"
            value={modalData.termsAndConditions}
            onChange={handleInputChange}
          />
        </Field>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          Character Count: {charCount} / {characterLimit}
        </div>
        <div>
          {isWidthIncorrect && warningPopup('Advert width must be 520px')}
          {isHeightIncorrect &&
            warningPopup('Advert height must be less than 300px')}
          {isRPWidthIncorrect && warningPopup('Advert width must be 480px')}
          {isRPHeightIncorrect && warningPopup('Advert height must be 300px')}
          {isMeetingBarHeightIncorrect &&
            warningPopup('Advert height must be less than 50px')}
          {!areMandatoryFieldsSelected() &&
            saveAttempted &&
            warningPopup('Please fill in all mandatory fields')}
        </div>
      </Form>
    </Modal>
  )
}

export default observer(AdvertsModal)
