const first = arr => {
  if (arr && arr.length > 0) {
    return arr[0]
  }
  return undefined
}

const capitalize = str =>
  str
    .split(' ')
    .map(string => string.charAt(0).toUpperCase() + string.slice(1))
    .join(' ')

const getRandom = arr => arr[Math.floor(Math.random() * arr.length)]

const flatten = arr => [].concat.apply([], arr)

export { first, capitalize, getRandom, flatten }
