import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'

const { string } = types

const ResetPasswordStore = types
  .model('ResetPasswordStore', {
    password: string,
    confirmPassword: string
  })
  .volatile(() => ({
    formRef: {}
  }))
  .actions(self => ({
    updateFormRef(ref) {
      self.formRef = ref
    },
    updateField(event) {
      self[event.target.name] = event.target.value
    },
    submitNewPassword: flow(function* submitNewPassword(
      event,
      resetId,
      closeModal
    ) {
      event.preventDefault()
      try {
        const data = {
          url: `reset-password/${resetId}`,
          method: 'PUT',
          successMessage: `Password reset - Please, login.`,
          body: {
            password: self.password,
            passwordConfirm: self.confirmPassword
          }
        }
        const response = yield rest(data)
        if (response.email) {
          closeModal()
        }
      } catch (e) {
        throw e
      }
    })
  }))
  .create({
    password: '',
    confirmPassword: ''
  })

makeInspectable(ResetPasswordStore)

export default ResetPasswordStore
