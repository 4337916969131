import React from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'
import store from './RptConfig.store.js'
import { MODAL_ZINDEX } from '@utils/globalVar'
import OrganisationStore from '@components/Organisation/Organisation.store'
import Select from '@atlaskit/select'
import FieldTextArea from '@atlaskit/field-text-area'
import { isValidJson } from '@utils/Validators'

const RptConfigModal = () => {
  const { closeModal, updateFormRef, modalData, update, saveRptConfig } = store

  const actions = [
    {
      text: 'Save',
      onClick: () => saveRptConfig(),
    },
    { text: 'Close', onClick: () => closeModal() },
  ]

  return (
    <Modal
      actions={actions}
      heading={modalData.isNew ? 'Add new RptConfig' : 'Edit RptConfig'}
      onClose={closeModal}
    >
      <Form
        shouldFitContainer
        ref={(ref) => {
          updateFormRef(ref)
        }}
      >
        <Field label="Name" required isRequired>
          <FieldTextStateless
            name="name"
            type="text"
            shouldFitContainer
            value={modalData.name}
            onChange={update}
          />
        </Field>

        <Field label="Config" required isRequired validators={[isValidJson]}>
          <FieldTextArea
            name="config"
            type="text"
            shouldFitContainer
            value={modalData.config}
            onChange={update}
            minimumRows={10}
            enableResize="vertical"
          />
        </Field>

        <Field label="Organisation" isRequired required>
          <Select
            name={'organisationId'}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: MODAL_ZINDEX }),
            }}
            onChange={(item) =>
              update({ target: { name: 'organisationId', value: item.value } })
            }
            options={OrganisationStore.getLabels()}
            placeholder="Choose a Organisation"
            value={OrganisationStore.getSingleLabel(modalData.organisationId)}
          />
        </Field>
      </Form>
    </Modal>
  )
}

export default observer(RptConfigModal)
