import { types, flow } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'

const { string, array } = types

const Lang = types.model('Lang', {
  id: string,
  name: string,
})

const LanguagesStore = types
  .model('LanguagesStore', {
    data: array(Lang),
    loading: false,
  })
  .views(self => ({
    isInArray(id) {
      return self.data.find(item => item.id === id)
    },
    getDataForSelect() {
      return self.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
    },
  }))
  .actions(self => ({
    getItems: flow(function* getItems() {
      self.loading = true
      try {
        const res = yield rest({
          url: 'language',
        })
        self.data = res
      } catch (e) {
        console.log('error', e)
      }
      self.loading = false
    }),
    updateSelected: flow(function* updateSelected(item, isChecked) {
      self.loading = true
      const method = isChecked ? 'DELETE' : 'POST'
      const url = isChecked ? `language/${item.id}` : 'language'
      const { id, name, nativeName } = item

      try {
        yield rest({
          url,
          method,
          body: {
            id,
            name,
            nativeName,
          },
        })
        self.getItems()
      } catch (e) {
        console.log('error', e)
      }
      self.loading = false
    }),
  }))
  .create({
    name: '',
  })

makeInspectable(LanguagesStore)

export default LanguagesStore
