import React from 'react'
import { observer } from 'mobx-react'
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog'
import { useHistory } from 'react-router-dom'

const AdvertsHelp = () => {
  const history = useHistory()
  const closeModal = () => {
    history.push('/adverts')
  }

  return (
    <Modal
      onClose={closeModal}
      actions={[
        {
          text: 'Close',
          onClick: closeModal,
        },
      ]}
    >
      <ModalHeader>
        <ModalTitle>
          <u>How to add a new advert</u>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ul>
          <li> Select 'Upload Advert'</li>
          <li>
            In the 'Add New Advert' modal enter your contentId, advert type and
            advert placement.
          </li>
          <li>Click 'Choose file' to upload your advert.</li>
          Your advert must fit the following dimensions:
          <br />
          <h3>
            <u> General Ad / Info Graphic </u>
          </h3>
          <li>Meeting gaps width: 520px (set width)</li>
          <li>
            Meeting gaps height: variable (advert will only display if there is
            enough room in meeting gaps)
          </li>
          <li>RP zone width: 480px (set width)</li>
          <li>RP zone height: 300px (set height)</li>
          <br />
          <h3>
            <u>Meeting Level</u>
          </h3>
          <li>Meeting bar height: 50px (set height)</li>
          <li>
            {' '}
            Meeting bar width: variable (advert will only display if there is
            enough room after the going)
          </li>
          <li>Race width: 520px (set width)</li>
          <li> Race max height: 300px </li>
          <br />
          <h3>
            <u>Race Specific</u>
          </h3>
          <li> Width: 520px (set width)</li>
          <li> Max height: 300px</li>
        </ul>
        <br />
        <ul>
          <li>Add the start and expiration date for the advert.</li>
          <li>
            If you add terms and conditions a modal will appear displaying them
            when the advert is clicked.
          </li>
          <li>You can add text or ideally HTML to the terms and conditions.</li>
          <li>Select 'Save'. </li>
          <li>
            You will now enter the adverts scope details where you can decide
            where the advert will be displayed.
          </li>
          <br />
          <h3>
            <u>Shop coverage</u>
          </h3>
          <li>
            For shop coverage you can select 'By Region' or 'Specific Shops.'
          </li>
          <li>
            Should you select 'By Region' you can deselect any regions where you
            do not want the advert to show. You can also select 'Add / Remove
            Exclusions' to prevent specific shops from displaying the advert.
          </li>
          <li>
            Should you select 'Specific Shops' you can select a list of shops
            where you wish the advert to be displayed.
          </li>
          <br />
          <h3>
            <u>Meeting coverage</u>
          </h3>
          <li>
            Meeting coverage is only available for a meeting level advert.
          </li>
          <li>
            Should you select 'By Jurisdiction' you can deselect any
            Jurisdictions where you do not want the advert to show. You can also
            select 'Add / Remove Exclusions' to prevent the advert displaying on
            certain meetings.
          </li>
          <li>
            Should you select 'Specific Meeting' you can select a list of
            meetings where you wish the advert to be displayed.
          </li>
          <h3>
            <br />
            <u>Race coverage</u>
          </h3>
          <li>Race coverage is only available for a race specific advert.</li>
          <li>
            Select 'Add / Remove Races' to select any races you wish the advert
            to be displayed.
          </li>
          <br />
          <li>
            Once you have finished adding your scope details select 'Save
            Coverage Settings'
          </li>
        </ul>
      </ModalBody>
    </Modal>
  )
}

export default observer(AdvertsHelp)
