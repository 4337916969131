import flagStore from '@stores/flagStore'
import userStore from '@stores/userStore'
import { history } from '@stores/routerStore'
import { first } from '@utils/helpers'
import config from '@config/config'

const rest = async (params) => {
  const base = {
    baseUrl: config.apiUrl,
    url: '',
    method: 'GET',
    successMessage: false,
    successAction: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: userStore.token,
    },
  }
  const options = { ...base, ...params }
  const { baseUrl, url, method, headers, body } = options
  try {
    const data = await fetch(`${baseUrl}/${url}`, {
      method,
      headers,
      body: JSON.stringify(body),
    })

    const json = await data.json()
    if (data.status !== 200 && data.status !== 201) {
      console.log(`Problem with: ${url}`)
      flagStore.addFlag({
        title: `${json.message}. ${first(json.data) || ''}`,
        type: 'error',
      })
      if (data.status === 401 || data.status === 403) {
        history.push('/login')
      }
    } else {
      if (options.successMessage) {
        flagStore.addFlag({
          title: options.successMessage,
          type: 'success',
        })
      }
      if (options.successAction) {
        options.successAction()
      }
    }
    return json || {}
  } catch (e) {
    flagStore.addFlag({
      title: 'Error connecting',
      type: 'error',
    })
    console.error(e)
    return {}
  }
}

export default rest
