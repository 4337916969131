import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import ManagedDynamicTable from '@components/ManagedAtlaskit/ManagedDynamicTable'
import Button, { ButtonGroup } from '@atlaskit/button'
import PageHeader from '@components/PageHeader/PageHeader'
import store from './PiConfig.store'
import AuthModal from './PiConfig.modal'
import BulkModal from './Bulk.modal'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'
import rows from './rows'
import head from './tableHead'
import Spacer from '@components/Spacer/Spacer'
import OrganisationStore from '../Organisation/Organisation.store'

class PiConfig extends Component {
  componentDidMount() {
    store.getPiConfigs()
    OrganisationStore.getOrganisations()
  }

  render() {
    const {
      data,
      state,
      modalOpen,
      openModal,
      openBulkModal,
      bulkModalOpen,
    } = store

    return (
      <Fragment>
        <PageHeader text="Devices">
          <ButtonGroup>
            {store.selectedIds.length && (
              <Button onClick={() => openBulkModal()} appearance="secondary">
                Bulk Edit
              </Button>
            )}
            {store.selectedIds.length && (
              <Button
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Reset Devices',
                    content: `Are you sure you want to reset ${store.selectedIds.length} devices?`,
                    appearance: 'warning',
                    actionText: 'Reset',
                    action: () => store.resetPi(),
                  })
                }
                appearance="secondary"
              >
                Bulk Reset
              </Button>
            )}
            <Button onClick={() => openModal()} appearance="primary">
              Add New Device
            </Button>
          </ButtonGroup>
        </PageHeader>

        {state === 'done' && !data.length ? (
          <p>No Devices Found</p>
        ) : (
          <ManagedDynamicTable
            head={head()}
            rows={rows(data)}
            rowsPerPage={data.length < 10 ? undefined : 10}
            defaultPage={1}
            loadingSpinnerSize="large"
            isLoading={state === 'pending'}
            isFixedSize
          />
        )}

        {modalOpen && <AuthModal />}
        {bulkModalOpen && <BulkModal />}
        <Spacer />
      </Fragment>
    )
  }
}

export default observer(PiConfig)
