import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

import Admin from './Admin.nav'
import Translations from './Translations.nav'
import Authentication from './Authentication.nav'
import Config from './Config.nav'
import Location from './Location.nav'
import DeviceList from './DeviceList.nav'
import Adverts from './Adverts.nav'

import menuStore from '@stores/menuStore'
import userStore from '@stores/userStore'

const NavSlideBar = ({ route }) => {
  const fuzzy = (expected) => route.includes(expected)
  menuStore.toggleMenu(true)
  switch (true) {
    case fuzzy('/admin'):
      return userStore.isAdmin() ? <Admin /> : null
    case fuzzy('/translations'):
      return userStore.isAdmin() ? <Translations /> : null
    case fuzzy('/authentication'):
      return userStore.isAdmin() ? <Authentication /> : null
    case fuzzy('/config/rpt') || fuzzy('config/rpd'):
      return userStore.isAdmin() ? <Config /> : null
    case route === '/devices/location':
      return <Location />
    case fuzzy('/devices/list'):
      return <DeviceList />
    case fuzzy('/adverts'):
      return <Adverts />
    default:
      menuStore.toggleMenu()
      return null
  }
}

NavSlideBar.propTypes = {
  route: PropTypes.string,
}

export default observer(NavSlideBar)
