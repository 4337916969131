export default {
  cells: [
    {
      key: 'title',
      content: 'Token',
      isSortable: true,
    },
    {
      key: 'dialogue_title.name',
      content: 'Title',
      isSortable: true,
    },
  ],
}
