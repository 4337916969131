import { types, flow, getSnapshot } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import OrganisationStore from '@components/Organisation/Organisation.store'
import RegionStore from '@components/Region/Region.store'
import UserStore from '@stores/userStore'
import { PiConfig } from '../PiConfig/PiConfig.store'
import { history } from '@stores/routerStore'
import set from 'lodash/set'
import get from 'lodash/get'
import { ConfigModel, defaultConfig } from '@components/Config/Config.store'

const { string, array, enumeration, maybeNull, optional, boolean } = types

const Organisation = types.model('Organisation', {
  id: string,
  name: string,
  config: maybeNull(ConfigModel),
})

const Region = types.model('Region', {
  id: string,
  name: string,
  organisation: Organisation,
  organisationId: maybeNull(string),
  config: maybeNull(ConfigModel),
})

export const Location = types.model('Location', {
  id: string,
  name: string,
  shopId: maybeNull(string),
  addressOne: string,
  addressTwo: maybeNull(string),
  city: string,
  postCode: string,
  region: Region,
  regionId: maybeNull(string),
  isNew: false,
  configPis: array(PiConfig),
  config: maybeNull(ConfigModel),
})

const ModalData = types.model('ModalData', {
  id: optional(string, () => ''),
  name: optional(string, () => ''),
  shopId: maybeNull(string),
  addressOne: string,
  addressTwo: maybeNull(string),
  city: string,
  postCode: string,
  regionId: maybeNull(string),
  region: Region,
  isNew: optional(boolean, false),
})

const ConfigModalData = types.model('ConfigModalData', {
  nodeId: string,
  cumulativeConfig: types.model({
    organisation: maybeNull(ConfigModel, () => defaultConfig()),
    region: maybeNull(ConfigModel, () => defaultConfig()),
    location: maybeNull(ConfigModel, () => defaultConfig()),
  }),
})
const emptyConfigModalData = () => ({
  nodeId: '',
  cumulativeConfig: {
    organisation: defaultConfig(),
    region: defaultConfig(),
    location: defaultConfig(),
  },
})

const emptyClone = ({ organisationId = '' } = {}) => ({
  id: '',
  name: '',
  shopId: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  postCode: '',
  regionId: '',
  region: {
    id: '',
    name: '',
    organisationId,
    organisation: {
      id: organisationId,
      name: '',
    },
  },
  isNew: true,
})

const LocationStore = types
  .model('LocationStore', {
    data: array(Location),
    state: enumeration('State', ['pending', 'done', 'error']),
    configEditorOpen: boolean,
    configModalData: ConfigModalData,
    modalOpen: boolean,
    modalData: ModalData,
    shopIdFilter: string,
    postCodeFilter: string,
    cityFilter: string,
  })
  .volatile(() => ({
    modalRef: {},
  }))
  .views(self => ({
    getLabels(data = self.data) {
      return data.map(item => ({ label: item.name, value: item.id }))
    },
    getSingleLabel(id) {
      return self.getLabels().find(item => item.value === id) || null
    },
    getLabelsForRegionId(regionId) {
      const data = self.data.filter(x => x.regionId === regionId)
      return self.getLabels(data)
    },
  }))
  .actions(self => ({
    updateFormRef(ref) {
      self.modalRef = ref
    },
    closeConfigModal() {
      self.configModalData = emptyConfigModalData()
      self.configEditorOpen = false
    },
    openConfigModal(location) {
      const modalData = { ...getSnapshot(location) }
      const configModalData = {
        nodeId: location.id,
        cumulativeConfig: {
          organisation:
            get(modalData, 'region.organisation.config') || defaultConfig(),
          region: get(modalData, 'region.config') || defaultConfig(),
          location: modalData.config || defaultConfig(),
        },
      }

      self.configModalData = configModalData
      self.configEditorOpen = true
    },
    update(event, path = 'modalData') {
      set(self[path], event.target.name, event.target.value)

      if (event.target.name === 'region.organisationId') {
        self.modalData.regionId = null
      }
    },
    openModal(location) {
      OrganisationStore.getOrganisations()
      RegionStore.getRegions()
      const modalData = location
        ? { ...getSnapshot(location) }
        : emptyClone({ organisationId: UserStore.organisationId })
      self.modalData = modalData
      self.modalOpen = true
    },
    closeModal() {
      self.modalData = emptyClone()
      self.modalOpen = false
    },
    goToFilteredDeviceList(locationId) {
      history.push(`/devices/list/?locationId=${locationId}`)
    },

    getLocations: flow(function* getLocations(byOrganisation = false) {
      self.state = 'pending'

      try {
        const res = yield rest({
          url: `location${
            byOrganisation ? `?organisationId=${UserStore.organisationId}` : ''
          }`,
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res.data
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),
    deleteLocation: flow(function* deleteLocation(id) {
      self.state = 'pending'
      try {
        yield rest({
          url: `location/${id}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        self.state = 'done'
        self.getLocations()
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),

    saveLocation: flow(function* saveLocation() {
      if (self.modalRef.validate().isInvalid) return

      if (self.modalData.isNew) {
        yield self.addNewLocation()
      } else {
        yield self.updateLocation()
      }

      self.getLocations()
      self.closeModal()
    }),
    addNewLocation: flow(function* addNewLocation() {
      const body = {
        ...self.modalData,
      }
      delete body.id
      const options = {
        method: 'POST',
        url: 'location',
        body,
        successMessage: `${self.modalData.name} Successfully Added`,
      }
      return yield rest(options)
    }),

    updateLocation: flow(function* updateLocation() {
      const options = {
        method: 'PATCH',
        url: `location/${self.modalData.id}`,
        body: self.modalData,
        successMessage: `${self.modalData.name} Successfully Updated`,
      }
      return yield rest(options)
    }),
    changeFilter(event) {
      self[event.target.name] = event.target.value
    },
    resetFilters() {
      self.shopIdFilter = ''
      self.postCodeFilter = ''
      self.cityFilter = ''
      self.search()
    },
    search: flow(function* search() {
      self.state = 'pending'

      try {
        const params = {
          shop_id: self.shopIdFilter,
          organisation_id: UserStore.organisationId,
          post_code: self.postCodeFilter,
          city: self.cityFilter,
        }

        if (UserStore.isAdmin()) {
          delete params.organisation_id
        }

        const filters = new URLSearchParams(params).toString()
        const res = yield rest({
          url: `location/?${filters}`,
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res.data
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch locations', error)
        self.state = 'error'
      }
    }),
  }))
  .create({
    data: [],
    modalData: emptyClone(),
    state: 'done',
    configEditorOpen: false,
    configModalData: emptyConfigModalData(),
    modalOpen: false,
    postCodeFilter: '',
    shopIdFilter: '',
    cityFilter: '',
  })

makeInspectable(LocationStore)

export default LocationStore
