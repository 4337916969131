import { types } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'

const menuStore = types
  .model('BackgroundStore', {
    subNavOpen: true,
    userModalOpen: types.boolean,
  })
  .views(self => ({
    menuState() {
      return self.subNavOpen
    },
  }))
  .actions(self => ({
    toggleMenu(open) {
      if (open === true) {
        self.subNavOpen = open
      } else if (open === false) {
        self.subNavOpen = false
      } else {
        self.subNavOpen = !self.subNavOpen
      }
    },
    openUserModal() {
      self.userModalOpen = true
    },
    closeUserModal() {
      self.userModalOpen = false
    },
  }))
  .create({
    subNavOpen: true,
    userModalOpen: false,
  })

makeInspectable(menuStore)

export default menuStore
