import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Form, { Field } from '@atlaskit/form'
import { FieldTextStateless } from '@atlaskit/field-text'
import store from './ForgotPassword.store.js'

export const ForgotPasswordModal = ({ closeModal }) => {
  const { updateFormRef, updateEmail, email, submitForgotPassword } = store

  const actions = [
    {
      text: 'Save',
      onClick: event => submitForgotPassword(event, closeModal),
    },
    { text: 'Close', onClick: () => closeModal() },
  ]

  return (
    <Modal actions={actions} heading="Forgot Password" onClose={closeModal}>
      <Form shouldFitContainer ref={updateFormRef}>
        <Field label="Email" required isRequired>
          <FieldTextStateless
            name="email"
            type="email"
            shouldFitContainer
            value={email}
            onChange={updateEmail}
          />
        </Field>
      </Form>
    </Modal>
  )
}

ForgotPasswordModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default observer(ForgotPasswordModal)
