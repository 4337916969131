import { types, flow, getSnapshot } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'
import rest from '@utils/rest'
import set from 'lodash/set'
import get from 'lodash/get'
import OrganisationStore from '@components/Organisation/Organisation.store'
import { ConfigModel, defaultConfig } from '@components/Config/Config.store'
import UserStore from '../../stores/userStore'

const {
  identifier,
  string,
  array,
  enumeration,
  maybeNull,
  optional,
  boolean,
} = types

const Organisation = types.model('Organisation', {
  id: identifier,
  name: string,
  config: maybeNull(ConfigModel, () => defaultConfig()),
})

const Location = types.model('Location', {
  id: identifier,
  name: string,
})

export const Region = types.model('Region', {
  id: string,
  name: string,
  organisationId: string,
  organisation: Organisation,
  locations: array(Location),
  config: maybeNull(ConfigModel),
})

const ModalData = types.model('ModalData', {
  id: maybeNull(string, () => ''),
  name: maybeNull(string, () => ''),
  organisationId: maybeNull(string, () => ''),
  isNew: optional(boolean, false),
})

const emptyClone = ({ organisationId = '' } = {}) => ({
  id: '',
  name: '',
  organisationId,
  isNew: true,
})

const ConfigModalData = types.model('ConfigModalData', {
  nodeId: string,
  cumulativeConfig: types.model({
    organisation: maybeNull(ConfigModel, () => defaultConfig()),
    region: maybeNull(ConfigModel, () => defaultConfig()),
  }),
})
const emptyConfigModalData = () => ({
  nodeId: '',
  cumulativeConfig: {
    organisation: defaultConfig(),
    region: defaultConfig(),
  },
})

const RegionStore = types
  .model('RegionStore', {
    data: array(Region),
    state: enumeration('State', ['pending', 'done', 'error']),
    modalOpen: false,
    modalData: ModalData,
    configEditorOpen: boolean,
    configModalData: ConfigModalData,
  })
  .volatile(() => ({
    modalRef: {},
  }))
  .views(self => ({
    getLabels(data = self.data) {
      return data.map(item => ({ label: item.name, value: item.id }))
    },
    getSingleLabel(id) {
      return self.getLabels().find(item => item.value === id) || null
    },
    getLabelsForOrganisationid(organisationId) {
      return self.getLabels(
        organisationId === ''
          ? self.data
          : self.data.filter(x => x.organisationId === organisationId),
      )
    },
  }))
  .actions(self => ({
    updateFormRef(ref) {
      self.modalRef = ref
    },
    closeConfigModal() {
      self.configModalData = emptyConfigModalData()
      self.configEditorOpen = false
    },
    openConfigModal(location) {
      const modalData = { ...getSnapshot(location) }
      const configModalData = {
        nodeId: location.id,
        cumulativeConfig: {
          organisation:
            get(modalData, 'organisation.config') || defaultConfig(),
          region: get(modalData, 'config') || defaultConfig(),
        },
      }

      self.configModalData = configModalData
      self.configEditorOpen = true
    },
    update(event, path = 'modalData') {
      set(self[path], event.target.name, event.target.value)
    },
    openModal(region) {
      OrganisationStore.getOrganisations()
      const modalData = region
        ? { ...getSnapshot(region) }
        : emptyClone({ organisationId: UserStore.organisationId })
      self.modalData = modalData
      self.modalOpen = true
    },
    closeModal() {
      self.modalData = emptyClone()
      self.modalOpen = false
    },

    deleteRegion: flow(function* deleteRegion(id) {
      self.state = 'pending'
      try {
        yield rest({
          url: `region/${id}`,
          method: 'DELETE',
          successMessage: 'Successfully deleted',
        })

        self.state = 'done'
        self.getRegions()
      } catch (error) {
        console.error('Failed to fetch projects', error)
        self.state = 'error'
      }
    }),

    saveRegion: flow(function* saveRegion() {
      if (self.modalRef.validate().isInvalid) return

      let options
      if (self.modalData.id === '') {
        const cleanBody = { ...self.modalData }
        delete cleanBody.id
        options = {
          method: 'POST',
          url: 'region',
          body: cleanBody,
          successMessage: `${self.modalData.name} Successfully Added`,
        }
      } else {
        options = {
          method: 'PATCH',
          url: `region/${self.modalData.id}`,
          body: self.modalData,
          successMessage: `${self.modalData.name} Successfully Updated`,
        }
      }
      yield rest(options)
      self.getRegions()
      self.closeModal()
    }),

    getRegions: flow(function* getRegions(organisationId) {
      self.state = 'pending'
      try {
        const res = yield rest({
          url: `region${
            organisationId ? `?organisationId=${organisationId}` : ''
          }`,
        })

        if (res.message) {
          self.state = 'error'
        } else {
          self.data = res
          self.state = 'done'
        }
      } catch (error) {
        console.error('Failed to fetch regions', error)
        self.state = 'error'
      }
    }),
  }))
  .create({
    data: [],
    state: 'done',
    modalOpen: false,
    modalData: emptyClone(),
    configEditorOpen: false,
    configModalData: emptyConfigModalData(),
  })

makeInspectable(RegionStore)

export default RegionStore
