export default {
  cells: [
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'shopId',
      content: 'Shop ID',
      isSortable: true,
    },
    {
      key: 'city',
      content: 'City',
      isSortable: true,
    },
    {
      key: 'postCode',
      content: 'Post Code',
      isSortable: true,
    },
    {
      key: 'orgName',
      content: 'Organisation',
    },
    {
      key: 'regionName',
      content: 'Region',
    },
    {
      key: 'deviceCount',
      content: 'Devices',
    },
    {
      key: 'more',
    },
  ],
}
