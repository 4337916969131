import React from 'react'
import Tooltip from '@atlaskit/tooltip'
import ImageIcon from '@atlaskit/icon/glyph/image'
import Button, { ButtonGroup } from '@atlaskit/button'
import { format } from 'date-fns'

import WarningModalStore from '@ui/WarningModal/Warning.modal.store'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import store from './featureRace.store'

const rows = (data) =>
  data.map((item, index) => {
    const today = new Date()
    const raceDate = new Date(item.featureRaceDate)
    const hoursAndMinutes = item.raceTime.split(':')
    raceDate.setHours(hoursAndMinutes[0], hoursAndMinutes[1])
    const displayFromDate = new Date(item.displayFromDate)
    let status = 'Active'
    if (today < displayFromDate) {
      status = 'Scheduled'
    }
    if (today > raceDate) {
      status = 'Expired'
    }
    return {
      key: index,
      cells: [
        {
          key: item.featureRaceName,
          content: item.featureRaceName,
        },
        {
          key: item.raceTime,
          content: `${item.courseName}, ${item.raceTime}`,
        },
        {
          key: item.featureRaceDate,
          content: format(new Date(item.featureRaceDate), 'dd-MM-yyyy'),
        },
        {
          key: item.displayFromDate,
          content: format(new Date(item.displayFromDate), 'dd-MM-yyyy'),
        },
        { key: status, content: status },
        {
          content: (
            <Tooltip content={'Left Content Preview'} position={'bottom'}>
              <Button
                appearance="primary"
                onClick={() =>
                  store.openPreview({
                    isLeftContent: true,
                    raceId: item.raceId,
                  })
                }
              >
                <ImageIcon />
              </Button>
            </Tooltip>
          ),
        },
        {
          content: (
            <Tooltip content={'Right Content Preview'} position={'bottom'}>
              <Button
                appearance="primary"
                onClick={() =>
                  store.openPreview({
                    isLeftContent: false,
                    raceId: item.raceId,
                  })
                }
              >
                <ImageIcon />
              </Button>
            </Tooltip>
          ),
        },
        {
          content: (
            <ButtonGroup>
              <DeleteButton
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Delete Race',
                    content: `Are you sure you want to delete ${item.featureRaceName}?`,
                    appearance: 'warning',
                    actionText: 'Delete',
                    action: () => store.deleteFeatureRace(item.id, item.raceId),
                  })
                }
              />
            </ButtonGroup>
          ),
        },
        {
          content: (
            <div style={{ marginBottom: '8px' }}>
              <Button spacing="none" onClick={() => store.openModal(item)}>
                Edit Properties
              </Button>
            </div>
          ),
        },
      ],
    }
  })

export default rows
