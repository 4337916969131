import React from 'react'
import PageHeader from '@components/PageHeader/PageHeader'
import DynamicTable from '@atlaskit/dynamic-table'
import hoc from '@utils/hoc'
import Spacer from '@components/Spacer/Spacer'

import rows from './rows'
import head from './tableHead'
import store from './Languages.store'
import data from './data.json'

class PM {
  componentDidMount() {
    store.getItems()
  }
}

export const Languages = () => (
  <div>
    <PageHeader text="Languages" />

    <DynamicTable
      head={head}
      rows={rows(data)}
      rowsPerPage={undefined}
      loadingSpinnerSize="large"
      deafultSortKey={'enabled'}
      isLoading={store.loading}
      isFixedSize
    />

    <Spacer />
  </div>
)

export default hoc(PM, Languages)
