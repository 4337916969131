import { gql } from '@apollo/client'

export const horseRacesByMeetingId = gql`
  query horseMeetingsById($ids: [ID!]!) {
    horseMeetingsById(ids: $ids) {
      id
      raceConnection {
        edges {
          node {
            id
            datetime
          }
        }
      }
    }
  }
`
export const internationalHorseRacesByMeetingId = gql`
  query internationalMeetingsById($ids: [ID!]!) {
    internationalMeetingsById(ids: $ids) {
      id
      raceConnection {
        edges {
          node {
            id
            datetime
          }
        }
      }
    }
  }
`
