import React from 'react'
import { InlineEditStateless } from '@atlaskit/inline-edit'
import SingleLineTextInput from '@atlaskit/input'
import { MODAL_ZINDEX } from '@utils/globalVar'
import { CheckboxSelect } from '@atlaskit/select'
import store from './Groups.store'
import TokenStore from '@components/Tokens/Tokens.store'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'

const rows = () =>
  store.data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.name,
        content: (
          <div style={{ paddingLeft: '8px' }}>
            <InlineEditStateless
              isLabelHidden
              areActionButtonsHidden
              isEditing={item.isEditing}
              onEditRequested={item.editing}
              onCancel={item.editing}
              onConfirm={item.updateGroupName}
              readView={
                <div style={{ width: '150px' }}>
                  <SingleLineTextInput
                    isEditing={false}
                    value={item.name}
                    shouldFitContainer
                  />
                </div>
              }
              editView={
                <SingleLineTextInput
                  isEditing
                  isInitiallySelected
                  value={item.name}
                  onChange={item.updateName}
                  shouldFitContainer
                />
              }
            />
          </div>
        ),
      },
      {
        key: item.schema,
        content: (
          <CheckboxSelect
            placeholder="Choose tokens"
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: MODAL_ZINDEX }) }}
            options={TokenStore.getLabels()}
            value={item.getLabels()}
            onChange={item.updateSchema}
            onBlur={item.updateGroupTokens}
          />
        ),
      },
      {
        content: (
          <div className="tableRight">
            <DeleteButton
              onClick={() =>
                WarningModalStore.open({
                  heading: 'Delete Role',
                  content: `Are you sure you want to delete ${item.name}?`,
                  appearance: 'warning',
                  action: () => store.deleteGroup(item.id),
                })
              }
            />
          </div>
        ),
      },
    ],
  }))

export default rows
