import * as yup from 'yup'

export const configSchema = yup
  .object()
  .shape({
    bookmakerId: yup.string(),
    ghBookmakerId: yup.string(),
    contentId: yup.string(),
    screenType: yup.string().default('LARGE_4K_SCREEN'),
    showPrices: yup.bool(),
    inlineSpotlights: yup.bool(),
    diomedSpotlights: yup.bool(),
    countries: yup.string(),
    showRPZone: yup.bool(),
    highlightToteRaces: yup.bool(),
    verdictAtBottom: yup.bool(),
    showVirtuals: yup.bool(),
    display24Hr: yup.bool(),
    showGreyhounds: yup.bool(),
    showGhForecast: yup.bool(),
    showAdverts: yup.bool(),
    showScreensaver: yup.bool(),
    showNewspaper: yup.string(),
    defaultNewspaperPage: yup.string(),
    showCardInsights: yup.bool(),
    showNewspaperTips: yup.bool(),
    refreshBetweenTimes: yup.string(),
    derivedMarkets: yup.bool(),
    devTools: yup.bool(),
    showFeatureRacesByDay: yup.string(),
    screenDisplayType: yup.string(),
    showEPS: yup.bool(),
    showHorsesNextOff: yup.bool(),
    meetingPriority: yup.string(),
    morningMeetingPriority: yup.string(),
    morningMeetingPriorityStartTime: yup.string(),
    afternoonMeetingPriority: yup.string(),
    afternoonMeetingPriorityStartTime: yup.string(),
    eveningMeetingPriority: yup.string(),
    eveningMeetingPriorityStartTime: yup.string(),
    showMeetingPriorityByTime: yup.bool(),
    version: yup.string().default('LATEST'),
    showEasyView: yup.string(),
    defaultToEasyView: yup.bool(),
    showSplashPage: yup.bool(),
    testScreen: yup.bool().default(false),
    showGreyhoundsEasyView: yup.bool(),
    screenNumber: yup.number(),
    showSports: yup.bool(),
    sportsNavigationState: yup.string(),
    specificSport: yup.string(),
    sportsDaysToAdd: yup.number(),
    sportsOrder: yup.string(),
    isIREShop: yup.bool(),
  })
  .noUnknown()
