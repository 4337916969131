export default {
  cells: [
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'id',
      content: 'ID',
      isSortable: true,
      width: 35,
    },
    {
      key: 'organisation',
      content: 'Organisation',
      isSortable: true,
      width: 25,
    },
    {
      key: 'more',
      width: 5,
    },
  ],
}
