import React from 'react'
import Button, { ButtonGroup } from '@atlaskit/button'
import DeleteButton from '@components/DeleteButton/DeleteButton'
import FakeLink from '@components/UI/FakeLink/FakeLink'
import store from './Users.store'
import WarningModalStore from '@ui/WarningModal/Warning.modal.store'

const rows = data =>
  data.map((item, index) => ({
    key: index,
    cells: [
      {
        key: item.email,
        content: (
          <FakeLink
            text={item.email}
            onClick={() => store.openModal(item, 'editUserModal')}
          />
        ),
      },
      {
        key: item.firstName,
        content: item.firstName,
      },
      {
        key: item.lastName,
        content: item.lastName,
      },
      {
        key: item.phoneNumber,
        content: item.phoneNumber,
      },
      {
        key: item['permission.role'],
        content: item['permission.role'],
      },
      {
        content: (
          <div className="tableRight">
            <ButtonGroup>
              <Button onClick={() => store.openModal(item, 'editUserModal')}>
                Edit User
              </Button>
              <DeleteButton
                onClick={() =>
                  WarningModalStore.open({
                    heading: 'Delete User',
                    content: `Are you sure you want to delete ${item.email}?`,
                    appearance: 'warning',
                    action: () => store.deleteUser(item.id),
                  })
                }
              />
            </ButtonGroup>
          </div>
        ),
      },
    ],
  }))

export default rows
