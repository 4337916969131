import React from 'react'
import PropTypes from 'prop-types'
import DynamicTable from '@atlaskit/dynamic-table'
import Pagination from '@atlaskit/pagination'
import Button from '@atlaskit/button'
import hoc from '@utils/hoc'
import permission from '@components/Permissions/Permissions.store'
import PageHeader from '@components/PageHeader/PageHeader'
import SearchDropdown from '@components/SearchDropdown/SearchDropdown'
import OrganisationStore from '@components/Organisation/Organisation.store'
import Spacer from '@components/Spacer/Spacer'
import store from './Users.store'
import UserModal from './Users.modal'
import rows from './rows'
import head from './tableHead'

class PM {
  componentDidMount() {
    store.getUsers()
    permission.getPermissions()
    OrganisationStore.getOrganisations()
  }
}

export const Users = () => {
  const { data, state, editUserModal, openNewModal, newUserModal } = store

  return (
    <div>
      <PageHeader text="Users">
        <SearchDropdown
          onSubmit={store.searchUser}
          placeholder="Search Users"
        />
        <Button onClick={openNewModal} appearance="primary">
          Create New
        </Button>
      </PageHeader>

      <DynamicTable
        head={head}
        rows={rows(data)}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={state === 'pending'}
        isFixedSize
      />
      {!!store.total && (
        <Pagination
          defaultValue={1}
          value={store.currentPage}
          total={Math.ceil(store.total / store.limit)}
          onChange={store.changePage}
        />
      )}
      {(newUserModal || editUserModal) && <UserModal isNew={newUserModal} />}
      <Spacer />
    </div>
  )
}

Users.propTypes = {
  classes: PropTypes.object,
}

export default hoc(PM, Users)
