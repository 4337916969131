import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'
import EditScopeStore from './EditScope.store'
import { scopeIncludeEnum } from '../AdvertUtils'
import { useQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { allHorseMeetingsQuery } from './AdvertQueries'
import {
  StyledDivHover,
  ZoomedOptions,
  ZoomedSelectedOptions,
  SaveOrCancelContainer,
  OptionsContainer,
  InstructionText,
} from './EditScope.styles'

const today = moment().format('YYYY-MM-DD')
const nextWeek = moment().add(7, 'days').format('YYYY-MM-DD')

const AddMeetingExclusions = (props) => {
  const [isOpen, setIsOpen] = useState(true)
  const closeModal = useCallback(() => setIsOpen(false), [])
  const { scopeData, updateScope } = EditScopeStore

  const selectedJurisdictions = scopeData.jurisdictions
    ? scopeData.jurisdictions.reduce((acc, curr) => {
        if (curr.include === scopeIncludeEnum.include) {
          curr.name === 'UK/IRE' ? acc.push('GB', 'IRE') : acc.push(curr.name)
        }
        return acc
      }, [])
    : []

  const {
    data: MeetingData,
    error: MeetingError,
    loading: MeetingLoading,
  } = useQuery(allHorseMeetingsQuery, {
    variables: {
      countryCodes: selectedJurisdictions,
      startDateUTC: today,
      endDateUTC: nextWeek,
    },
  })

  if (MeetingLoading) {
    return <p>LOADING</p>
  }
  if (MeetingError) {
    return <p>Error loading meetings</p>
  }

  const allMeetingsForScope =
    MeetingData &&
    MeetingData.AllMeeting.map((meeting) => {
      return {
        id: meeting.id,
        dateTime: meeting.dateTime,
        courseName: meeting.courseName,
        countryCode: meeting.countryCode,
        uniqueName: `${moment(meeting.dateTime).format('Do MMM')}: ${
          meeting.courseName
        }`,
        include: scopeIncludeEnum.include,
      }
    })

  const onMeetingClick = (meeting) => {
    meeting.include = scopeIncludeEnum.exclude
    const meetingNames = props.liveMeetingExclusionsArray.map(
      (meeting) => meeting.uniqueName,
    )
    if (!meetingNames.includes(meeting.uniqueName)) {
      const newMeetingArray = [
        ...props.liveMeetingExclusionsArray,
        {
          id: meeting.id,
          uniqueName: meeting.uniqueName,
          include: meeting.include,
        },
      ]
      props.setLiveMeetingExclusionsArray(newMeetingArray)
    }
  }

  const removeMeetingClick = (meetingToRemove) => {
    const remainingMeetings = props.liveMeetingExclusionsArray.filter(
      (meeting) => meeting.uniqueName !== meetingToRemove.uniqueName,
    )
    remainingMeetings.include = scopeIncludeEnum.include
    props.setLiveMeetingExclusionsArray(remainingMeetings)
  }

  const sortedMeetingsByTime = allMeetingsForScope.sort(
    (a, b) => new Date(a.dateTime) - new Date(b.dateTime),
  )

  return (
    <div>
      {isOpen && (
        <Modal
          onClose={() => {
            closeModal()
            props.handleExclusionClose()
          }}
        >
          <OptionsContainer>
            <ZoomedOptions>
              <InstructionText>Click on a meeting to Exclude</InstructionText>
              {sortedMeetingsByTime &&
                sortedMeetingsByTime.map((meeting) => {
                  return (
                    <StyledDivHover
                      key={meeting.uniqueName}
                      onClick={() => onMeetingClick(meeting)}
                    >
                      {`${moment(meeting.dateTime).format('Do MMM')}: ${
                        meeting.courseName
                      }`}
                    </StyledDivHover>
                  )
                })}
            </ZoomedOptions>
            <ZoomedSelectedOptions>
              <InstructionText>
                Exclude advert from these meetings (Click to remove meeting from
                list)
              </InstructionText>
              {props.liveMeetingExclusionsArray &&
                props.liveMeetingExclusionsArray.map((meeting) => {
                  return (
                    <StyledDivHover
                      key={meeting.uniqueName}
                      onClick={() => removeMeetingClick(meeting)}
                    >
                      {meeting.uniqueName}
                    </StyledDivHover>
                  )
                })}
            </ZoomedSelectedOptions>
          </OptionsContainer>
          <SaveOrCancelContainer>
            <Button
              onClick={() => {
                updateScope({
                  target: {
                    value: props.liveMeetingExclusionsArray,
                    name: 'jurisdictionExclusions',
                  },
                }),
                  updateScope({
                    target: {
                      value: [],
                      name: 'selectedMeetings',
                    },
                  }),
                  closeModal()
                props.handleClose()
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                closeModal()
                props.handleClose()
              }}
            >
              Cancel
            </Button>
          </SaveOrCancelContainer>
        </Modal>
      )}
    </div>
  )
}

export default observer(AddMeetingExclusions)
