import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import Button from '@atlaskit/button'
import ManagedDynamicTable from '@components/ManagedAtlaskit/ManagedDynamicTable'
import PageHeader from '@components/PageHeader/PageHeader'
import store from './Adverts.store'
import EditScopeStore from './EditScope/EditScope.store'
import Modal from './Adverts.modal'
import Preview from './Preview'
import rows from './rows'
import head from './tableHead'
import EditScope from './EditScope/EditScope'
import { useEffect } from 'react'

const Adverts = () => {
  useEffect(() => {
    store.getAdverts()
  }, [])
  const { openModal, modalOpen, data, state, preview } = store
  return (
    <Fragment>
      <PageHeader text="Adverts">
        <Button onClick={() => openModal()} appearance="primary">
          Upload Advert
        </Button>
      </PageHeader>
      <ManagedDynamicTable
        head={head()}
        rows={rows(data)}
        isLoading={state === 'pending'}
        sortKey={'advertName'}
      />
      {modalOpen && <Modal />}
      {preview && <Preview previewImage={preview} />}
      {EditScopeStore.scopeOpen && (
        <EditScope Advertdata={EditScopeStore.data} />
      )}
    </Fragment>
  )
}

export default observer(Adverts)
