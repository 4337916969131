import React, { Fragment, useEffect, useState } from 'react'
import Modal from '@atlaskit/modal-dialog'
import { observer } from 'mobx-react'
import Form from '@atlaskit/form'
import RegionStore from '@components/Region/Region.store'
import LocationStore from '@components/Location/Location.store'
import Checkbox from '@atlaskit/checkbox'
import { Radio } from '@atlaskit/radio'
import { uniqBy, cloneDeep, sortBy } from 'lodash'
import UserStore from '../../../stores/userStore'
import SelectShops from './SelectShops'
import AddExclusions from './AddExclusions'
import AddMeetingExclusions from './AddMeetingExclusions'
import EditScopeStore from './EditScope.store'
import Button from '@atlaskit/button'
import { scopeIncludeEnum } from '../AdvertUtils'
import SelectMeetings from './SelectMeetings'
import AddRaces from './AddRaces'
import moment from 'moment-timezone'
import { jurisdictions } from '../AdvertUtils'
import {
  CoverageBorder,
  DescriptionText,
  RadioAndCheckboxContainer,
  CheckboxContainer,
  Selections,
  RaceSelections,
  RaceCoverageContainer,
  RaceCoverageInnerContainer,
  RaceScrollHeader,
} from './EditScope.styles'

export const EditScope = () => {
  const {
    closeScope,
    scopeData,
    updateScope,
    updateFormRef,
    updateInitialScope,
    saveCoverage,
    getDBShops,
    getDBMeetings,
    getDBRaces,
    getDBRegions,
    getDBJurisdictions,
  } = EditScopeStore

  const [regions, setRegions] = useState([])
  const [currentJurisdictions, setCurrentJurisdictions] = useState([])
  const [currentRegions, setCurrentRegions] = useState([])
  const [loading, setLoading] = useState(true)
  const [isRegionSelected, setIsRegionSelected] = useState(true)
  const [isJurisdictionSelected, setIsJurisdictionSelected] = useState(true)
  const [openStates, setOpenStates] = useState({
    isOpen: false,
    isMeetingOpen: false,
    isRacesOpen: false,
    isShopExclusionsOpen: false,
    isMeetingExclusionsOpen: false,
  })
  const [DBData, setDBData] = useState({
    DBShops: [],
    DBMeetings: [],
    DBRaces: [],
    DBRegions: [],
    DBJurisdictions: [],
  })
  const [liveShopExclusionsArray, setLiveShopExclusionsArray] = useState([])
  const [liveSelectedShopArray, setLiveSelectedShopArray] = useState([])
  const [liveMeetingExclusionsArray, setLiveMeetingExclusionsArray] = useState(
    [],
  )
  const [liveSelectedMeetingArray, setLiveSelectedMeetingArray] = useState([])
  const [liveRacesArray, setLiveRacesArray] = useState([])

  useEffect(() => {
    let regions = uniqBy(
      RegionStore.getLabelsForOrganisationid(UserStore.organisationId),
      'label',
    ).map((region) => {
      return { ...region, include: scopeIncludeEnum.include }
    })
    let shops = uniqBy(
      regions.flatMap((region) =>
        LocationStore.getLabelsForRegionId(region.value),
      ),
      'value',
    ).map((shop) => {
      return { ...shop, include: scopeIncludeEnum.include }
    })

    setRegions(regions)
    updateScope({ target: { value: shops, name: 'shops' } })
  }, [])

  const actions = [
    { text: 'Save Coverage Settings', onClick: () => saveCoverage() },
    { text: 'Close', onClick: () => closeScope() },
  ]

  useEffect(() => {
    const fetchData = async () => {
      setDBData({
        DBShops: await getDBShops(),
        DBMeetings: await getDBMeetings(),
        DBJurisdictions: await getDBJurisdictions(),
        DBRaces: await getDBRaces(),
        DBRegions: await getDBRegions(),
      })
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const DBNames = []
    const regionsForScope = DBData.DBRegions.map((region) => {
      DBNames.push(region.name)
      return {
        label: region.name,
        value: region.regionId,
        include: scopeIncludeEnum.include,
      }
    })
    const liveRegions = [...regionsForScope]
    regions.forEach((region) => {
      if (!DBNames.includes(region.label)) {
        liveRegions.push({
          label: region.label,
          value: region.value,
          include: scopeIncludeEnum.exclude,
        })
      }
    })
    const emptyRegionsAndShops =
      DBData.DBShops.length === 0 && DBData.DBRegions.length === 0

    setCurrentRegions(liveRegions)
    const initialRegions = emptyRegionsAndShops ? regions : liveRegions

    updateScope({
      target: {
        value: DBData.DBShops.length > 0 ? [] : initialRegions,
        name: 'regions',
      },
    })
    updateInitialScope({
      target: { value: regionsForScope, name: 'regions' },
    })
  }, [DBData.DBRegions, regions])

  useEffect(() => {
    const DBNames = []
    const jurisdictionsForScope = DBData.DBJurisdictions.map((jurisdiction) => {
      DBNames.push(jurisdiction.jurisdiction)
      return {
        name: jurisdiction.jurisdiction,
        include: scopeIncludeEnum.include,
      }
    })
    const liveJurisdictions = [...jurisdictionsForScope]
    jurisdictions.forEach((jurisdiction) => {
      if (!DBNames.includes(jurisdiction.name)) {
        liveJurisdictions.push({
          name: jurisdiction.name,
          include: scopeIncludeEnum.exclude,
        })
      }
    })
    const emptyMeetingsAndJurisdictions =
      DBData.DBMeetings.length === 0 && DBData.DBJurisdictions.length === 0
    setCurrentJurisdictions(liveJurisdictions)
    const initialJurisdictions = emptyMeetingsAndJurisdictions
      ? jurisdictions
      : liveJurisdictions
    updateScope({
      target: {
        value: DBData.DBMeetings.length > 0 ? [] : initialJurisdictions,
        name: 'jurisdictions',
      },
    })
    updateInitialScope({
      target: { value: liveJurisdictions, name: 'jurisdictions' },
    })
  }, [DBData.DBJurisdictions, DBData.DBMeetings])

  useEffect(() => {
    const shopsToShow = []
    const shopsToExclude = []
    DBData.DBShops.forEach((shop) => {
      if (shop.include === scopeIncludeEnum.include) {
        shopsToShow.push({
          label: shop.shopName,
          value: shop.shopId,
          include: shop.include,
        })
      } else {
        shopsToExclude.push({
          label: shop.shopName,
          value: shop.shopId,
          include: shop.include,
        })
      }
    })

    if (
      DBData.DBShops[0] &&
      DBData.DBShops[0].include === scopeIncludeEnum.include
    ) {
      setIsRegionSelected(false)
    }

    updateScope({ target: { value: shopsToShow, name: 'selectedShops' } })
    updateInitialScope({
      target: { value: shopsToShow, name: 'selectedShops' },
    })
    updateScope({ target: { value: shopsToExclude, name: 'regionExclusions' } })
    updateInitialScope({
      target: { value: shopsToExclude, name: 'regionExclusions' },
    })
    setLiveShopExclusionsArray(scopeData.regionExclusions)
    setLiveSelectedShopArray(scopeData.selectedShops)
  }, [DBData.DBShops])

  useEffect(() => {
    const meetingsToShow = []
    const meetingsToExclude = []
    DBData.DBMeetings.forEach((meeting) => {
      if (meeting.include === scopeIncludeEnum.include) {
        meetingsToShow.push({
          id: meeting.meetingId,
          uniqueName: meeting.meeting,
          include: meeting.include,
        })
      } else {
        meetingsToExclude.push({
          id: meeting.meetingId,
          uniqueName: meeting.meeting,
          include: meeting.include,
        })
      }
    })

    if (
      DBData.DBMeetings[0] &&
      DBData.DBMeetings[0].include === scopeIncludeEnum.include
    ) {
      setIsJurisdictionSelected(false)
    }

    updateScope({ target: { value: meetingsToShow, name: 'selectedMeetings' } })
    updateInitialScope({
      target: { value: meetingsToShow, name: 'selectedMeetings' },
    })
    updateScope({
      target: { value: meetingsToExclude, name: 'jurisdictionExclusions' },
    })
    updateInitialScope({
      target: { value: meetingsToExclude, name: 'jurisdictionExclusions' },
    })
    setLiveMeetingExclusionsArray(scopeData.jurisdictionExclusions)
    setLiveSelectedMeetingArray(scopeData.selectedMeetings)
  }, [DBData.DBMeetings])

  useEffect(() => {
    const racesForScope = DBData.DBRaces.map((race) => {
      return {
        id: race.raceId,
        meeting: race.meeting,
        dateTime: race.dateTime,
      }
    })
    updateScope({ target: { value: racesForScope, name: 'selectedRaces' } })
    updateInitialScope({
      target: { value: racesForScope, name: 'selectedRaces' },
    })
    const racesByDaySortedAlphabetically = sortBy(
      scopeData.selectedRaces,
      [
        (x) => moment(x.dateTime).format('DD,MM'),
        (x) => x.meeting.toUpperCase(),
      ],
      ['asc', 'asc'],
    )
    setLiveRacesArray(racesByDaySortedAlphabetically)
  }, [DBData.DBRaces])

  const handleClose = () => {
    setOpenStates((prevState) => {
      return { ...prevState, isOpen: false }
    })
  }

  const handleSelectMeetingClose = () => {
    setOpenStates((prevState) => {
      return { ...prevState, isMeetingOpen: false }
    })
  }

  const handleExclusionsClose = () => {
    setOpenStates((prevState) => {
      return { ...prevState, isShopExclusionsOpen: false }
    })
  }

  const handleMeetingExclusionsClose = () => {
    setOpenStates((prevState) => {
      return { ...prevState, isMeetingExclusionsOpen: false }
    })
  }

  const handleAddRemoveRaceClose = () => {
    setOpenStates((prevState) => {
      return { ...prevState, isRacesOpen: false }
    })
  }

  const onRegionCheckboxChange = (event) => {
    const itemValue = event.value
    const scopeCopy = cloneDeep(scopeData)
    const updatedRegions = scopeCopy.regions.map((region) => {
      if (region.label === itemValue) {
        if (region.include === scopeIncludeEnum.include) {
          region.include = scopeIncludeEnum.exclude
        } else if (region.include === scopeIncludeEnum.exclude) {
          region.include = scopeIncludeEnum.include
        }
        return region
      }
      return region
    })
    setCurrentRegions(updatedRegions)
    updateScope({
      target: { value: updatedRegions, name: 'regions' },
    })
  }
  const onJurisdictionCheckboxChange = (event) => {
    const itemValue = event.value
    const scopeCopy = cloneDeep(scopeData)
    const updatedJurisdictions = scopeCopy.jurisdictions.map((jurisdiction) => {
      if (jurisdiction.name === itemValue) {
        if (jurisdiction.include === scopeIncludeEnum.include) {
          jurisdiction.include = scopeIncludeEnum.exclude
        } else if (jurisdiction.include === scopeIncludeEnum.exclude) {
          jurisdiction.include = scopeIncludeEnum.include
        }
        return jurisdiction
      }
      return jurisdiction
    })
    setCurrentJurisdictions(updatedJurisdictions)
    updateScope({
      target: { value: updatedJurisdictions, name: 'jurisdictions' },
    })
  }
  if (loading) {
    return <p>LOADING</p>
  } else {
    const DBRegionNames = DBData.DBRegions.map((region) => {
      return region.name
    })
    const regionBoxes = regions
      ? regions.map((region) => {
          const checked =
            DBRegionNames.includes(region.label) ||
            (DBData.DBRegions.length === 0 && DBData.DBShops.length === 0)
          return (
            <Checkbox
              initiallyChecked={checked}
              onChange={onRegionCheckboxChange}
              label={region.label}
              value={region.label}
              name={'selectRegions'}
              key={region.label}
              isDisabled={!isRegionSelected}
            />
          )
        })
      : []

    const DBJurisdictionNames = DBData.DBJurisdictions.map((jurisdiction) => {
      return jurisdiction.jurisdiction
    })
    const jurisdictionBoxes = jurisdictions.map((jurisdiction) => {
      const checked =
        DBJurisdictionNames.includes(jurisdiction.name) ||
        (DBData.DBJurisdictions.length === 0 && DBData.DBMeetings.length === 0)
      return (
        <Checkbox
          initiallyChecked={checked}
          onChange={onJurisdictionCheckboxChange}
          label={jurisdiction.name}
          value={jurisdiction.name}
          name={'selectJurisdictions'}
          key={jurisdiction.name}
          isDisabled={
            !isJurisdictionSelected || scopeData.advertType !== 'meeting'
          }
        />
      )
    })

    return (
      <Fragment>
        <Modal
          actions={actions}
          heading={'Create / Edit Advert Scope'}
          onClose={closeScope}
        >
          <Form
            shouldFitContainer
            ref={(ref) => {
              updateFormRef(ref)
            }}
          >
            <CoverageBorder>
              <RadioAndCheckboxContainer>
                <h2
                  style={{
                    fontSize: '14px',
                    background: 'white',
                    marginTop: '-30px',
                    width: '92px',
                  }} //margin-top only working here inline.
                >
                  Shop Coverage
                </h2>
                <Radio
                  isChecked={isRegionSelected}
                  onChange={() => {
                    updateScope({
                      target: { value: currentRegions, name: 'regions' },
                    })
                    setIsRegionSelected(() => true)
                  }}
                  label="By Region"
                  value={'By Region'}
                  name="parent"
                />
                <DescriptionText>
                  Advert will be displayed in all shops in <br />
                  selected regions other than those <br />
                  specified as exclusions
                </DescriptionText>
                <CheckboxContainer>
                  {regionBoxes.map((box) => box)}
                </CheckboxContainer>
                {liveShopExclusionsArray && !!liveShopExclusionsArray.length && (
                  <div>
                    <p style={{ margin: '5px' }}>Shop Exclusions</p>
                    <Selections>
                      {liveShopExclusionsArray.map((shop) => (
                        <p key={shop.id}>{shop.label}</p>
                      ))}
                    </Selections>
                  </div>
                )}
                <Button
                  onClick={() =>
                    setOpenStates((prevState) => {
                      return { ...prevState, isShopExclusionsOpen: true }
                    })
                  }
                >
                  Add / Remove Exclusions
                </Button>
              </RadioAndCheckboxContainer>
              <div style={{ marginTop: '-8px' }}>
                <Radio
                  value="Specific Shops"
                  label="Specific Shops"
                  name="Specific Shops"
                  isChecked={!isRegionSelected}
                  onChange={() => {
                    setIsRegionSelected(() => false)
                  }}
                />
                <DescriptionText>
                  Advert will be displayed in all shops
                  <br />
                  selected
                </DescriptionText>
                {!!liveSelectedShopArray.length && (
                  <Selections>
                    {liveSelectedShopArray.map((shop) => (
                      <p key={shop.label}>{shop.label}</p>
                    ))}
                  </Selections>
                )}
                <Button
                  onClick={() =>
                    setOpenStates((prevState) => {
                      return { ...prevState, isOpen: true }
                    })
                  }
                >
                  Select Shops
                </Button>
                {openStates.isOpen && !isRegionSelected && (
                  <SelectShops
                    handleClose={handleClose}
                    liveSelectedShopArray={liveSelectedShopArray}
                    setLiveSelectedShopArray={setLiveSelectedShopArray}
                  />
                )}
              </div>
            </CoverageBorder>
          </Form>

          <Form
            shouldFitContainer
            ref={(ref) => {
              updateFormRef(ref)
            }}
          >
            <CoverageBorder>
              <RadioAndCheckboxContainer>
                <h2
                  style={{
                    fontSize: '14px',
                    background: 'white',
                    marginTop: '-30px',
                    width: '114px',
                  }}
                >
                  Meeting Coverage
                </h2>
                <Radio
                  isChecked={isJurisdictionSelected}
                  onChange={() => {
                    updateScope({
                      target: {
                        value: currentJurisdictions,
                        name: 'jurisdictions',
                      },
                    })
                    setIsJurisdictionSelected(() => true)
                  }}
                  label="By Jurisdiction"
                  value={'By Jurisdiction'}
                  name="parent"
                  isDisabled={scopeData.advertType !== 'meeting'}
                />
                <DescriptionText>
                  Advert will be displayed on all meetings <br /> in the below
                  Jurisdictions other than <br /> those specified as exlusions
                </DescriptionText>
                <CheckboxContainer>
                  {jurisdictionBoxes.map((box) => box)}
                </CheckboxContainer>
                {liveMeetingExclusionsArray &&
                  !!liveMeetingExclusionsArray.length && (
                    <div>
                      <p style={{ margin: '5px' }}>Meeting Exclusions</p>
                      <Selections>
                        {liveMeetingExclusionsArray.map((meeting) => (
                          <p key={meeting.uniqueName}>{meeting.uniqueName}</p>
                        ))}
                      </Selections>
                    </div>
                  )}
                <Button
                  onClick={() =>
                    setOpenStates((prevState) => {
                      return { ...prevState, isMeetingExclusionsOpen: true }
                    })
                  }
                >
                  Add / Remove Exclusions
                </Button>
              </RadioAndCheckboxContainer>
              <div style={{ marginTop: '-8px' }}>
                <Radio
                  value="Specific Meetings"
                  label="Specific Meeting"
                  name="Specific Meetings"
                  isChecked={!isJurisdictionSelected}
                  onChange={() => {
                    setIsJurisdictionSelected(() => false)
                  }}
                  isDisabled={scopeData.advertType !== 'meeting'}
                />
                <DescriptionText>
                  Advert will be displayed on all meetings
                  <br />
                  selected
                </DescriptionText>
                {!!liveSelectedMeetingArray.length && (
                  <Selections>
                    {liveSelectedMeetingArray.map((meeting) => (
                      <p key={meeting.uniqueName}>{meeting.uniqueName}</p>
                    ))}
                  </Selections>
                )}

                <Button
                  onClick={() =>
                    setOpenStates((prevState) => {
                      return { ...prevState, isMeetingOpen: true }
                    })
                  }
                >
                  Add / Remove Meetings
                </Button>
              </div>
            </CoverageBorder>
          </Form>

          <Form
            shouldFitContainer
            ref={(ref) => {
              updateFormRef(ref)
            }}
          >
            <RaceCoverageContainer>
              <RaceCoverageInnerContainer>
                <h2
                  style={{
                    fontSize: '14px',
                    background: 'white',
                    marginTop: '-30px',
                    width: '92px',
                    height: 'fit-content',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Race Coverage
                </h2>
                {
                  <Fragment>
                    <RaceScrollHeader>
                      <h1 style={{ marginLeft: '14px' }}>Date</h1>
                      <h1>Meeting</h1>
                      <h1 style={{ marginRight: '14px' }}>Race</h1>
                    </RaceScrollHeader>
                    <RaceSelections>
                      {liveRacesArray.map((race) => (
                        <div
                          key={race.id}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p>{moment(race.dateTime).format('DD/MM')}</p>
                          <p>{race.meeting}</p>
                          <p>{moment(race.dateTime).format('HH:mm')}</p>
                        </div>
                      ))}
                    </RaceSelections>
                  </Fragment>
                }

                <Button
                  onClick={() =>
                    setOpenStates((prevState) => {
                      return { ...prevState, isRacesOpen: true }
                    })
                  }
                >
                  Add / Remove Races
                </Button>
              </RaceCoverageInnerContainer>
            </RaceCoverageContainer>
          </Form>
        </Modal>
        {scopeData.advertType === 'meeting' &&
          openStates.isMeetingOpen &&
          !isJurisdictionSelected && (
            <SelectMeetings
              handleClose={handleSelectMeetingClose}
              liveSelectedMeetingArray={liveSelectedMeetingArray}
              setLiveSelectedMeetingArray={setLiveSelectedMeetingArray}
            />
          )}
        {openStates.isShopExclusionsOpen && isRegionSelected && (
          <AddExclusions
            handleClose={handleExclusionsClose}
            liveShopExclusionsArray={liveShopExclusionsArray}
            setLiveShopExclusionsArray={setLiveShopExclusionsArray}
          />
        )}
        {scopeData.advertType === 'meeting' &&
          openStates.isMeetingExclusionsOpen &&
          isJurisdictionSelected && (
            <AddMeetingExclusions
              handleClose={handleMeetingExclusionsClose}
              liveMeetingExclusionsArray={liveMeetingExclusionsArray}
              setLiveMeetingExclusionsArray={setLiveMeetingExclusionsArray}
            />
          )}
        {openStates.isRacesOpen && scopeData.advertType === 'race_specific' && (
          <AddRaces
            handleClose={handleAddRemoveRaceClose}
            liveRacesArray={liveRacesArray}
            setLiveRacesArray={setLiveRacesArray}
          />
        )}
      </Fragment>
    )
  }
}

export default observer(EditScope)
