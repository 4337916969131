import { types } from 'mobx-state-tree'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { createBrowserHistory } from 'history'

const makeBrowserHistory = createBrowserHistory()
const routingStore = new RouterStore()

const history = syncHistoryWithStore(makeBrowserHistory, routingStore)

const routerStore = types
  .model({
    router: types.optional(types.frozen()),
    currentPath: types.string,
  })
  .actions((self) => ({
    storePath(path) {
      self.currentPath = path
    },
  }))
  .create({ router: routingStore, currentPath: '' })

makeBrowserHistory.listen((location) => {
  routerStore.storePath(location.pathname)
})

routerStore.storePath(window.location.pathname) // store path on load

export { history, routerStore }
